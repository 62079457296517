import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import L, {control, icon, latLng, Layer, Map, Marker, marker, polygon, tileLayer} from 'leaflet';
import 'leaflet.gridlayer.googlemutant';
import {UserProfile} from '../../settings/user-profile/user-profile-type';
import moment from 'moment-timezone';
import {TrackingService} from '../../../services/tracking.service';
import {DateFormatterService} from '../../../services/dateformat';
import {LangUtilService} from '../../util/lang-util.service';
import {CommonService} from '../../../services/common.service';
import {MessageService} from 'primeng/api';
import {IMapUserOptions} from '../../../state/User/interfaces/IUserMap';
import {handleMapResize} from '../../../state/User/user.utilityService';
import {getPopUpHeader, showPopupData} from '../../../app/delivery-managenment-system/services/common.service';

declare let $: any;

@Component({
  selector: 'app-car-event-table',
  templateUrl: './car-event-table.component.html',
  styleUrls: ['./car-event-table.component.scss']
})
export class CarEventTableComponent implements OnChanges, OnInit {
  dataSource: PeriodicElement[];
  // dataSource: Array<object>;
  public selectedRowIndex: number;
  @Input() showDataNotAvailable: boolean;
  @Input() isCarSelected: boolean;
  @Input() isLongTableRequired: boolean;
  @Input() updateValue: any;
  @Input() eventsWrapperHeight: number;
  @Input() analyticsData: any;
  @Input() searchedCar: any;
  @Input() id: any = null;
  @Input() selectedEventList: Array<string>;
  @Input() selectedStatusList: Array<string>;


  @Input() userMapOptions: IMapUserOptions;

  @Output() showLoaderEvent: EventEmitter<boolean> = new EventEmitter();


  markers: Layer[] = [];
  map: Map;
  public userDetails: UserProfile;
  public mapDetails: IMapUserOptions;
  public selectedMap: any;
  public selectedZoom: number;
  public selectedLatitude: number;
  public selectedLongitude: number;
  public center: any;
  public mapTiles: Array<IMapUserOptions>;
  wholeDataSourceObj: any = null;
  public pagination: any;
  public pageNumber: any;
  public skip: number;
  public limit: number;
  public inputArr: any = [];
  public flag = true;

  filterEvents: any[] = [
    {eventName: 'All'},
    {eventName: 'Idling Event'},
    {eventName: 'Harsh Event'},
    {eventName: 'Ignition'}
  ];
  filterEvent: any;
  // Open Street Map definitions

  // Values to bind to Leaflet Directive
  /*options = {
    layers: [],
    zoom: 16,
    zoomControl: false,
    center: latLng(25.214017, 55.310220)
  };*/
  private url: string;
  private option: any;
  options = {
    layers: [],
    zoom: 5,
    zoomControl: false,
    center: latLng(null, null)
  };
  mapToView: any;
  displayedColumns: string[] = ['eventName', 'description', 'time', 'location'];
  themeSelected = null;
  layer: Layer;
  wholeDataSource: PeriodicElement[];


  /*LAYER_OSM = tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    detectRetina: true,
    maxZoom: 18,
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
  });
  steet = tileLayer('https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
    maxZoom: 20,
    subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
  });
  darkLayer = L.tileLayer('https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png', {
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
    subdomains: 'abcd',
    maxZoom: 19
  });
  baseLayers = {'Light Theme': this.steet, 'Dark Theme': this.darkLayer};*/
  constructor( private trackingService: TrackingService,
              private lang: LangUtilService, public dateFormatter: DateFormatterService, private commonService: CommonService,
              private messageService: MessageService) {
    this.mapToView = tileLayer(this.url, this.option);
    this.showDataNotAvailable = false;
    this.isLongTableRequired = false;
    this.isCarSelected = false;
    this.selectedRowIndex = -1;
    this.markers = [];
    this.dataSource = [];
    this.wholeDataSource = [];
    this.filterEvent = this.filterEvents[0];
    this.searchedCar = null;
    this.pageNumber = 1;
    this.pagination = {
      'currentPage': 1,
      'totalNoOfRecords': 0,
      'recordsPerPage': 10,
      'pageCountValue': 0,
    };

  }

  ngOnInit() {
    this.getMapForSelectedUser();
    this.themeSelected = sessionStorage.getItem('theme');
  }

  dragEnd() {
    if (this.mapDetails) {
      this.setMap(this.mapDetails);
    }
  }

  getMapForSelectedUser() {
    if (this.userMapOptions && this.map) {
      this.mapDetails = this.userMapOptions;
      if (this.mapDetails.zoom) {
        this.selectedZoom = this.mapDetails.zoom;
      } else {
        this.selectedZoom = 11;
      }
      if (this.mapDetails.centerLocation) {
        this.selectedLatitude = this.mapDetails.centerLocation.coordinates[0];
        this.selectedLongitude = this.mapDetails.centerLocation.coordinates[1];
      } else {
        this.selectedLatitude = 25.214017;
        this.selectedLongitude = 55.310220;
      }
      this.setMap(this.mapDetails);
      this.center = latLng(this.selectedLatitude, this.selectedLongitude);
      this.map.setView(this.center, this.selectedZoom);
      this.map.setZoom(this.selectedZoom);
    }
  }

  setMap(mapSelected) {
    if (mapSelected && mapSelected.tile) {
      this.url = mapSelected.tile.url;
      this.option = mapSelected.tile.option;
      this.mapToView = tileLayer(this.url, this.option);
      if (mapSelected.tile['google_style']) {
        (L as any).gridLayer.googleMutant({
          type: 'roadmap',
          styles: mapSelected.tile['google_style'],
        }).addTo(this.map);

      } else {
        this.mapToView.addTo(this.map);
      }
      this.map.invalidateSize(true);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName of Object.keys(changes)) {
      const change = changes[propName];

      if (propName === 'updateValue') {
        if (change.currentValue !== change.previousValue && this.updateValue != null) {
          this.updateTable(this.updateValue);
        }
      }

      if (propName === 'userMapOptions') {
        if (change.currentValue !== change.previousValue) {
          this.getMapForSelectedUser();
        }
      }

      if (propName === 'searchedCar' || propName === 'selectedStatusList' || propName === 'analyticsData') {
        if (change.currentValue !== change.previousValue) {
          if (this.searchedCar && this.analyticsData && this.flag) {
            this.flag = false;
            this.removeMarkersFromMap();
            this.getEventsCountForPagination();
          }
          setTimeout(() => this.flag = true, 10);
        }
      }
    }
  }

  eventsToPlot = [];

  removeplottedEvents(plottedEvents) {
    if (plottedEvents.length > 0) {
      for (let i = 0; i < plottedEvents.length; i++) {
        const eventObj = plottedEvents[i];
        this.map.removeLayer(eventObj);
      }
    }
    plottedEvents = [];
    return plottedEvents;
  }

  bindEventIconForTableData(data) {
    data['eventIcon'] = this.commonService.getIconsForEvents({name: data.name, status: data.status});
  }

  plotEventsOnMap(e) {
    this.lang.updateMomentLanguage(moment);
    const generatedTimeStamp = moment(e?.generatedTimeStamp).calendar();
    const obj = {name: e?.name, status: e?.status};
    if (e?.coordinates?.length===2) {
      const newMarker = marker(e.coordinates,
        {
          icon: icon({
            iconSize: [15, 15],
            iconUrl: this.commonService.getIconsForEvents(obj)
          }),
          autoPan: true
        });
      newMarker?.setLatLng(e?.coordinates)?.addTo(this?.map)?.bindPopup(
        getPopUpHeader('',e.description || '', (generatedTimeStamp || '')) + showPopupData([])
        , {maxWidth : 1000}).openPopup()
      this.eventsToPlot?.push(newMarker);
    }
  }

  showLocationOnMap(e) {
    this.markers = [];
    this.lang.updateMomentLanguage(moment);
    const generatedTimeStamp = moment(e.generatedTimeStamp).calendar();
    if (e.coordinates && e.coordinates.length > 0) {
      const newMarker: Marker = marker(e.coordinates,
        {
          icon: icon({
            iconSize: [16, 16],
            // iconAnchor: [13, 38],
            iconUrl: e.eventIcon,
            // shadowUrl: 'leaflet/marker-shadow.png',
            // shadowAnchor: [13, 38]
          }),
          autoPan: true
        });
      newMarker.addTo(this.map).bindPopup(getPopUpHeader('',e.description || '', (generatedTimeStamp || '')) + showPopupData([])
      , {maxWidth : 1000}).openPopup()
      this.markers.push(newMarker);

      if (this.map) {
        this.map.setZoom(19);
        setTimeout(() => {
          this.map.panTo(e.coordinates);
        }, 500);
        this.plotGeofence(e);
      }
    } else {
      this.messageService.add({key: 'car-event-msg', severity: 'info', summary: 'Info', detail: 'Event location not found'});
    }


  }

  plotGeofence(e) {
    if (e.geoFenceObj && e.geoFenceObj.location && e.geoFenceObj.location.coordinates) {
      this.removeMarkersFromMap();
      const coordinates = e?.geoFenceObj?.location?.coordinates?.map((coordinate) => {
        return latLng(coordinate[0], coordinate[1]);
      });
      this.layer = polygon(coordinates).addTo(this.map).bindTooltip(
        '<B style="font-size: 14px">' + e.geoFenceObj.label + '</B>').closeTooltip();
    }
  }

  removeMarkersFromMap() {
    if (this.layer) {
      this.layer.removeFrom(this.map);
    }
  }

  /*  onMapReady(map: Map) {
      $('.leaflet-control-attribution').hide();
      this.map = map;
      if ( this.themeSelected === 'dark-theme' ) {
        this.baseLayers['Dark Theme'].addTo(this.map);
      } else {
        this.baseLayers['Light Theme'].addTo(this.map);
      }
      L.control.zoom({
        position: 'bottomright'
      }).addTo(map);
    }*/

  onMapReady(map: Map) {
    $('.leaflet-control-attribution').hide();
    this.map = map;
    control.zoom({
      position: 'bottomright'
    }).addTo(map);
    this.getMapForSelectedUser();
    handleMapResize(this.map, 'event-table-map');
    this.setMap(this.mapDetails);
  }

  highlight(row) {
    this.selectedRowIndex = row._id;
  }

  updateTable(event) {
    if (this.dataSource && this.dataSource.length > 0) {
      this.dataSource.unshift(event);
    } else {
      this.dataSource = [];
      this.dataSource.push(event);
    }
  }

  onFilterEventChange(event) {
    if (event.eventName === 'All') {
    } else {
    }
  }

  getDataByPageNo(page) {
    this.showLoaderEvent.emit(true);
    this.getEventsDataByPageNo(page);
  }

  public updatePageNumber(index: number): void {
    this.pageNumber = index;
  }

  getPageCount() {
    this.inputArr = [];
    this.pageNumber = 1;
    this.pagination.pageCountValue = Math.ceil((this.pagination.totalNoOfRecords) / this.pagination.recordsPerPage);
    for (let i = 1; i <= this.pagination.pageCountValue; i++) {
      this.inputArr[i] = i;
    }
  }

  getEventsCountForPagination() {
    let selectedCar: string = this.id ? this.id : this.searchedCar?._id;
    const type: string = this.selectedEventList.toString();
    const status: string = this.selectedStatusList.toString();
    const startDate: Date = this.analyticsData.startDate;
    const endDate: Date = this.analyticsData.endDate;

    if (this.searchedCar.assetObj && this.searchedCar.assetObj.carrier && this.searchedCar.assetObj.carrier._id) {
      selectedCar = this.searchedCar.assetObj.carrier._id;
    }

    this.showLoaderEvent.emit(true);
    if (selectedCar) {
      this.trackingService.getCarEventAndStatusCount(selectedCar, type, status, startDate, endDate)
        .subscribe((data) => {
          this.showLoaderEvent.emit(data && data['count'] > 0);
          this.showDataNotAvailable = !(data && data['count'] > 0);
          this.pagination.totalNoOfRecords = data['count'];
          const obj: any = {};
          obj.pageIndex = 0;
          this.getPageCount();
          if (data && data['count'] > 0) {
            this.getEventsDataByPageNo(obj);
          } else {
            this.dataSource = [];
            this.eventsToPlot = this.removeplottedEvents(this.eventsToPlot);
            this.markers = this.removeplottedEvents(this.markers);
            this.showLoaderEvent.emit(false);
          }

        }, (error) => {
          console.log(error);
        });
    } else {
      this.showDataNotAvailable = true;
    }
  }

  getEventsDataByPageNo(currentPage) {
    if (currentPage?.rows) {
      this.pagination.recordsPerPage = currentPage.rows;
    }
    let recordsPerPage;
    let skip;
    this.pageNumber = currentPage.page;
    if (currentPage.page === 0) {
      skip = 0;
      recordsPerPage = this.pagination.recordsPerPage;
    } else {
      skip = (currentPage.page) * this.pagination.recordsPerPage;
      recordsPerPage = this.pagination.recordsPerPage;
    }
    let selectedCar = this.id ? this.id : this.searchedCar?._id;
    const type: string = this.selectedEventList.toString();
    const status: string = this.selectedStatusList.toString();
    const startDate: Date = this.analyticsData.startDate;
    const endDate: Date = this.analyticsData.endDate;

    if (this.searchedCar.assetObj && this.searchedCar.assetObj.carrier && this.searchedCar.assetObj.carrier._id) {
      selectedCar = this.searchedCar.assetObj.carrier._id;
    }
    if (selectedCar) {
      this.trackingService.getCarEventAndStatusData(selectedCar, type, status, startDate, endDate, skip, recordsPerPage)
        .subscribe((data: Array<any>) => {
          this.dataSource = data;
          this.eventsToPlot = this.removeplottedEvents(this.eventsToPlot);
          this.markers = this.removeplottedEvents(this.markers);
          if (this.dataSource.length > 0) {
            for (let i = 0; i < this.dataSource.length; i++) {
              const eventObj = this.dataSource[i];
              this.bindEventIconForTableData(eventObj);
              this.plotEventsOnMap(eventObj);
            }
            this.showLoaderEvent.emit(false);
            const firstRow: any = this.dataSource[0];
            this.selectedRowIndex = firstRow._id;
            this.showLocationOnMap(firstRow);
          } else {
            this.removeMarkersFromMap();
            this.showLoaderEvent.emit(false);
          }
          this.filterEvent = this.filterEvents[0];
        });
    }

  }

}


export interface PeriodicElement {
  /*eventName: string;
  status: string;
  time: string;
  coordinates: string;*/
  description: string;
  deviceId: string;
  coordinates: Array<number>;
  generatedTimeStamp: string;
  name: string;
  status: string;
  vinNumber: string;
}
