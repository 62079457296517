<div class="assetListGrid" id="serviceListingPopup">
  <!--  <div class="grid noMargin">-->
  <!--    <div class="md:col-12">-->
  <!--      <div class="backBtn">-->
  <!--        <button mat-raised-button color="primary" id="serviceListBackBtn" class="FR" (click)="resetSearchedAssets()">Back</button>-->
  <!--        <button mat-raised-button color="accent" class="linkBtn showServiceLinkPopupBtn buttonEnable" *ngIf="linkAssetsIds.length > 0">Link <i class="fa fa-link" aria-hidden="true"></i></button>-->
<!--      </div>-->
<!--      <app-search-for-service [serviceAssetConfigDetails]="serviceAssetConfigDetails" [assetData]="assetData"  [resetSearchedAssetOnBackBtn]="resetSearchedAssetOnBackBtn"-->
<!--                              (searchedAssets)="searchedAssetsFound($event)"-->
<!--                              [resetSearchedServicesOnPlusClick]="resetSearchedServicesOnPlusClick"></app-search-for-service>-->
<!--      &lt;!&ndash;<app-search-for-asset [allAssetTypes]="allAssetTypes" [assetData]="assetData" (searchedAssets)="searchedAssetsFound($event)"-->
<!--                            [resetSearchedAssetOnBackBtn]="resetSearchedAssetOnBackBtn" [resetSearchedAssetOnPlusClick]="resetSearchedAssetOnPlusClick"></app-search-for-asset>&ndash;&gt;-->
<!--    </div>-->
<!--  </div>-->

<!--  <div class="grid noMargin">-->
<!--    <div class="md:col-6" *ngFor="let asset of searchedAssetForLinking">-->
<!--      <app-asset-card [assetData]="asset" [config]="config" [serviceAssetConfigDetails]="serviceAssetConfigDetails" [showCloseBtn]="false" [showGroupBtn]="true" [isLinkable]="true"-->
<!--                      [isCardClickable]="false" [showLinkBtn]="true" (linkAssetDetails)="linkAssetDetails($event)"></app-asset-card>-->

<!--    </div>-->
<!--  </div>-->
  <app-search-for-service [serviceAssetConfigDetails]="serviceAssetConfigDetails" [assetData]="assetData"
                          [resetSearchedAssetOnBackBtn]="resetSearchedAssetOnBackBtn"
                          (searchedAssets)="searchedAssetsFound($event)"
                          [resetSearchedServicesOnPlusClick]="resetSearchedServicesOnPlusClick"></app-search-for-service>
  <div class="grid mx-1 mt-1 assetListContainer"
       [ngStyle]="{'height' : searchedAssetForLinking && searchedAssetForLinking.length !== 0 ? '52%' : '0%'} ">
    @for (asset of searchedAssetForLinking; track asset) {
      <div class="xl:col-6">
      <app-thing-card [assetData]="asset" [config]="config" [assetConfigDetails]="serviceAssetConfigDetails" [showCloseBtn]="false"
                      [showGroupBtn]="true" [isLinkable]="true"
                      [isLinkableTooltipValue]="getLangLabel('sys.click-to-select-link-asset')"
                      [isCardClickable]="false" [showLinkBtn]="true"
                      (linkAssetDetails)="linkAssetDetails($event)"></app-thing-card>
    </div>
    }
  </div>

  <div class="grid mx-0 py-1 mt-3">
    <div class="col-12">
      <div class="grid mx-0 my-2 py-1 justify-content-start">
        @if (linkAssetsIds.length > 0) {
        <button pButton pRipple color="accent" type="button" class="linkBtn defaultButtonColor showServiceLinkPopupBtn buttonEnable mx-1"
        ><i class="fa fa-link"
            aria-hidden="true"></i> {{ linkAssetsIds.length === 1 ? getLangLabel('sys.link-service') : getLangLabel('sys.link-services') }}
        </button>
        }
        <button pButton pRipple color="warn" id="serviceListBackBtn" class="mx-1 closePopUpBtn" (click)="resetSearchedAssets()">{{'sys.cancel' | translate}}</button>
      </div>
    </div>
  </div>
</div>
