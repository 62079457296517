import {createAction, props} from '@ngrx/store';
import {IAssetStatusSocketData} from './interfaces/custome.interfaces';


export const gpsData = createAction(
  '[socket api] gps data'
);

export const killAll = createAction(
  '[socket api] close all open socket nsp'
);

export const gpsDataSuccess = createAction(
  '[socket api] gps data fetch success',
  props<{ gps: any }>()
);
export const disconnectGpsDataSocket = createAction(
  '[socket api] disconnect gps socket connection'
);

export const connectionStats = createAction(
  '[socket api] connection stats',
  props<{ status: string }>()
);

export const lmdDeviceLiveStatus = createAction(
  '[socket api] lmd fetch live status'
);

export const lmdDeviceLiveStatusSuccess = createAction(
  '[socket api] lmd fetch live stats success',
  props<{ liveStatus: any }>()
);

export const disconnectLmdLiveStatus = createAction(
  '[socket api] disconnect lmd  socket connection'
);

export const failure = createAction(
  '[socket api] fetch failure',
  props<{ error: Error }>()
);

export const assetStatus = createAction(
  '[socket api ] update asset',
  props<{ id: string }>()
);
export const assetStatusChange = createAction(
  '[socket api] on asset status change',
  props<{ res: IAssetStatusSocketData }>()
);


export const disconnectAssetStatus = createAction(
  '[socket api] disconnect  update asset socket connection',
  props<{ id: string }>()
);

export const updateWarehouseCountGraphWebSocketRequired = createAction(
  '[Effect warehouse dashboard update count array using webSocket API] fetch warehouseCountArrayusingWebSocket',
  props<{ nameSpace: string, topicName: string }>()
);

export const userNotificationWebSocketRequired = createAction(
  '[Effect user notification using webSocket API] fetch notification',
  props<{ nameSpace: string, topicName: string }>()
);
export const updateGeofencesSocketRequired = createAction(
  '[Effect update geofences using webSocket API] fetch updateGeoFencesSocket',
  props<{nameSpace:string,topicName:string, key : string}>()
);
export const updateGeofenceDataFromSocketSuccess = createAction(
  '[Effect update geofences using webSocket API] on success updateGeofenceDataFromSocketSuccess',
  props<{ data}>()
)

export const updateSocketDataRequired = createAction(
  '[Effect mmd consignment deliveries using webSocket API] fetch updateSocketDataState',
  props<{ nameSpace: string, topicName: string, key: string, callback?: (data?: unknown) => void }>()
);

export const disconnect = createAction(
  '[socket api] on success disconnect from socket nsp',
  props<{ nsp: string, topic: string, key?: string }>()
);
