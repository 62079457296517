import {Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges} from '@angular/core';
import * as _ from 'lodash';
import { groupBy, find, findKey} from 'lodash';
declare let $: any;
import {Actions, Subject} from '../../../auth/rules';
import {AssetService} from '../../../services/asset.service';
import {DateFormatterService} from '../../../services/dateformat';
import {ServiceLinkableService} from '../../../services/serviceLinkable.service';
import {LangUtilService} from '../../util/lang-util.service';
import { ConfigService } from '../../../services/config.service';
import moment from 'moment';

import { IAssetCardImgStatus } from '../../../state/Assets/interfaces/Assets.modal';

@Component({
  selector: 'app-thing-card',
  templateUrl: './thing-card.component.html',
  styleUrls: ['./thing-card.component.scss']
})
export class ThingCardComponent implements OnInit, OnChanges {
  public id: any;
  public assetLinkedDetails: any;
  public assetLabel: string;
  public assetLabelToolTip: string;
  public serviceLabelToolTip: string;
  public serviceLabel: string;
  public embossedFlag: boolean;
  public imageDetails: any = {
    show:false,
    imageType:null,
    subType:null,
    imageUrl: null
  };
  @Input() selectedId : string;
  @Input() isCardClickable: boolean;
  @Input() showStatusOnAssetCard: boolean;
  @Input() isLinkable: boolean;
  @Input() isLinkableTooltipValue: string;
  @Input() assetConfigDetails: any;
  @Input() serviceAssetConfigDetails: any;
  @Input() assetDetailsAfterLinkingUpdated: any;
  @Input() assetData: any;
  @Input() upadtedCartSpecificDetails: any;
  @Input() updatedIndividualSericeStatus: any;
  @Input() showGroupBtn: boolean;
  @Input() showCloseBtn: boolean;
  @Input() showDelinkServiceBtn: boolean;
  @Input() showLinkBtn: boolean;
  @Input() isAssetDetailsInReadMode: boolean;
  @Input() isCardHeightFixed: boolean;
  @Input() resetFormData: number;
  @Input() isprofilePhotoAccepted: boolean;
  @Input() isThumbnailAccepted: boolean;
  @Input() splitScreenIsActive = false;
  @Input() imageDisplayStatus: IAssetCardImgStatus

  @Output() editAsset: EventEmitter<any> = new EventEmitter();
  @Output() delinkAssetDetails: EventEmitter<any> = new EventEmitter();
  @Output() delinkServiceDetails: EventEmitter<any> = new EventEmitter();
  @Output() linkAssetDetails: EventEmitter<any> = new EventEmitter();
  @Output() isActiveCardStatus: EventEmitter<object> = new EventEmitter();
  @Input() config: any;
  @Input() imageUrl: any;
  SUBJECT=Subject;
  ACTIONS=Actions;
  profileImage = {
    isFound: false,
    data: null
  };
  fieldsToBeDisplayed: any = [];
  imagesLoader = {
    thumbnailImage : false
  };
  hover: boolean = false;

  private token: string = ''
    assetImageSrc: any;
  // eslint-disable-next-line max-len
  constructor(public assetService: AssetService,
    public configService: ConfigService,
    public dateFormatter: DateFormatterService,
    public serviceLinkableService: ServiceLinkableService,
    private lang: LangUtilService) {
    this.embossedFlag = false;
    this.isCardClickable = false;
    this.showStatusOnAssetCard = false;
    this.isLinkable = false;
    this.isAssetDetailsInReadMode = false;
    this.assetLinkedDetails = {};
    this.showDelinkServiceBtn = false;
    this.token = sessionStorage.getItem('token')
  }

  ngOnInit() {
    if(this.isThumbnailAccepted){
        this.imageDetails[this.assetData._id] = { imgUrl:this.configService.appConfig.appBaseUrl + `asset/displayImageByAssetId/`+this.assetData._id+`?url=false&token=${this.token}&date=${moment().valueOf()}`}
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName of Object.keys(changes)) {
      let change = changes[propName];
      if (propName === 'assetData') {
        if (change.currentValue !== change.previousValue) {
          this.embossedFlag = false;
          this.oneTimeImageLoaderInit(this.assetData['_id'], 'thumbnailImage');
          //this.getAssetsLinkedDetails(this.assetData);
          if (this.assetData && this.assetData.assetType && this.assetConfigDetails) {
            if (this.assetData.serviceAssets && this.assetData.serviceAssets.length > 0) {
              this.getServicesLinkedForAssetDetails(this.assetData.serviceAssets);
            } else {
              this.getAssetsLinkedDetails(this.assetData);
            }
            this.checkForAssetImage();
            this.getAssetLabel();
          } else if (this.assetData && this.assetData.serviceType) {
            this.getAssetLinkedForService(this.assetData);
            this.getServiceAssetLabel();
            if (this.showStatusOnAssetCard) {
              this.serviceLinkableService.getServiceStatus(this.assetData._id).subscribe((status: any) => {
                if (status && status.status && status.status === 'online') {
                  this.assetData['running'] = true;
                } else {
                  this.assetData['running'] = false;
                }
              });
            }
          }
          let groupByAssetType = groupBy(this.config, 'type');

          let type = null;
          if ( this.assetData.assetType ) {
            type = this.assetData.assetType;
          } else if ( this.assetData.serviceType ) {
            type = this.assetData.serviceType;
          }
          if (groupByAssetType[type] && groupByAssetType[type][0] && groupByAssetType[type][0]['configuration']) {
            this.fieldsToBeDisplayed = groupBy(groupByAssetType[type][0]['configuration'], 'showInCard')['true'];
          }
        }
      }
      if (propName === 'resetFormData') {
        if (change.currentValue !== change.previousValue) {
          this.assetLinkedDetails = {};
          this.assetLabel = '';
          this.serviceLabel = '';
          this.assetLabelToolTip = '';
          this.serviceLabelToolTip = '';
        }
        // this.fieldsToBeDisplayed = [];
      }

      /* if (propName === 'upadtedCartSpecificDetails') {
       if (change.currentValue !== change.previousValue) {
         if (this.assetData._id === this.upadtedCartSpecificDetails._id) {
           this.assetData = this.upadtedCartSpecificDetails;
           this.assetData['images'] && this.assetData['images']['profilePhoto'] ? this.getProfilPicDetails(this.assetData) : '';
         }
       }
     } */

     if (propName === 'updatedIndividualSericeStatus') {
       if (change.currentValue !== change.previousValue) {
         if (this.assetData._id === this.updatedIndividualSericeStatus.id) {
           this.assetData['running'] = this.updatedIndividualSericeStatus['running'];
         }
       }
     }
   }
   if (this.assetDetailsAfterLinkingUpdated) {
     this.checkToUpdatedAssetLinkCount();
   }
   /*if (this.upadtedCartSpecificDetails) {
     if (this.assetData._id === this.upadtedCartSpecificDetails._id) {
       console.log('this.assetData', this.assetData);
       console.log('this.upadtedCartSpecificDetails', this.upadtedCartSpecificDetails);
       this.assetData = this.upadtedCartSpecificDetails;
     }
   }*/
  }

  checkForAssetImage() {
    this.getImageShowOnCardDetails()
    if (this.isThumbnailAccepted || this.isprofilePhotoAccepted) {
      if (this.assetData.isUpdated) {
        setTimeout(() => {
           this.getUpdatedRegisteredFaceThumbnail();
          }, 100);
        this.oneTimeImageLoaderInit(this.assetData['_id'], 'thumbnailImage');
      }
      // setTimeout(function() { this.getUpdatedRegisteredFaceThumbnail(); }, 100);
    }
    // const localThis = this;
    // _.forEach(this.config, function(config) {
    //   if (config['type'] === localThis.assetData['assetType']) {
    //     if (config['config']  && config['config']['fileUpload'] &&
    //       config['config']['fileUpload']['profilePhoto'] && config['config']['fileUpload']['profilePhoto']['accept'] === true) {
    //       // localThis.getProfilPicDetails(localThis.assetData);
    //       localThis.profileImage.isFound = true;
    //       if (localThis.assetData.isUpdated) {
    //         setTimeout(function() { localThis.getUpdatedRegisteredFaceThumbnail(); }, 100);
    //       }
    //     } else {
    //       localThis.profileImage.isFound = false;
    //     }
    //   }
    // });
  }

  oneTimeImageLoaderInit(id, type) {
    let that = this;
    setTimeout(() => {
    let oneTimeImageContainer = $('#' + id);
    if (oneTimeImageContainer.prop('complete')) {
      that.imagesLoader[type] = true;
      $(this).parent().find('.curtain').remove();
      // already loaded
    } else {
      $( "<div class='curtain'> <div class='shine'></div> </div>" ).insertAfter(oneTimeImageContainer);
      (oneTimeImageContainer).one('lazyloaded load', function() {
        // now it has loaded
        that.imagesLoader[type] = true;
        $(this).parent().find('.curtain').remove();
      });
    }
    }, 10);
  }

  checkToUpdatedAssetLinkCount() {
    if (this.assetData._id === this.assetDetailsAfterLinkingUpdated._id) {
      if (this.assetData && this.assetData.assetType) {
        this.getAssetsLinkedDetails(this.assetDetailsAfterLinkingUpdated);
      } else if (this.assetData && this.assetData.serviceType) {
        this.getAssetLinkedForService(this.assetDetailsAfterLinkingUpdated);
      }
    }
  }

  editAssetDetails(assetData) {
    this.isActiveCardStatus.emit(assetData);
    this.id = this.assetData._id;
    this.assetData['assetLabel'] = this.assetLabel;
    this.editAsset.emit(this.assetData);
    $('html').css('overflow-y', 'hidden');
  }

  closeBtnClicked() {
    this.delinkAssetDetails.emit(this.assetData);
  }

  delinkServiceBtnClicked() {
    this.delinkServiceDetails.emit(this.assetData);
  }

  /*linkBtnClicked() {
    this.linkAssetDetails.emit(this.assetData);
  }*/

  getAssetsLinkedDetails(assetData) {
    if (assetData.assetsLinked)  {
      let assetLinkedData: any;
      assetLinkedData = groupBy(assetData.assetsLinked, function (asset) {
        return asset.assetType;
      });
      this.assetLinkedDetails = assetLinkedData;
    } else if (assetData.serviceAssets) {
      let assetLinkedData: any;
      assetLinkedData = groupBy(assetData.serviceAssets, function (service) {
        return service.serviceType;
      });
      this.assetLinkedDetails = assetLinkedData;
    }
  }

  getServicesLinkedForAssetDetails(serviceLinkedToAsset) {
    if (serviceLinkedToAsset && serviceLinkedToAsset.length > 0) {
      let serviceLinkedData: any;
      serviceLinkedData = groupBy(serviceLinkedToAsset, function (service) {
        return service.serviceType;
      });
      this.assetLinkedDetails = serviceLinkedData;
    } else {
      this.assetLinkedDetails = {};
    }
  }

  getAssetLinkedForService(assetDetails) {
    if (assetDetails.gatewayAsset && assetDetails.gatewayAsset.assetType) {
      let obj: any = {};
      let propName: string = '';
      let array: any = [];
      propName = assetDetails.gatewayAsset.assetType;
      array.push(assetDetails.gatewayAsset);
      obj[propName] = array;
      this.assetLinkedDetails = obj;
    } else {
      this.assetLinkedDetails = {};
    }
  }

  getServiceAssetLabel() {
    /*this.serviceLabel = '';
    if (this.assetData && this.assetData.serviceType) {
      let assetDetails =  _.find(this.serviceAssetConfigDetails, ['type', this.assetData.serviceType]);
      this.serviceLabel = assetDetails.label;
    }*/
    this.serviceLabel = '';
    this.serviceLabelToolTip = '';
    this.serviceLabel = this.assetData.name;
    this.serviceLabelToolTip = 'Name';
  }

  getAssetLabel() {
    this.assetLabel = '';
    this.assetLabelToolTip = '';
    if (this.assetData && this.assetData.assetType) {
      let assetDetails =  find(this.assetConfigDetails, ['type', this.assetData.assetType]);
      let titleKey: string;
      if(assetDetails && assetDetails['configuration']){
          titleKey = findKey(assetDetails.configuration, function(o) { return o.isTitle === true; });
      }
      if (titleKey) {
        this.assetLabel = this.assetData[titleKey];
        this.assetLabelToolTip = assetDetails.configuration[titleKey]['label'];
      } else {
        if(assetDetails && assetDetails.label){
            this.assetLabel = assetDetails.label;
            this.assetLabelToolTip = assetDetails.label;
        }
      }
    }
  }

  getBoxShadowForCard(assetData) {
    if (assetData && assetData.assetType) {
      let type = assetData.assetType;
      let assetConfig = find(this.assetConfigDetails, ['type', type]);
      switch (type) {
        case type:
          if (assetConfig && assetConfig.theme) {
            return '0 2px 5px 0' + assetConfig.theme.color.primary, '0 2px 10px 0' + assetConfig.theme.color.primary;
          }
      }
    } else if (assetData && assetData.serviceType) {
      let type = assetData.serviceType;
      let assetConfig = find(this.serviceAssetConfigDetails, ['type', type]);
      switch (type) {
        case type:
          if (assetConfig && assetConfig.theme) {
            return '0 2px 5px 0' + assetConfig.theme.color.primary, '0 2px 10px 0' + assetConfig.theme.color.primary;
          }
      }
    }
  }

  selectedAssetsForLinking() {
    if (this.isLinkable) {
      this.embossedFlag = this.assetData['isAssetSelected'] ? this.assetData['isAssetSelected'] : false;
      // let obj = {};
      // obj['isAssetSelected'] = this.embossedFlag;
      // obj['assetData'] = this.assetData;
      // this.linkAssetDetails.emit(obj);
     // this.assetData['isAssetSelected'] = !this.embossedFlag;
      this.assetData = {...this.assetData, isAssetSelected: !this.embossedFlag};
      this.linkAssetDetails.emit(this.assetData);
    }
  }

  getProfilPicDetails(assetData) {
    this.assetService.getThumbnailImage(assetData['_id'])
      .subscribe((res) => {
        const that = this;
        if (res) {
          if (res.type && res.type === 'application/json') {
            that.profileImage.data = '../../assets/img-person-placeholder.jpg';
          } else {
            const reader = new FileReader();
            reader.readAsDataURL( res );
            reader.onload = function (e: any) {
              that.profileImage.data = e.target.result;
            };
          }
        }
      });
  }

  getUpdatedRegisteredFaceThumbnail() {
    let imageId: string;
    imageId = this.assetData._id;
    if (this.isThumbnailAccepted) {
      let imgUrl = this.assetService.getRegisteredFaceThumbnail(this.assetData._id);
      // imgUrl += '&date=' + new Date().getTime();
      $('img#' + imageId).attr('src', imgUrl);
    } else if (this.isprofilePhotoAccepted) {
      let imgUrl = this.assetService.getRegisteredProfilePhoto(this.assetData._id);
      // imgUrl += '&date=' + new Date().getTime();
      $('img#' + imageId).attr('src', imgUrl);
    }
  }

  getLangLabel(labelCode: string) {
    if (labelCode) {
      return this.lang.getTranslation(labelCode);
    }
  }

  getImageShowOnCardDetails(){
   /*  let aType = this.assetData.assetType;
    let groupByAssetType = groupBy(this.config, 'type');
    let fData = groupByAssetType[aType][0]['config']['showImageOnCard']; */

    const _this = this
    const url = _this.configService.appConfig.appBaseUrl + `asset/displayImageByAssetId/`+this.assetData._id+`?url=false&token=${this.token}`
    _this.imageDetails.imageUrl = this.imageDisplayStatus?.show ? url : null

    // console.log(groupByAssetType[aType][0]['config'], 'config details')
    // let isShow:any = false
    // if(fData){
    //   this.assetService.getOnCardImageUrl(this.assetData._id, fData)
    //   .then( url =>{this.imageDetails.imageUrl = url})
    //   .catch( url =>{this.setDefaultUrl();})
    // }
    // this.imageDetails.show = isShow;
    // if(this.imageDetails.show){
    // }


  }


  setDefaultUrl(){
    // this.assetService.getDefaultUrl(this.assetData.assetType).subscribe((response)=>{
    //     if(response && response['url']){
    //         this.imageDetails.imageUrl = response['url']
    //     }
    // })
  }


  onMouseEnter() {
    this.hover = true;
  }

  onMouseLeave() {
    this.hover = false;
  }


}
