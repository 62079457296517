import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {CarMeterGaugeType} from '../car-meter-gauge/Car-meter-gauge-type';
import {AssetService} from '../../../services/asset.service';
import {FormService} from '../../../services/form.service';
import {AssetConfigService} from '../../../services/asset-config.service';
import {ConfigService} from '../../../services/config.service';
import {ServiceLinkableService} from '../../../services/serviceLinkable.service';
import {HealthCheckService} from '../../../services/healthCheck.service';
import {GraphDataType} from '../sys-stats-graph/graph-data-type';
//import { Chart } from 'chart.js';
import {clone, find, findIndex, forEach, groupBy, keys, map, max, remove, sortBy} from 'lodash';
import moment from 'moment-timezone';
import {Actions, Subject} from '../../../auth/rules';
import {TrackingService} from '../../../services/tracking.service';
import {Ability, PureAbility} from '@casl/ability';
import {IotzenPillType} from '../iotzen-pills/iotzen-pill-type';
import {EChartSeries, EChartSeriesUpdate} from '../e-charts/e-charts-data-type';
import {FaceMatchService} from '../../../services/face-match.service';
import {LinkedAssetIconService} from '../../../services/linked-asset-icon.service';
import {SplitScreenService} from '../../../services/split-screen.service';
import {CONSTANT} from '../../../config/constant';
import {DateFormatterService} from '../../../services/dateformat';
import {latLng, Layer, Map} from 'leaflet';
import {SetCssPropertiesService} from '../../../services/set-css-properties.service';
import {LangUtilService} from '../../util/lang-util.service';
import printJS from 'print-js';
import {ConfirmationService, MenuItem, MessageService} from 'primeng/api';
import {Galleria} from 'primeng/galleria';
import {select, Store} from '@ngrx/store';
import {getUserMapSelector} from '../../../state/User/user.selector';
import {currentActiveId, selectIsSidebarFullScreen} from '../../../state/Sidebar/sidebar.selector';
import {TranslatePipe, TranslateService} from '@ngx-translate/core';
import {AblePipe} from '@casl/angular';
import { SocketActions } from '../../../state/Socket';
import { setSelectedIdToRowHighlight } from '../../../state/Sidebar/sidebar.action';
import { assetsCardsImageDisplayStatusSelector } from '../../../state/Assets/assets.selector';

declare let $: any;

const SIDEBAR_ID = CONSTANT.SIDEBAR_IDS.O2D_RIGHT_SIDEBAR;

// import { AppComponent } from '../../../app/app.component';

const CAR = CONSTANT.ASSET_TYPES.CAR;
const DELIVERY_PERSON = CONSTANT.ASSET_TYPES.DELIVERY_PERSON;

@Component({
  selector: 'app-right-sidebar',
  templateUrl: './right-sidebar.component.html',
  styleUrls: ['./right-sidebar.component.scss']
})
export class RightSidebarComponent implements OnChanges, OnInit, OnDestroy {
  isCurrentIdActive = false;
  trackerNameSpace: string;
  sidebarId = SIDEBAR_ID;
  currentActiveSidebarId$ ;
  profileImageUpload = {
    imageSelected: false,
    selectedFile: null,
    imagePreview: '../../../assets/image-person-placeholder.jpg',
    profilePhoto: null,
    isBtnDisabled: false,
    url: null
  };
  profileImageUrlInModel: string;
  serverLive = {
    show: false,
    isActive: false
  };
  cameraLive = {
    isActive: false,
    showCameraLive: false
  };
  _groupVariables = {
    // showLinkedAssets: true,
    // showLinkedGroups: false,
    // pill: {
    //   defaultActivePillIndex: 0,
    //   arrayOfLinkablePills: [],
    // },
    groups: [],
    fieldsToBeDisplayed: ['label', 'color', 'assetTypes'],
    groupsIdsToRemove: [],
    isGetGroupsInitiated: null,
    clearGroups: null
  };
  sideBarMenuItems: MenuItem[];
  selectedEventObjectArray = [];
  selectedEventStatusArray = []
  selectedEventList = [];
  selectedStatusList = [];
  carEvents: Object = {};
  imagesLoader = {
    thumbnailImage: false
  };
  showMap: boolean;
  markers: Layer[] = [];
  map: Map;
  mapOptions = {
    layers: [],
    zoom: 5,
    zoomControl: false,
    center: latLng(null, null)
  };
  isCarNotLinkedToAnyObdDevice: boolean;
  liveFeedSocketConnection = {};
  selectedAssetConfigDetails: any;
  file_count: number;
  qrCount = 0;
  public attachmentModal = false;
  public uploadImage = false;
  file_list = [];
  qr_list = [];
  uploadItemList: string[] = [];
  selectedUploadItem: any;
  uploadDisabled = true;
  HideItemNameInput = true;
  filetypeNameInput = '';
  uploadUrl: string;

  modal_options = {
    modalHeader: '',
    display: false,
    modalContent: ''
  }
  modal_content = {
    FILES: 'files',
    QRCODE: 'qr_code',
  }

  qrCode = {
    data: ''
  }
  uploadItemDescriton = [];

  cardsImageDisplayStatus$ = this.store.select(assetsCardsImageDisplayStatusSelector);

  // user map
  mapTiles$ = this.store.pipe(select(getUserMapSelector))

  isSidebarClosed = true;
  sidebarIsFullScreen$ = this.store.select(selectIsSidebarFullScreen);

  // carousel options-----------------
  showThumbnails: boolean;

  fullscreen = false;

  activeIndex = 0;

  onFullScreenListener: any;

  @ViewChild('galleria') galleria: Galleria;
  showFileUploadButton = true;
  showUploadBtn = false;
  showFileBadge = false;

  // carousel options-----------------
  public assetLabel: string;
  @Input() assetTypeToSend:string
  @Input() assetData: any;
  @Input() assetLinkedDetails: any;
  @Input() assetLinkedToServiceDetails: any;
  @Input() assetConfigDetails: any;
  @Input() serviceAssetConfigDetails: any;
  @Input() allServersStats: any;
  @Input() allServicesStatus: any;
  @Input() showCircularLoaderValue: any;
  @Input() isAssetDetailsInReadMode: boolean;
  @Input() isAssetDeregisteredSuccess: boolean;
  @Input() isServerManageActionSuccess: boolean;
  @Input() showFollowMeBtn: boolean;
  @Input() showCarStatus: boolean;
  @Input() resetFormData: number;
  @Input() modalTitle: string;
  @Output() delinkAssetDetailsToMainComponent: EventEmitter<any> = new EventEmitter();
  @Output() delinkServiceDetailsToMainComponent: EventEmitter<any> = new EventEmitter();
  @Input() isLinkDelinkAction: boolean;
  @Input() addGroupAction: boolean;
  @Input() removeGroupAction: boolean;
  @Input() isServiceLinkDelinkAction: boolean;
  @Input() isDefaultCarLiveTabOpen: boolean;
  @Input() IstoggleButtonRequired: boolean;
  @Input() isprofilePhotoAccepted: boolean;
  @Input() isThumbnailAccepted: boolean;
  @Input() isQrCodeRequired: boolean;
  @Input() carLive: object;
  @Input() gaugeItemsToShowInRightSlider: any;
  @Input() moduleTypeToGetEventTypes: string;
  @Output() linkAssetDetailsToMainComponent: EventEmitter<any> = new EventEmitter();
  @Output() addGroupDetailsToMainComponent: EventEmitter<any> = new EventEmitter();
  @Output() removeGroupDetailsToMainComponent: EventEmitter<any> = new EventEmitter();
  @Output() linkServiceDetailsToMainComponent: EventEmitter<any> = new EventEmitter();
  @Output() syncUpdateAssetCartEvent: EventEmitter<any> = new EventEmitter();
  @Output() deregisterAssetToMainComponent: EventEmitter<any> = new EventEmitter();
  @Output() manageServicesObjectToMainComponent: EventEmitter<any> = new EventEmitter();
  @Output() followMeBtnAction: EventEmitter<string> = new EventEmitter();
  @Output() imageUploadedFlag: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() toggleActiveClassEvent: EventEmitter<any> = new EventEmitter();

  @Input() isEventSelectedToEdit: boolean;
  @Input() showDeRegisterBtn: boolean;
  @Input() showUpdateBtn: boolean;
  @Input() eventToEdit: any;
  @Input() showCarStatusTab: boolean;
  @Input() isGroupTabRequired = true;
  @Input() isAssetForm;
  @Output() formSubmitTrigger: EventEmitter<any> = new EventEmitter();
  public formConfiguration: any;
  public assetUpdatedData: any;
  public resetSearchedAssetOnPlusClick: any;
  public resetSearchedServicesOnPlusClick: any;
  public searchForAsset = '';
  public infoLabel = '';
  public assetConfig: any;
  public individualAssetLinkedDetailsArray: Array<object> = [];
  public serviceActionsArray: Array<unknown> = [];
  public output: string;
  public assetFromConfig: Array<object> = [];
  public assetId;
  public serviceLabel: string;
  public linkedAssetDetails: any;
  public carStats = {
    speed: {
      chartData: {},
      show: false,
      showLoader: true,
      updateValue:''
    },
    rpm: {
      chartData: {},
      show: false,
      showLoader: true,
      updateValue: ''

    },
    aTemp: {
      chartData: {},
      show: false,
      showLoader: true,
      updateValue: ''

    },
    cTemp: {
      chartData: {},
      show: false,
      showLoader: true,
      updateValue: ''

    },
    fuelLevel: {
      chartData: {},
      show: false,
      showLoader: true,
      updateValue: ''

    },
  };
  public delinkAssetDetails: any;
  public isAssetLinkable: boolean;
  public showCircularLoader: boolean;
  public isEditOptionClicked: boolean;
  public showStatusOnAssetCard: boolean;
  public isServiceLinkable: boolean;
  public isServerAvailable: boolean;
  public isServiceManageable: boolean;
  public showCarStatusGraphs: boolean;
  public showCarLiveDataGraphs: boolean;
  public showStatus: boolean;
  public customCollapsedHeight: string;
  public customExpandedHeight: string;
  public statsDetails: any = null;
  public statsNotFound: boolean;
  public carStatsNotFound: boolean;
  public showStatsGraphTabs: boolean;
  // public isGaugeGraphToggle: boolean;
  public liveUpdatedTime: string;
  public coolantTemperature: number;
  public boxTemperature: any;
  public graphDiskRandomNumber: number;
  public graphCpuRandomNumber: number;
  public graphMemoryRandomNumber: number;
  //public carSpeedChart: Chart;
  public graphCarSpeedRandomNumber: number;
  public graphCarRpmRandomNumber: number;
  public graphCarAatempRandomNumber: number;
  public graphCarCtempRandomNumber: number;
  public resetCarMeterGaugeRandomNumber: number;
  public obdOdoMeterReading: number;
  public carEventsArray: Array<object> = [];
  public carEventDataLatestPush: any;
  public carEventDataNotAvailable: boolean;
  public showCarEventComponent: boolean;
  public isCarSelectedToShowEvents: boolean;
  showCircularLoaderForImg: boolean;
  imageuploadError: string;
  activateUploadingBtn: boolean;
  profileImageForModal: string;
  SUBJECT = Subject;
  ACTIONS = Actions;
  public rawGpsData: any = null;
  public arrayOfDurationPills: Array<IotzenPillType>;
  public arrayOfTypePills: Array<IotzenPillType> = [];
  public typeRadio = 'gauge';
  defaultActivePillIndex = 0;
  selectedLinkedAsset: any;
  triggerCarFollowAction: number;
  public carSpeedGaugeOptions: CarMeterGaugeType = {
    name: '',
    radius: '',
    min: 0,
    max: 200,
    data: [{
      value: '0',
      name: ''
    }],
    axisLineColor: [
      {
        position: 0.3,
        color: '#67e0e3'
      },
      {
        position: 0.7,
        color: '#37a2da'
      },
      {
        position: 1,
        color: '#fd666d'
      }
    ]
  };
  public carRpmGaugeOptions: CarMeterGaugeType = {
    name: '',
    radius: '',
    min: 0,
    max: 7,
    data: [{
      value: '0',
      name: ''
    }],
    axisLineColor: [
      {
        position: 0.3,
        color: '#67e0e3'
      },
      {
        position: 0.7,
        color: '#37a2da'
      },
      {
        position: 1,
        color: '#fd666d'
      }
    ]
  };
  public carFuelGaugeOptions: CarMeterGaugeType = {
    name: '',
    radius: '',
    min: 0,
    max: 60,
    data: [{
      value: '0',
      name: ''
    }],
    axisLineColor: [
      {
        position: 0.1,
        color: '#fd666d'
      },
      {
        position: 0.7,
        color: '#37a2da'
      },
      {
        position: 1,
        color: '#67e0e3'
      }
    ]
  };
  public coldChainBoxTempGaugeOptions: CarMeterGaugeType = {
    name: '',
    radius: '',
    min: 0,
    max: 20,
    data: [{
      value: '0',
      name: ''
    }],
    axisLineColor: [
      {
        position: 0.3,
        color: '#67e0e3'
      },
      {
        position: 0.5,
        color: '#37a2da'
      },
      {
        position: 1,
        color: '#fd666d'
      }
    ]
  };
  options = null;
  boxStatusGraph = {
    temperature: {
      chartData: {},
      show: false,
      showLoader: true,
      updateValue: ''

    },
    humidity: {
      chartData: {},
      show: false,
      showLoader: true,
      updateValue: ''

    }
  }
  public eChartGraph: any = {
    cpu: {
      graph: {
        series: [],
        legend: [],
        type: 'line',
        title: 'Inspection',
        xAxis: []
      },
      show: false,
      showEChartLoader: false,
      type: 'cpu',
      dataSetsBackgroundColor: 'rgba(56, 142, 60,1.9)',
      title: 'Cpu Stats'
    },
    disk: {
      graph: {
        series: [],
        legend: [],
        type: 'line',
        title: 'Inspection',
        xAxis: []
      },
      show: false,
      showEChartLoader: false,
      type: 'disk',
      dataSetsBackgroundColor: 'rgba(41,182,246,1.9)',
      title: 'Disk Stats'
    },
    memory: {
      graph: {
        series: [],
        legend: [],
        type: 'line',
        title: 'Inspection',
        xAxis: []
      },
      show: false,
      showEChartLoader: false,
      type: 'memory',
      dataSetsBackgroundColor: 'rgba(41,182,246,1.9)',
      title: 'Memory Stats'
    }
  };

  public carDistanceMovedStats = {
    distanceMoved: {
      chartData: {},
      show: false,
      showLoader: true,
      updateValue: ''

    }
  };
  memoryValue: (value: number) => string;
  // carousel options
  images: any[] = [];

  public arrayOfServicesLinkMethods: Array<IotzenPillType>;
  _serviceLinkMethods = {
    activePillIndex: 0,
    showLink: true,
    showDeploy: false
  };

  actionMessage = '';
  _serviceDeploy: {
    selectedService: any,
    allServicesToManage: Array<object>,
    showForm: boolean,
    uploadedFiles: Array<string>,
    hintToUploadFiles: string,
    disableSubmit: boolean
  } = {
    selectedService: null,
    allServicesToManage: [],
    showForm: false,
    uploadedFiles: [],
    hintToUploadFiles: '',
    disableSubmit: true
  };

  _box: {
    isboxStatusRequiredToShow: boolean,
    isBoxOpened: boolean,
    isBoxClosed: boolean,
    boxOpenStatusText: string
  } = {
    isboxStatusRequiredToShow: false,
    isBoxOpened: false,
    isBoxClosed: false,
    boxOpenStatusText: '',
  };
  responsiveOptions: any[] = [
    {
      breakpoint: '1024px',
      numVisible: 5
    },
    {
      breakpoint: '768px',
      numVisible: 3
    },
    {
      breakpoint: '560px',
      numVisible: 1
    }
  ];

  buildSidebarMenuItems() {
    const translate = new TranslatePipe(this.translate, this.cd);
    const ablePipe = new AblePipe(this.pureAbility);
    this.sideBarMenuItems = [];
    this.sideBarMenuItems.push(
      {
        label: translate.transform(this.infoLabel),
        command: (event) => {
          this.openTabContent(event.originalEvent, 'assetInfo');
        }
      }
    )


    if (this.carLive && this.carLive['showCarLive']) {
      this.sideBarMenuItems.push(
        {
          label: translate.transform('sys.live'),
          command: (event) => {
            this.openTabContent(event.originalEvent, 'carLiveData');
          }
        },
      );
    }

    if (this.assetData && this.assetData.serviceType && this.isAssetLinkable && ablePipe.transform(this.ACTIONS.READ, this.SUBJECT.ASSET)) {
      this.sideBarMenuItems.push(
        {
          label: translate.transform('sys.linked'),
          command: (event) => {
            this.openTabContent(event.originalEvent, 'link');
          }
        },
      );
    }

    if (this.assetData && this.assetData.serviceType && this.isServiceManageable && ablePipe.transform(this.ACTIONS.WRITE, this.SUBJECT.SERVICE)) {
      this.sideBarMenuItems.push(
        {
          label: translate.transform('sys.manage'),
          command: (event) => {
            this.openTabContent(event.originalEvent, 'manageService');
          }
        },
      );
    }

    if (this.assetData && this.assetData.assetType && this.isAssetLinkable && ablePipe.transform(this.ACTIONS.READ, this.SUBJECT.ASSET)) {
      this.sideBarMenuItems.push(
        {
          label: translate.transform('sys.linked'),
          command: (event) => {
            this.openTabContent(event.originalEvent, 'link');
          }
        },
      );

    }

    if (this.isGroupTabRequired && this.assetData && this.assetData.assetType && ablePipe.transform(this.ACTIONS.READ, this.SUBJECT.GROUP)) {
      this.sideBarMenuItems.push(
        {
          label: translate.transform('sys.groups'),
          command: (event) => {
            this.openTabContent(event.originalEvent, 'groups');
          }
        },
      );
    }

    if (this.assetData && this.assetData.assetType &&
      this.isServiceLinkable && ablePipe.transform(this.ACTIONS.READ, this.SUBJECT.SERVICE)) {
      this.sideBarMenuItems.push(
        {
          label: translate.transform('sys.service'),
          command: (event) => {
            this.openTabContent(event.originalEvent, 'service');
            this._serviceLinkMethods.showLink = true;
            this._serviceLinkMethods.showDeploy = false;
            this._serviceLinkMethods.activePillIndex = 0;
            this._serviceDeploy.selectedService = null;
          }
        },
      );
    }
  }

  constructor(private pureAbility: PureAbility, private cd: ChangeDetectorRef, private translate: TranslateService, public assetService: AssetService, public formService: FormService, private assetConfigService: AssetConfigService, private trackingService: TrackingService,
              public configService: ConfigService, public healthCheckService: HealthCheckService, public serviceLinkableService: ServiceLinkableService, private lang: LangUtilService,
            public faceMatchService: FaceMatchService, public setCssProperties: SetCssPropertiesService,
              private ability: Ability, private linkedAssetIconService: LinkedAssetIconService,
              private splitScreenService: SplitScreenService,
              public dateFormatter: DateFormatterService,
              private messageService: MessageService,
              private confirmationService: ConfirmationService, private store: Store) {
    this.trackerNameSpace = CONSTANT.SOCKET.TRACKER.NAMESPACE.TRACKER_GPS;
    this.isAssetLinkable = false;
    this.showCircularLoader = true;
    this.isEditOptionClicked = false;
    this.isAssetDetailsInReadMode = false;
    this.isAssetDeregisteredSuccess = false;
    this.isServerManageActionSuccess = false;
    this.isServiceLinkable = false;
    this.isServiceManageable = false;
    this.isServerAvailable = false;
    this.isDefaultCarLiveTabOpen = false;
    this.showFollowMeBtn = false;
    this.showStatusOnAssetCard = false;
    // this.isGaugeGraphToggle = false;
    this.showStatus = false;
    this.showCarStatus = false;
    this.showCarStatusGraphs = false;
    this.showCarLiveDataGraphs = false;
    this.carStatsNotFound = true;
    this.statsNotFound = false;
    this.showStatsGraphTabs = false;
    this.showCarEventComponent = false;
    this.carEventDataNotAvailable = false;
    this.serviceAssetConfigDetails = [];
    this.serviceActionsArray = [];
    this.assetLabel = '';
    this.serviceLabel = '';
    this.obdOdoMeterReading = 0;
    this.customCollapsedHeight = '70px';
    this.customExpandedHeight = '70px';
    this.imageuploadError = null;
    this.arrayOfDurationPills = [{
      id: 'oneHour',
      label: '1H',
      description: 'Last 1 Hour',
      inActiveImgSrc: '',
      activeImgSrc: ''
    },
      {id: 'sixHours', label: '6H', description: 'Last 6 Hours', inActiveImgSrc: '', activeImgSrc: ''},
      {id: 'twelveHours', label: '12H', description: 'Last 12 Hours', inActiveImgSrc: '', activeImgSrc: ''},
      {id: 'oneDay', label: '1D', description: 'Last 1 Day', inActiveImgSrc: '', activeImgSrc: ''}];
    this.memoryValue = function (value: number): string {
      return `${Math.round(value)} / ${this['max']}`;
    };
    this.isCarSelectedToShowEvents = true;
    this.showCircularLoaderForImg = false;
    this.isCarNotLinkedToAnyObdDevice = false;
    this.arrayOfServicesLinkMethods = [{
      id: 'link',
      label: 'Link',
      description: 'Link the service',
      inActiveImgSrc: '',
      activeImgSrc: ''
    },
      {id: 'deploy', label: 'Deploy', description: 'Deploy the service', inActiveImgSrc: '', activeImgSrc: ''}];
  }

  ngOnDestroy() {
    // this.disconnectFromNameSpace(this.trackerNameSpace);
    this.currentActiveSidebarId$?.unsubscribe();
    this.store.dispatch(SocketActions.disconnect({
      nsp: this.trackerNameSpace,
      topic: CONSTANT.SOCKET.TRACKER.TOPICS.OBD_DEVICE_GPS_PUSH,
      key: this.sidebarId
    }));
    if (this.carLive) {
      this.carLive['showCarLive'] = false;
    }
    this.unbindDocumentListeners();

  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName of Object.keys(changes)) {
      const change = changes[propName];
      if (propName === 'assetData') {
        if (change.currentValue !== change.previousValue) {
          if (this.assetData && this.assetData['_id']) {
            this.getFileDetails()
            this.loadCarousel();
            this.getAssetType();
            this.getQrCode();
          }
          if (this.assetData && this.assetData.assetType) {
            // if (this.isThumbnailAccepted) {
            //   this.oneTimeImageLoaderInit('thumbnailImage' + this.assetData._id, 'thumbnailImage');
            // }
            setTimeout(() => {
              this.oneTimeImageLoaderInit('thumbnailImage' + this.assetData?._id, 'thumbnailImage');
            }, 10);
            this.splitScreenService.checkForTabsPagination('#sidebar-tabs-container');
            this.isEditOptionClicked = false;
            this._groupVariables.groupsIdsToRemove = [];
            this.resetProfileImageUploadValues();
            this.selectedLinkedAsset = null;
            this.infoLabel = this.lang.getTranslation('app.core.custom-modal.asset-info');//'Asset Info';
            this.getAssetsConfiguration();
            this.modify();
            this.assetData = this.checkServerStatus(this.assetData);
            this.getObdOdoMeterReading();
            this.openTabContent('', 'assetInfo');
            $('#assetListingPopup').hide(300);
            $('#serviceListingPopup').hide(300);
            $('#groupListingPopup').hide(300);
            this.serverLive.show = this.checkIfAssetTypeIsServer();
            this.getAddedGroupsForAsset();
            this.toggleLiveTab();
            this.checkIfCarLinkedToObdDevice();
            this.checkWhichGaugeToDisplayInLiveTab(this.assetData.assetType);
            // this.buildLinkedPillsArray();
          }
          if (this.assetData && this.assetData.serviceType) {
            this.splitScreenService.checkForTabsPagination('#sidebar-tabs-container');
            this.isEditOptionClicked = false;
            this.selectedLinkedAsset = null;
            this.infoLabel = this.lang.getTranslation('app.core.custom-modal.service-info');
            this.showStatusOnAssetCard = true;
            this.getAssetsConfiguration();
            this.getServiceAssetConfiguration();
            this.getServiceAssetConfigByType();
            this.openTabContent('', 'assetInfo');
            $('#assetListingPopup').hide(300);
            $('#serviceListingPopup').hide(300);
            $('#groupListingPopup').hide(300);
          }
          /*if (!this.isLinkDelinkAction && !this.isServiceLinkDelinkAction && !this.isServerManageActionSuccess) {
            this.openTabContent('', 'assetInfo');
          }*/
          if (this.isServerManageActionSuccess) {
            setTimeout(() => {
              this.openTabContent('', 'manageService');
            }, 10);
          }
          if (this.isDefaultCarLiveTabOpen) {
            setTimeout(() => {
              this.openTabContent('', 'carLiveData');
            }, 10);
          }
          if (this.showCarStatusTab) {
            setTimeout(() => {
              this.openTabContent('', 'carStatus');
            }, 10);
          }
          if (this.assetData && this.assetData.assetType) {
            const groupByAssetType = groupBy(this.assetConfigDetails, 'type');
            this.isAssetLinkable = groupByAssetType && groupByAssetType[this.assetData.assetType] &&
              groupByAssetType[this.assetData.assetType][0] &&
              groupByAssetType[this.assetData.assetType][0].config &&
              groupByAssetType[this.assetData.assetType][0].config.assetsLinkable &&
              groupByAssetType[this.assetData.assetType][0].config.assetsLinkable.length > 0;
          } else if (this.assetData && this.assetData.serviceType) {
            const groupByServiceType = groupBy(this.serviceAssetConfigDetails, 'type');
            this.isAssetLinkable = groupByServiceType && groupByServiceType[this.assetData.serviceType] &&
              groupByServiceType[this.assetData.serviceType][0] &&
              groupByServiceType[this.assetData.serviceType][0].config &&
              groupByServiceType[this.assetData.serviceType][0].config.assetsLinkable;
          }
          this._box.isboxStatusRequiredToShow = false;
        }
        this.buildSidebarMenuItems()
      }
      if (propName === 'assetLinkedDetails') {
        if (change.currentValue !== change.previousValue) {
          if (this.assetData && this.assetData.assetsLinked && this.assetData.assetsLinked.length > 0) {
            this.getLinkedAssetDetails(this.assetData._id);
          } else if (this.assetData && this.assetData.serviceAssets && this.assetData.serviceAssets.length > 0) {
            this.getServicesLinkedToAssetDetails();
          } else {
            this.individualAssetLinkedDetailsArray = [];
          }
          if (this.isLinkDelinkAction) {
            setTimeout(() => {
              this.openTabContent('', 'link');
            }, 10);
          } else if (this.isServiceLinkDelinkAction) {
            setTimeout(() => {
              this.openTabContent('', 'service');
            }, 10);
          }
        }
      }
      if (propName === 'assetLinkedToServiceDetails') {
        if (change.currentValue !== change.previousValue) {
          this.modifyAssetLinkedToServiceData();
          /*if (this.assetLinkedToServiceDetails) {
            this.assetLinkedToServiceDetails(this.assetData._id);
          } else {
            this.individualAssetLinkedDetailsArray = [];
          }
          if (this.isLinkDelinkAction) {
            this.openTabContent('', 'link');
          }*/
        }
      }
      // if(propName === 'IstoggleButtonRequired'){

      //   console.log(change.currentValue);
      //   if (change.currentValue !== change.previousValue) {
      //     this.app.layoutMode = 'static';
      //   }
      // }
      if (propName === 'showCircularLoaderValue') {
        if (change.currentValue !== change.previousValue) {
          this.showCircularLoader = true;
        }
      }
      if (propName === 'resetFormData') {
        if (change.currentValue !== change.previousValue) {
          this.showStatusOnAssetCard = false;
        }
      }
      if (propName === 'isAssetDeregisteredSuccess') {
        if (change.currentValue !== change.previousValue) {
          if (this.isAssetDeregisteredSuccess === true) {
            this.isEditOptionClicked = false;
          }
        }
      }
      if (propName === 'addGroupAction') {
        if (change.currentValue !== change.previousValue) {
          if (this.addGroupAction === true) {
            this._groupVariables.groupsIdsToRemove = [];
            this.openTabContent('', 'groups');
            this.getAddedGroupsForAsset();
            this._groupVariables.clearGroups = Math.random();
          }
        }
      }
      if (propName === 'removeGroupAction') {
        if (change.currentValue !== change.previousValue) {
          if (this.removeGroupAction === true) {
            this._groupVariables.groupsIdsToRemove = [];
            this.openTabContent('', 'groups');
            this.getAddedGroupsForAsset();
          }
        }
      }
    }
  }

  ngOnInit() {
    this.currentActiveSidebarId$ = this.store.select(currentActiveId).subscribe(res =>{
      if(res === this.sidebarId)
      this.isCurrentIdActive = true;
      else
      this.isCurrentIdActive = false;
    })
    setTimeout(() => {
      $('#sidebar-tabs-container').removeClass('mat-tab-header-pagination-controls-enabled');
    }, 500);
    if (this.isThumbnailAccepted || this.isprofilePhotoAccepted) {
      setTimeout(() => {
        this.oneTimeImageLoaderInit('thumbnailImage' + this.assetData?._id, 'thumbnailImage');
      }, 10);
    }
    this.bindDocumentListeners();
  }

//  --------get File Details----------
  getFileDetails(isCount = true) {
    this.file_list = [];
    const assetId = this.assetData._id;
    if (isCount) {
      this.assetService.getFileDetails(assetId, isCount).subscribe((res) => {
        if (res && res['count']) {
          this.file_count = res['count'];
        } else {
          this.file_count = 0;
        }
      })
    }
    if (!isCount) {
      this.assetService.getFileDetails(assetId, isCount).subscribe((res) => {
        forEach(res, (item) => {
          if (item && item['name']) {
            this.file_list.push(item)
          }
        })
      })
    }
  }

  filesIconClicked() {
    this.modal_options.display = true;
    this.modal_options.modalContent = this.modal_content['FILES']
    this.modal_options.modalHeader = 'Attached Files';
    this.getFileDetails(false)
  }

  // myUploader(evt, uploadItem){
  //     console.log(evt)
  //     this.assetService.sendAssetByAssetName(uploadItem, evt.files[0], this.assetData['_id']).subscribe((res)=>{
  //         console.log(res['msg'])
  //     })

  downloadSampleFile(fileiId) {
    const token = sessionStorage.getItem('token')
    window.open(this.configService.appConfig.appBaseUrl + 'asset/fileV2/' + fileiId + '?token=' + token, '_blank');

  }

  //   upload icon
  uploadIconClicked() {
    this.uploadImage = true;
  }

  // }
  onSelect() {
    this.buildUploadUrl();

  }

  onUpload(evt) {
    if (!(this.uploadItemList.length === 1)) {
      this.selectedUploadItem = '';
    }
    this.filetypeNameInput = ''
    this.uploadDisabled = true
    this.HideItemNameInput = true;
    this.showUploadBtn = true;
    this.showFileUploadButton = true;
    this.loadCarousel();
    this.getFileDetails();

    if (evt && evt.originalEvent.status && evt.originalEvent.status === 200) {
      const msg = 'File uploaded successfully';
      this.showSuccess(msg)
    }

  }

  onError(evt) {
    const errMsg = `File upload failed: ${evt.error['error']}`
    this.showFileUploadButton = true;
    // this.selectedUploadItem = '';
    this.uploadDisabled = false


    this.onErrorMessage(errMsg)
  }


  // message service

  onUploadStarted() {
    // this.selectedUploadItem = '';
    this.filetypeNameInput = ''
    this.uploadDisabled = true
    this.HideItemNameInput = true;

    this.showFileUploadButton = false;
  }

  buildUploadUrl() {
    if (this.assetData?.['_id']) {
      this.uploadUrl = this.configService.appConfig.appBaseUrl + `asset/filesV2/${this.assetData?.['_id']}/${this.selectedUploadItem}`
    }
  }

  showSuccess(msg) {
    this.messageService.add({severity: 'success', summary: 'Success', detail: msg});
  }

  onErrorMessage(msg) {
    this.messageService.add({severity: 'error', summary: 'Error', detail: msg});
  }

  // radio button
  radioButtonSelected(SI) {
    if (SI.length > 0 && !(SI === 'others')) {
      this.uploadDisabled = false
      this.HideItemNameInput = true;

    }
    if (SI.length > 0 && (SI === 'others')) {
      // enable Input
      this.HideItemNameInput = false;

      // set input to selected item
      this.uploadDisabled = true

    }
    this.buildUploadUrl();
  }

  // QR cunt and imges from api code
  getQrCode(isCount = true) {
    if (isCount) {
      this.assetService.getFileDetails(this.assetData['_id'], isCount, 'qrcode').subscribe((response) => {
        if (response && response['count']) {
          this.qrCount = response['count'];
        } else {
          this.qrCount = 0;
        }
      })
    }
    if (!isCount) {
      this.assetService.getFileDetails(this.assetData['_id'], isCount, 'qrcode').subscribe((response: {
        data: string
      }) => {
        if (response?.['data']) {
          this.qrCode = response
        }
      })
    }

  }

  qrIconClicked() {
    this.modal_options.display = true;
    this.modal_options.modalContent = this.modal_content['QRCODE']
    this.modal_options.modalHeader = 'QR Code';
    this.getQrCode(false)
  }

  uploadModalHideEvent() {
    this.filetypeNameInput = ''
    this.uploadDisabled = true
    this.HideItemNameInput = true;
    this.uploadDisabled = false;

    if (!(this.uploadItemList.length === 1)) {
      this.selectedUploadItem = '';
      this.uploadDisabled = true;
    }

  }

  addFileName(fileName) {
    if (fileName) {
      this.uploadItemList.push(fileName);
      this.selectedUploadItem = fileName;
      this.filetypeNameInput = ''
      this.uploadDisabled = false
      this.HideItemNameInput = true;

    }
  }

  containsOthers() {
    if (this.assetData && this.assetData['_id']) {
      // return true

      if (this.uploadItemList.includes("others")) {
        return true
      } else {
        return false
      }
    }

  }

  transformFileName(fileName) {
    const fN: string = fileName + '';
    const result = fN.replace(/([A-Z])/g, " $1");
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult;
  }

  loadCarousel() {
    this.activeIndex = 0;
    this.images = []
    this.assetService.getFileDetails(this.assetData['_id'], false, 'image').subscribe((response) => {
      const token = sessionStorage.getItem('token')
      const imgArr = []
      forEach(response, (res) => {
        if (res['_id']) {
          const url = this.configService.appConfig.appBaseUrl + 'asset/fileV2/' + res['_id'] + '?token=' + token + '&date=' + moment().valueOf();
          const data = {
            "previewImageSrc": url,
            "thumbnailImageSrc": url,
            "alt": '',
            "title": this.transformFileName(res['name']),
            'assetId': res['_id']
          }
          imgArr.push(data)
        }
      })
      this.images = imgArr;
    })


  }

  // get asset type
  getAssetType() {
    if (this.assetData && this.assetData['assetType']) {
      this.assetService.getAssetType(this.assetData['assetType']).subscribe((res) => {
        const assetType = find(res, (item) => {
          if (this.assetData && (item['type'] === this.assetData['assetType'])) {
            return item
          }
        })
        if (assetType) {

          // image upload code
          const fileUpload = assetType['config']['fileUpload'];
          if (fileUpload && !(Object.keys(fileUpload).length === 0)) {
            this.showUploadBtn = true;

            this.uploadItemList = Object.keys(fileUpload)
            if (this.uploadItemList.length === 1) {
              this.selectedUploadItem = this.uploadItemList[0]
              this.radioButtonSelected(this.selectedUploadItem)
            }

            if (this.uploadItemList.length > 0) {
              forEach(this.uploadItemList, (_, i) => {
                this.uploadItemDescriton.push(fileUpload[this.uploadItemList[i]].description);
              })
            }

          } else {
            this.showUploadBtn = false;
          }

          this.assetService.getFileDetails(this.assetData['_id'], true, 'image').subscribe(() => {

          })
          this.assetService.getFileDetails(this.assetData['_id'], true, 'file').subscribe((res) => {
            if (res && res['count']) {
              if (res['count'] > 0) {
                this.file_count = res['count'];
                this.showFileBadge = true;
              }
            }
          })
        }

      })
    }
  }


  buildPillsArrayForLiveTab() {
    this.arrayOfTypePills = [];
    const pillsToShow = this.carLive['contentTypesToShow'].length > 0 ? this.carLive['contentTypesToShow'] : [];
    forEach(pillsToShow, (pillId) => {
      pillId = pillId[0].toUpperCase() + pillId.slice(1).toLowerCase();
      this.arrayOfTypePills.push({
        'id': pillId.toLowerCase(),
        'label': pillId,
        'description': 'Selected Type ' + pillId,
        'inActiveImgSrc': '',
        'activeImgSrc': ''
      });
    });
    // this.arrayOfTypePills = [
    //   {id: 'gauge', label: 'Gauge', description: 'Selected Type Gauge', inActiveImgSrc: '', activeImgSrc: ''},
    //   {id: 'graph', label: 'Graph', description: 'Selected Type Graph', inActiveImgSrc: '', activeImgSrc: ''}];
  }

  checkIfAssetTypeIsServer(): boolean {
    let isServer: boolean;
    isServer = false;
    if (this.assetData.assetType && this.assetData.assetType === CONSTANT.ASSET_TYPES.SERVER) {
      isServer = true;
    }
    return isServer;
  }

  getActivePillValue(value) {
    this.showMap = false;
    switch (value) {
      case 'gauge':
        this.typeRadio = 'gauge';
        this.carLive['selectedContentType'] = 'gauge';
        // this.isGaugeGraphToggle = false;
        this.getCarStatus();
        this.setCarGaugeValues();
        this.defaultActivePillIndex = this.setPillActiveInLiveTab(this.typeRadio);
        this.showMap = true;
        break;
      case 'graph':
        this.typeRadio = 'graph';
        this.carLive['selectedContentType'] = 'graph';
        // this.isGaugeGraphToggle = true;
        this.defaultActivePillIndex = this.setPillActiveInLiveTab(this.typeRadio);
        this.showMap = true;
        break;
      case 'map':
        this.typeRadio = 'map';
        this.carLive['selectedContentType'] = 'map';
        this.defaultActivePillIndex = this.setPillActiveInLiveTab(this.typeRadio);
        this.showMap = true;
        // setTimeout(() => {
        //   this.triggerCarFollowAction = Math.random();
        // },5000);
        break;
      default:
        break;
    }
  }

  setPillActiveInLiveTab(type) {
    const _this = this;
    let index: number;
    index = findIndex(_this.carLive['contentTypesToShow'], function (value: any) {
      return value === type;
    });
    return index;
  }

  checkWhichGaugeToDisplayInLiveTab(assetType) {
    if (assetType === DELIVERY_PERSON) {
      this.gaugeItemsToShowInRightSlider = {
        isSpeedGaugeRequired: false,
        isCoolentTempGaugeRequired: false,
        isFuelGaugeRequired: false,
        isRmpGaugeRequired: false,
        isObdGaugeRequired: false,
        isBoxTempGaugeRequired: true,
        isBoxOpenStatusGaugeRequired: true,
      };
    } else if (assetType === CAR) {
      this.gaugeItemsToShowInRightSlider = {
        isSpeedGaugeRequired: true,
        isCoolentTempGaugeRequired: true,
        isFuelGaugeRequired: true,
        isRmpGaugeRequired: true,
        isObdGaugeRequired: true,
        isBoxTempGaugeRequired: false,
        isBoxOpenStatusGaugeRequired: false,
      };
    }
  }

  // buildLinkedPillsArray() {
  //   this._isLinkable.pill.arrayOfLinkablePills = [{id: 'linkedAssets', label: 'Assets', description: 'Linked Assets', inActiveImgSrc: '', activeImgSrc: ''}];
  //   this._isLinkable.showLinkedAssets = true;
  //   this._isLinkable.showLinkedGroups = false;
  //   this._isLinkable.pill.defaultActivePillIndex = 0;
  // }

  // getLinkedActivePillValue(value) {
  //   switch (value) {
  //     case 'linkedAssets':
  //       this._isLinkable.showLinkedAssets = true;
  //       this._isLinkable.showLinkedGroups = false;
  //       this._isLinkable.pill.defaultActivePillIndex = 0;
  //       this._isLinkable.isGetGroupsInitiated = null;
  //       this._isLinkable.deLinkGroupsIds = [];
  //       break;
  //     case 'linkedGroups':
  //       this._isLinkable.showLinkedAssets = false;
  //       this._isLinkable.showLinkedGroups = true;
  //       this._isLinkable.pill.defaultActivePillIndex = 1;
  //       break;
  //     default:
  //       break;
  //   }
  // }

  onSelectFile(event) {
    this.profileImageUpload.selectedFile = event.target.files[0];
    const reader = new FileReader();
    this.profileImageUpload.imagePreview = reader.result as string;
    reader.onload = () => {
      this.profileImageUpload.imagePreview = reader.result as string;
    };
    reader.readAsDataURL(this.profileImageUpload.selectedFile);
    this.profileImageUpload.imageSelected = true;
  }

  showMessagePopup(popup, x?) {
    $('.' + popup).fadeIn(300);
    setTimeout(() => {
      $('.' + popup).fadeOut(300);
    }, x ? x : 3000);
  }

  deleteProfilePic() {
    // console.log('deleteProfilePic');
  }

  isAssetDelinkBtnReqired(): boolean {
    let isAssetDelinkBtnReqired: boolean;
    isAssetDelinkBtnReqired = false;
    if (this.assetData && this.assetData.assetType) {
      if (this.ability.can(Actions.UPDATE, Subject.ASSET)) {
        isAssetDelinkBtnReqired = true;
      }
    } else if (this.assetData && this.assetData.serviceType) {
      if (this.ability.can(Actions.UPDATE, Subject.ASSET)) {
        if (this.ability.can(Actions.UPDATE, Subject.SERVICE)) {
          isAssetDelinkBtnReqired = true;
        }
      }
    }
    return isAssetDelinkBtnReqired;
  }

  isServiceDelinkBtnReqired(): boolean {
    let isServiceDelinkBtnReqired: boolean;
    isServiceDelinkBtnReqired = false;
    if (this.assetData && this.assetData.assetType) {
      if (this.ability.can(Actions.UPDATE, Subject.ASSET)) {
        if (this.ability.can(Actions.UPDATE, Subject.SERVICE)) {
          isServiceDelinkBtnReqired = true;
        }
      }
    }
    return isServiceDelinkBtnReqired;
  }

  checkServerStatus(assetData) {
    let serverStatusGroupById: any;
    serverStatusGroupById = groupBy(this.allServersStats, '_id');
    if (serverStatusGroupById[assetData._id]) {
      if ((serverStatusGroupById[assetData._id][0]._id) === assetData._id) {
        assetData.running = true;
      }
    }
    return assetData;
  }

  getServicesLinkedToAssetDetails() {
    if (this.assetData && this.assetData.serviceAssets) {
      // this.individualAssetLinkedDetailsArray = this.modifyAssetLinkedData(this.assetData.serviceAssets);
      let individualAssetLinkedDetailsArray: any = [];
      individualAssetLinkedDetailsArray = this.modifyAssetLinkedData(this.assetData.serviceAssets);
      this.individualAssetLinkedDetailsArray = this.checkServicesLinkedToAssetStatus(individualAssetLinkedDetailsArray);
      this.individualAssetLinkedDetailsArray = this.linkedServiceButtonTitleFormation(this.individualAssetLinkedDetailsArray);
      this.getIconAndColorsForLinkedAssets(this.individualAssetLinkedDetailsArray, this.serviceAssetConfigDetails);
    }
  }

  modifyAssetLinkedToServiceData() {
    if (this.assetLinkedToServiceDetails) {
      const array: any = [];
      let individualAssetLinkedDetailsArray: any = [];
      array.push(this.assetLinkedToServiceDetails);
      // this.individualAssetLinkedDetailsArray = this.modifyAssetLinkedData(array);
      individualAssetLinkedDetailsArray = this.modifyAssetLinkedData(array);
      this.individualAssetLinkedDetailsArray = this.checkAssetsLinkedToServiceStatus(individualAssetLinkedDetailsArray);
      this.individualAssetLinkedDetailsArray = this.linkedAssetsButtonTitleFormation(this.individualAssetLinkedDetailsArray);
      this.getIconAndColorsForLinkedAssets(this.individualAssetLinkedDetailsArray, this.assetConfig);
    } else {
      this.individualAssetLinkedDetailsArray = [];
    }
  }

  checkAssetsLinkedToServiceStatus(individualAssetLinkedDetailsArray) {
    let allServersStats: any = [];
    allServersStats = this.allServersStats;
    if (individualAssetLinkedDetailsArray.length > 0) {
      forEach(individualAssetLinkedDetailsArray[0].assetsLinked, function (value) {
        let serverStatusGroupById: any;
        serverStatusGroupById = groupBy(allServersStats, '_id');
        if (serverStatusGroupById[value._id]) {
          if ((serverStatusGroupById[value._id][0]._id) === value._id) {
            value.running = true;
          }
        }
      });
    }
    return individualAssetLinkedDetailsArray;
  }

  checkServicesLinkedToAssetStatus(servicesLinkedToAssetsArray) {
    let allServicesStatus: any = [];
    allServicesStatus = this.allServicesStatus;
    if (servicesLinkedToAssetsArray.length > 0) {
      forEach(servicesLinkedToAssetsArray[0].assetsLinked, function (value) {
        let serviceStatusGroupedbyId: any;
        serviceStatusGroupedbyId = groupBy(allServicesStatus, 'registrationName');
        if (serviceStatusGroupedbyId[value.name] && serviceStatusGroupedbyId[value.name].length > 0) {
          if (serviceStatusGroupedbyId[value.name][0]['metrics'] && serviceStatusGroupedbyId[value.name][0]['metrics']['status']
            && serviceStatusGroupedbyId[value.name][0]['metrics']['status'] === 'online') {
            // value.running = true;
          }
        }
      });
    }
    return servicesLinkedToAssetsArray;
  }

  getAssetsConfiguration() {
    this.assetConfigService.getAssetsConfig()
      .then(assetConfig => {
        this.assetConfig = assetConfig;
        // this.getAssetLabel();
      });
  }

  getServiceAssetConfiguration() {
    this.assetConfigService.getServiceAssetConfig()
      .then(serviceAssetConfig => {
        this.serviceAssetConfigDetails = serviceAssetConfig;
        // this.getServiceAssetLabel();
      });
  }

  getServiceAssetLabel() {
    this.serviceLabel = '';
    if (this.assetData && this.assetData.serviceType) {
      const assetDetails = find(this.serviceAssetConfigDetails, ['type', this.assetData.serviceType]);
      this.serviceLabel = assetDetails.label;
    }
  }

  getAssetLabel() {
    this.assetLabel = '';
    if (this.assetData && this.assetData.assetType) {
      const assetDetails = find(this.assetConfig, ['type', this.assetData.assetType]);
      this.assetLabel = assetDetails.label;
    }
  }

  getProfilPicDetails(assetData) {
    this.showCircularLoaderForImg = true;
    this.assetService.getThumbnailImage(assetData['_id'])
      .subscribe((res) => {
        const that = this;
        if (res) {
          if (res.type && res.type === 'application/json') {
            that.showCircularLoaderForImg = false;
            that.profileImageUpload.profilePhoto = '../../assets/img-person-placeholder.jpg';
          } else {
            const reader = new FileReader();
            reader.readAsDataURL(res);
            reader.onload = function (e: any) {
              that.showCircularLoaderForImg = false;
              that.profileImageUpload.profilePhoto = e.target.result;
            };
          }
        } else {
          that.showCircularLoaderForImg = false;
        }
      });
  }

  modify() {
    this.showCircularLoader = true;
    this.assetId = this.assetData._id;
    this.assetService.getAssetConfig(this.assetData.assetType)
      .subscribe((data: any) => {
        this.selectedAssetConfigDetails = data;
        if (data && data.assetType && data.assetType.config) {
          (data.assetType.config.liveVideoFeed) ? this.cameraLive.showCameraLive = true : this.cameraLive.showCameraLive = false;
          if (this.isThumbnailAccepted || this.isprofilePhotoAccepted) {
            this.initializeImageUploader();
            this.getUpdatedRegisteredFaceThumbnail();
            this.assetData?._id && this.oneTimeImageLoaderInit('thumbnailImage' + this.assetData._id, 'thumbnailImage');
          }
          // this.getProfileImgForModal(this.assetData['_id']);
          // this.getProfilPicDetails(this.assetData);
          // }
        }
        let selectedAssetConfig: any = {};
        selectedAssetConfig = data;
        if (selectedAssetConfig && selectedAssetConfig.assetType && selectedAssetConfig.assetType.config && selectedAssetConfig.assetType.config.isServiceLinkable) {
          this.isServiceLinkable = true;
          this.showStatusOnAssetCard = true;
        } else {
          this.isServiceLinkable = false;
          this.showStatusOnAssetCard = false;
        }
        if (selectedAssetConfig && selectedAssetConfig.assetType && selectedAssetConfig.assetType.config && selectedAssetConfig.assetType.config.showStats) {
          this.showStatus = true;
          // this.getServerAnalytics();
          // this.getAssetStatus();
        } else {
          this.showStatus = false;
        }
        //this.assetFromConfig = this.formService.formatEditAssetConfig(selectedAssetConfig.configuration, this.assetData);
        this.showCircularLoader = false;
        this.formConfiguration = selectedAssetConfig.configuration;
      });
  }

  initializeImageUploader() {
    this.showCircularLoaderForImg = true;
    if (this.assetData?._id && (this.isThumbnailAccepted || this.isprofilePhotoAccepted)) {
      this.showCircularLoaderForImg = true;
      this.showCircularLoaderForImg = false;
    }
    this.showCircularLoaderForImg = false;
  }

  getServiceAssetConfigByType() {
    this.showCircularLoader = true;
    this.assetId = this.assetData._id;
    this.serviceLinkableService.getServiceAssetConfig(this.assetData.serviceType)
      .subscribe((data: any) => {
        let selectedServiceConfig: any = {};
        selectedServiceConfig = data;
        if (selectedServiceConfig && selectedServiceConfig.serviceType && selectedServiceConfig.serviceType.config && selectedServiceConfig.serviceType.config.management) {
          this.isServiceManageable = true;
          if (selectedServiceConfig.serviceType.config.management.actions && selectedServiceConfig.serviceType.config.management.actions.length > 0) {
            this.serviceActionsArray = selectedServiceConfig.serviceType.config.management.actions;
            if (this.assetData && this.assetData.serverStatus) {
              this.isServerAvailable = true;
              this.serviceLinkableService.getServiceStatus(this.assetData._id)
                .subscribe((status: any) => {
                  if (status && status.status && status.status === 'online') {
                    remove(this.serviceActionsArray, function (n: any) {
                      return (n.value === 'start');
                    });
                  } else {
                    remove(this.serviceActionsArray, function (n: any) {
                      return (n.value === 'stop' || n.value === 'restart');
                    });
                  }
                }, () => {
                });
              // if (this.assetData.running) {
              //   remove(this.serviceActionsArray, function(n: any) {
              //     return (n.value === 'start');
              //   });
              // } else {
              //   remove(this.serviceActionsArray, function(n: any) {
              //     return (n.value === 'stop' || n.value === 'restart');
              //   });
              // }
            } else {
              this.isServerAvailable = false;
              this.serviceActionsArray = [];
            }
          }
        } else {
          this.isServiceManageable = false;
        }
        //this.assetFromConfig = this.formService.formatEditAssetConfig(selectedServiceConfig.configuration, this.assetData);
        this.showCircularLoader = false;
        this.formConfiguration = selectedServiceConfig.configuration;
      });
  }

  getAssetStatus() {
    this.statsDetails = null;
    this.showCircularLoader = true;
    const durationInMinutes: any = 5;
    const start = moment().subtract(durationInMinutes, 'minutes').utc();
    const end = moment().utc();
    this.healthCheckService.getAssetStatus(start, end, this.assetId)
      .subscribe(() => {
        this.showCircularLoader = false;
        /*let serverStatusDetails: any = [];
        serverStatusDetails = data;
        if (serverStatusDetails.length > 0) {
           serverStatusDetails[0].mem.free = this.formatBytes(serverStatusDetails[0].mem.free);
           serverStatusDetails[0].mem.total = this.formatBytes(serverStatusDetails[0].mem.total);
           this.statsDetails = serverStatusDetails[0];
        } else {
          this.statsDetails = null;
        }*/
      });
  }

  getServerAnalytics() {
    this.eChartGraph['disk'].show = false;
    this.eChartGraph['cpu'].show = false;
    this.eChartGraph['memory'].show = false;
    this.showCircularLoader = true;
    this.statsNotFound = false;
    const durationInMinutes: any = 1;
    const start = moment().subtract(durationInMinutes, 'hour').utc();
    const end = moment().utc();
    this.getServerAnalyticsForData(start, end, this.assetId, 'all');
    /*this.assetService.getServerAnalytics(start, end, this.assetId, 'all')
      .subscribe(data => {
        this.showCircularLoader = false;
        let historicalData: any = [];
        historicalData = data;
       // historicalData = orderBy(historicalData, 'dateInString', 'asc');
        if (historicalData && historicalData.length > 0) {
          this.statsNotFound = false;
          this.showStatsGraphTabs = true;
          let xAxis: any;
          let groupData: any;
          historicalData = sortBy(historicalData, [function(o) { return o.milliseconds; }]);
          groupData = groupBy(historicalData, 'dateInString');
          xAxis = keys(groupData);
          this.drawDiskStatsChart(xAxis, groupData);
          this.drawCpuStatsChart(xAxis, groupData);
          this.drawMemoryStatsChart(xAxis, groupData);
        } else {
          this.statsNotFound = true;
        }
      });*/
  }


  getGraphDataForSeriesType(graphDataWithSeriesTypeObj) {
    let startTime: any;
    let endTime: any;
    switch (graphDataWithSeriesTypeObj.duration) {
      case 'oneHour':
        startTime = moment().subtract(1, 'hour').utc();
        endTime = moment().utc();
        break;
      case 'oneDay':
        startTime = moment().subtract(1, 'day').utc();
        endTime = moment().utc();
        break;
      case 'sixHours':
        startTime = moment().subtract(6, 'hour').utc();
        endTime = moment().utc();
        break;
      case 'twelveHours':
        startTime = moment().subtract(12, 'hour').utc();
        endTime = moment().utc();
        break;
      default:
        break;
    }
    this.showCircularLoader = true;
    this.getServerAnalyticsForData(startTime, endTime, this.assetId, graphDataWithSeriesTypeObj.field);
    /*this.assetService.getServerAnalytics(startTime, endTime, this.assetId, graphDataWithSeriesTypeObj.field)
      .subscribe((data) => {
        this.showCircularLoader = false;
        let historicalData: any = [];
        historicalData = data;
        if (historicalData && historicalData.length > 0) {
          let xAxis: any;
          let groupData: any;
          historicalData = sortBy(historicalData, [function(o) { return o.milliseconds; }]);
          groupData = groupBy(historicalData, 'dateInString');
          xAxis = keys(groupData);
          if (graphDataWithSeriesTypeObj.field === 'disk') {
            this.drawDiskStatsChart(xAxis, groupData);
          } else if (graphDataWithSeriesTypeObj.field === 'cpu') {
            this.drawCpuStatsChart(xAxis, groupData);
          } else if (graphDataWithSeriesTypeObj.field === 'memory') {
            this.drawMemoryStatsChart(xAxis, groupData);
          }
        }
      });*/
  }

  getServerAnalyticsForData(startTime, endTime, assetId, field) {
    this.assetService.getServerAnalytics(startTime, endTime, assetId, field)
      .subscribe(data => {
        this.showCircularLoader = false;
        let historicalData: any = [];
        historicalData = data;
        if (historicalData && historicalData.length > 0) {
          this.statsNotFound = false;
          let xAxis: any;
          let groupData: any;
          historicalData = sortBy(historicalData, [function (o) {
            return o.milliseconds;
          }]);
          groupData = groupBy(historicalData, 'dateInString');
          xAxis = keys(groupData);
          switch (field) {
            case 'all':
              this.drawDiskStatsChart(xAxis, groupData);
              this.drawCpuStatsChart(xAxis, groupData);
              this.drawMemoryStatsChart(xAxis, groupData);
              break;
            case 'disk':
              this.drawDiskStatsChart(xAxis, groupData);
              break;
            case 'cpu':
              this.drawCpuStatsChart(xAxis, groupData);
              break;
            case 'memory':
              this.drawMemoryStatsChart(xAxis, groupData);
              break;
            default:
              break;
          }
        } else {
          this.statsNotFound = true;
        }
      });
  }

  /*setGraphData(graphTitle: string, graphType: string, labels: Array<string>, data: any,
               dataPropertyName: string, size: string, type: string, dataSetsLabel: string, dataSetsBackgroundColor: string) {
     this.graphData[type].graphTitle = graphTitle;
     this.graphData[type].graphType = graphType;
     this.graphData[type].labels = labels;
     this.graphData[type].data = data;
     this.graphData[type].dataPropertyName = dataPropertyName;
     this.graphData[type].size = size;
     this.graphData[type].dataSetsLabel = dataSetsLabel;
     this.graphData[type].dataSetsBackgroundColor = dataSetsBackgroundColor;
     this.graphData[type].hardwarePropertyType = type;
  }*/

  /*diskStatsGraphPlotting(label, data, totalDiskSize) {
    let canvas : any = document.getElementById('diskStatsChart');
    let ctx = canvas.getContext('2d');
    this.serverHistoricalData.diskChartData = new Chart(ctx,
      {
        type: 'line',
        data: {
          labels: label,
          datasets: [{
            label: 'Disk used in GB',
            data: data['usedDisk'],
            fill: false,
            borderColor: 'rgb(41,182,246,1.9)',
            borderWidth: 1.5,
          }],
        },
        options: {
          title: {
            display: true,
            position: 'left',
            fontSize: 14,
            text: 'Disk Size ' + totalDiskSize + ' GB'
          },
          tooltips: {
            titleFontSize: 15,
            titleMarginBottom: 10,
            titleFontColor: '#212529',
            bodyFontSize: 15,
            bodySpacing: 10,
            bodyFontColor: '#212529',
            footerFontSize: 15,
            footerMarginTop: 10,
            footerFontColor: '#212529',
            backgroundColor: 'rgba(255,255,255,0.9)',
            borderWidth: 1,
            borderColor: '#7F7F7F',
            mode: 'label',
          },
          responsive: true,
          legend: {
            position: 'right' // place legend on the right side of chart
          },
          scales: {
            /!*steppedLine: true,
             xAxes: [{
             stacked: false, // this should be set to make the bars stacked
             }],*!/
            yAxes: [{
              stacked: false // this also..
            }]
          }
        },
      });
  }

  cpuStatsGraphPlotting(label, data) {
    let canvas : any = document.getElementById('cpuStatsChart');
    let ctx = canvas.getContext('2d');
    this.serverHistoricalData.chartData = new Chart(ctx,
      {
        type: 'line',
        data: {
          labels: label,
          datasets: [{
            label: 'CPU Currentload',
            data: data['cpuCurrentload'],
            fill: false,
            borderColor: 'rgba(56, 142, 60,1.9)',
            borderWidth: 1.5,
          }/!*, {
            label: 'CPU FullLoad',
            data: data['cpuFullLoad'],
            fill: false,
            borderColor: 'rgba(255, 160, 0,1.9)',
            borderWidth: 1.5,
          }*!/],
        },
        options: {
          tooltips: {
            titleFontSize: 15,
            titleMarginBottom: 10,
            titleFontColor: '#212529',
            bodyFontSize: 15,
            bodySpacing: 10,
            bodyFontColor: '#212529',
            footerFontSize: 15,
            footerMarginTop: 10,
            footerFontColor: '#212529',
            backgroundColor: 'rgba(255,255,255,0.9)',
            borderWidth: 1,
            borderColor: '#7F7F7F',
            mode: 'label',
          },
          responsive: true,
          legend: {
            position: 'right' // place legend on the right side of chart
          },
          scales: {
            /!*steppedLine: true,
            xAxes: [{
              stacked: false, // this should be set to make the bars stacked
            }],*!/
            yAxes: [{
              stacked: false // this also..
            }]
          }
        },
      });
  }

  memoryStatsGraphPlotting(label, data, totalMemory) {
    let canvas : any = document.getElementById('memoryStatsChart');
    let ctx = canvas.getContext('2d');
    this.serverHistoricalData.memoryChartData = new Chart(ctx,
      {
        type: 'line',
        data: {
          labels: label,
          datasets: [/!*{
            label: 'Total memory',
            data: data['totalMemory'],
            fill: false,
            borderColor: 'rgba(56, 142, 60,1.9)',
            borderWidth: 1.5,
          },*!/ {
            label: 'Free Memory in GB',
            data: data['freeMemory'],
            fill: false,
            borderColor: 'rgba(255, 160, 0,1.9)',
            borderWidth: 1.5,
          }],
        },
        options: {
          title: {
            display: true,
            position: 'left',
            fontSize: 14,
            text: 'Total memory ' + totalMemory + ' GB'
          },
          tooltips: {
            titleFontSize: 15,
            titleMarginBottom: 10,
            titleFontColor: '#212529',
            bodyFontSize: 15,
            bodySpacing: 10,
            bodyFontColor: '#212529',
            footerFontSize: 15,
            footerMarginTop: 10,
            footerFontColor: '#212529',
            backgroundColor: 'rgba(255,255,255,0.9)',
            borderWidth: 1,
            borderColor: '#7F7F7F',
            mode: 'label',
          },
          responsive: true,
          legend: {
            position: 'right' // place legend on the right side of chart
          },
          scales: {
            /!*steppedLine: true,
             xAxes: [{
             stacked: false, // this should be set to make the bars stacked
             }],*!/
            yAxes: [{
              stacked: false // this also..
            }]
          }
        },
      });
  }*/

  /*formatBytes(bytes) {
    return parseInt((bytes / (1024 * 1024)).toFixed(1));
  }*/
  updateAssetCart(dataUpdated) {
    this.assetUpdatedData = dataUpdated;
    this.syncUpdateAssetCartEvent.emit(dataUpdated);
  }

  /*setDataFromConfig(data) {
    this.assetService.saveAssetDetails(this.formService.formatAssetSaveDetails(data))
      .subscribe(res => {
        console.log('ressss', res);
      });
  }*/

  getLinkedAssetDetails(assetId) {
    this.assetService.getLinkedAssets(assetId)
      .subscribe(linkedAssetDetails => {
        this.individualAssetLinkedDetailsArray = this.modifyAssetLinkedData(linkedAssetDetails);
        this.individualAssetLinkedDetailsArray = this.linkedAssetsButtonTitleFormation(this.individualAssetLinkedDetailsArray);
        this.getIconAndColorsForLinkedAssets(this.individualAssetLinkedDetailsArray, this.assetConfigDetails);
      });
  }

  linkedAssetsButtonTitleFormation(individualAssetLinkedDetailsArray) {
    let assetsConfigList;
    this.assetConfigService.getAssetsConfig()
      .then(configDetails => {
        assetsConfigList = configDetails;
        forEach(individualAssetLinkedDetailsArray, function (asset) {
          const selectedAssetConfig = find(assetsConfigList, function (assetConfig) {
            return assetConfig.type === asset.assetType;
          });
          if (selectedAssetConfig && selectedAssetConfig.label) {
            asset['label'] = selectedAssetConfig.label;
          }
        });
      });
    return individualAssetLinkedDetailsArray;
  }

  linkedServiceButtonTitleFormation(individualAssetLinkedDetailsArray) {
    let serviceConfigList;
    this.assetConfigService.getServiceAssetConfig()
      .then(configDetails => {
        serviceConfigList = configDetails;
        forEach(individualAssetLinkedDetailsArray, function (service) {
          const selectedAssetConfig = find(serviceConfigList, function (serviceConfig) {
            return serviceConfig.type === service.assetType;
          });
          if (selectedAssetConfig && selectedAssetConfig.label) {
            service['label'] = selectedAssetConfig.label;
          }
        });
      });
    return individualAssetLinkedDetailsArray;
  }

  modifyAssetLinkedData(linkedAssetDetails) {
    let linkedAssetArray: any = [];
    linkedAssetArray = linkedAssetDetails;
    if (linkedAssetArray && linkedAssetArray.length > 0) {
      let assetLinkedData: any;
      assetLinkedData = groupBy(linkedAssetDetails, function (asset) {
        if (asset.assetType) {
          return asset.assetType;
        } else if (asset.serviceType) {
          return asset.serviceType;
        }
      });

      const individualAssetLinkedCountArray: any = [];
      let obj;
      for (const key in assetLinkedData) {
        const value = assetLinkedData[key];
        obj = {
          'assetType': key,
          'count': value.length,
          'assetsLinked': value
        };
        individualAssetLinkedCountArray.push(obj);
      }
      return individualAssetLinkedCountArray;
    } else {
      return [];
    }
  }

  /*modifyServiceLinkedData(linkedSericeDetails) {
    let linkedServiceArray: any = [];
    linkedServiceArray = linkedSericeDetails;
    if ( linkedServiceArray &&  linkedServiceArray.length > 0) {
      let serviceLinkedData: any;
      serviceLinkedData = groupBy(linkedSericeDetails, function (asset) {
          return asset.serviceType;
      });

      let individualServiceLinkedCountArray: any = [];
      let obj;
      for(let key in serviceLinkedData) {
        let value = serviceLinkedData[key];
        obj = {
          'serviceType': key,
          'count': value.length,
          'serviceLinked': value
        };
        individualServiceLinkedCountArray.push(obj);
      }
      return individualServiceLinkedCountArray;
    } else {
      return [];
    }
  }*/

  assetDetailsToDelink(delinkAssetDetails) {
    // this.delinkAssetDetails = delinkAssetDetails;
    this.delinkAssetDetailsToMainComponent.emit(delinkAssetDetails);
  }

  serviceDetailsToDelink(delinkServiceDetails) {
    this.delinkServiceDetailsToMainComponent.emit(delinkServiceDetails);
  }

  /*selectedAssetTypeToLink(assetType) {
    this.assetTypeForSearch = assetType;
    $('#assetListingPopup').show();
  }*/


  assetDetailsToLink(linkingAssetDetails) {
    this.linkAssetDetailsToMainComponent.emit(linkingAssetDetails);
  }

  serviceDetailsToLinkAsset(linkingServiceDetails) {
    this.linkServiceDetailsToMainComponent.emit(linkingServiceDetails);
  }

  addGroupDetails(selectedGroupIdsToAdd) {
    this.addGroupDetailsToMainComponent.emit(selectedGroupIdsToAdd);
  }

  resetSearchedAssets() {
    this.resetSearchedAssetOnPlusClick = Math.random();
  }

  resetSearchedServices() {
    this.resetSearchedServicesOnPlusClick = Math.random();
  }

  deregisterAsset(assetId) {
    this.deregisterAssetToMainComponent.emit(assetId);
  }

  getIconAndColorsForLinkedAssets(assetData, config) {
    this.linkedAssetIconService.getIconAndColorsForLinkedAssets(assetData, config);
  }

  closeAssetPopup() {
    $('#assetListingPopup').hide(300);
    $('#groupListingPopup').hide(300);
    $('html').css('overflow-y', 'auto');
    // this.assetFromConfig = [];
    this.formConfiguration = null;
    this.individualAssetLinkedDetailsArray = [];
    this.serviceActionsArray = [];
    this.resetFormData = Math.random();
    this.assetLabel = '';
    this.serviceLabel = '';
    this.isEditOptionClicked = false;
    this.showStatusOnAssetCard = false;
    this.rawGpsData = null;
    this.resetCarMeterGaugeRandomNumber = Math.random();
    this.openTabContent('', 'assetInfo');
    this.resetProfileImageUploadValues();
    if (!this.isSidebarClosed) {
      this.toggleActiveClassEvent.emit();
    }
    this.isSidebarClosed = true;
    this.isEditOptionClicked = false;
    this.selectedLinkedAsset = null;
    this.resetCameraVariable();
    this.selectedEventObjectArray = [];
    this.selectedEventList = [];
    this.selectedStatusList = [];
    // this.disconnectFromNameSpace(this.trackerNameSpace);
    this.store.dispatch(SocketActions.disconnect({
      nsp: this.trackerNameSpace,
      topic: CONSTANT.SOCKET.TRACKER.TOPICS.OBD_DEVICE_GPS_PUSH,
      key: this.sidebarId
    }));

    if (this.carLive) {
      this.carLive['selectedContentType'] = this.carLive['contentTypesToShow'][0];
      this.carLive['showCarLive'] = false;
    }
    this._box.isboxStatusRequiredToShow = false;
    this.splitScreenService.hideModalAndRemoveSplitScreen();
    this.store.dispatch(setSelectedIdToRowHighlight({selectedIdToHighlightRow : ''}))
  }

  resetProfileImageUploadValues() {
    this.profileImageUpload.selectedFile = null;
    this.profileImageUpload.isBtnDisabled = false;
    this.profileImageUpload.imageSelected = false;
    this.profileImageUpload.profilePhoto = null;
    this.profileImageUpload.url = null;
  }

  editOptionClicked() {
    this.isEditOptionClicked = !this.isEditOptionClicked
    this.profileImageUpload.isBtnDisabled = this.isEditOptionClicked;
  }

  manageServices(actionDetails) {
    /*let serviceId: any = '';
    let action: string = '';
    action = actionDetails.value;
    serviceId = this.assetId;
    let obj = {};
    obj = {
      'id': serviceId,
      'action': action
    };*/
    this.manageServicesObjectToMainComponent.emit(actionDetails);
    /*this.serviceLinkableService.manageServices(obj)
      .subscribe(res => {});*/
  }

  followMeBtnClicked() {
    this.followMeBtnAction.emit(this.assetData);
  }

  buildEchartCarStatusDataForSpecificPeriodAndSeries(startTime, endTime, vinNumber, series, dateDisplayFormate?) {
    this.trackingService.getCarAnalyticssHistoricDataById(startTime, endTime, vinNumber)
      .subscribe((data: any) => {
        this.showCircularLoader = false;
        if (data && data.length > 0) {
          this.carStatsNotFound = false;
          this.showCarStatusGraphs = true;
          this.showCarLiveDataGraphs = true;
          let carGpsData: any;
          carGpsData = data;
          const carLiveData = {
            speed: {
              data: [],
              label: [],
              colour: 'red',
              name: this.lang.getTranslation('app.core.custom-modal.car-live-data.speed-in-km')
            },
            rpm: {
              data: [],
              label: [],
              colour: 'green',
              name: this.lang.getTranslation('app.core.custom-modal.car-live-data.rpm')
            },
            aTemp: {
              data: [],
              label: [],
              colour: 'yellow',
              name: this.lang.getTranslation('app.core.custom-modal.car-live-data.air-temp')
            },
            cTemp: {
              data: [],
              label: [],
              colour: 'blue',
              name: this.lang.getTranslation('app.core.custom-modal.car-live-data.coolant-temp')
            },
            fuelLevel: {
              data: [],
              label: [],
              colour: 'blue',
              name: this.lang.getTranslation('app.core.custom-modal.car-live-data.fuel-in-lts')
            },
            temperature: {
              data: [],
              label: [],
              colour: 'blue',
              name: 'Temp in °'
            },
            humidity: {
              data: [],
              label: [],
              colour: 'blue',
              name: 'Humidity in %'
            }
          };
          for (let i = 0; i <= carGpsData.length - 1; i++) {
            const value = carGpsData[i];
            if (value.speed && value.updated) {
              carLiveData.speed.data.push(Math.round(value.speed));
              carLiveData.speed.label.push(this.dateFormator(value.updated, dateDisplayFormate));
            }
            if (value.engineRpm && value.updated) {
              carLiveData.rpm.data.push(Math.round(value.engineRpm));
              carLiveData.rpm.label.push(this.dateFormator(value.updated, dateDisplayFormate));
            }
            if (value.ambientAirTemperature && value.updated) {
              carLiveData.aTemp.data.push(Math.round(value.ambientAirTemperature));
              carLiveData.aTemp.label.push(this.dateFormator(value.updated, dateDisplayFormate));
            }
            if (value.coolantTemperature && value.updated) {
              carLiveData.cTemp.data.push(Math.round(value.coolantTemperature));
              carLiveData.cTemp.label.push(this.dateFormator(value.updated, dateDisplayFormate));
            }
            if (value.fuelLevel && value.updated) {
              carLiveData.fuelLevel.data.push(Math.round(value.fuelLevel));
              carLiveData.fuelLevel.label.push(this.dateFormator(value.updated, dateDisplayFormate));
            }
            if (i === carGpsData.length - 1) {
              /*this.carStats.speed=*/
              const allStats = keys(this.carStats);
              const localThis = this;
              forEach(allStats, function (statsType) {
                localThis.initCarStatsECharts(statsType, carLiveData[statsType], 'carStats');
              });
            }
          }

          this.trackingService.getCarSensorData(startTime, endTime, vinNumber, series).subscribe((data: any[]) => {
            this.showCircularLoader = false;
            if (data && data.length > 0) {
              let carGpsData: any;
              carGpsData = data;
              const carLiveData = {
                temperature: {
                  data: [],
                  label: [],
                  colour: 'red',
                  name: this.lang.getTranslation('app.core.custom-modal.car-live-data.temp-in-degree')
                },
                humidity: {
                  data: [],
                  label: [],
                  colour: 'red',
                  name: this.lang.getTranslation('app.core.custom-modal.car-live-data.humidity-in-percentage')
                },
              }

              for (let i = 0; i <= carGpsData.length - 1; i++) {
                const value = carGpsData[i];
                if (value?.temperature && value.updated) {
                  carLiveData.temperature.data.push(Math.round(value?.temperature));
                  carLiveData.temperature.label.push(this.dateFormator(value.updated, dateDisplayFormate));
                }
                if (value?.humidity && value.updated) {
                  carLiveData.humidity.data.push(Math.round(value?.humidity));
                  carLiveData.humidity.label.push(this.dateFormator(value.updated, dateDisplayFormate));
                }

                if (i === carGpsData.length - 1) {

                  const allStats = keys(this.boxStatusGraph)
                  const localThis = this;
                  forEach(allStats, function (statsType) {
                    localThis.initCarStatsECharts(statsType, carLiveData[statsType], 'boxStatusGraph');
                  });
                }
              }


            }
          })
        } else {
          this.showCarLiveDataGraphs = false;
          this.showCarStatusGraphs = false;
          this.carStatsNotFound = true;
        }
      });


  }

  dateFormator(date, format?) {
    let dateFormate = moment(date).format('MMMM Do YYYY, h:mm a');
    if (format) {
      if (format === 'date') {
        dateFormate = moment(date).format('MMMM Do YYYY, h:mm a');
      } else if (format === 'time') {
        dateFormate = moment(date).format('h:mm:ss a');
      }
    }
    return dateFormate;
  }

  initCarStatsECharts(statsType, historicalData, chartGroup) {
    const chartSeries: EChartSeries = {
      name: historicalData.name,
      type: 'line',
      data: historicalData.data,
      stack: true,
      smooth: false,
      label: {normal: {show: false, position: 'top'}},
      areaStyle: {opacity: 0.005},
      itemStyle: {
        colour: historicalData.colour
      }
    };
    this[chartGroup][statsType].chartData = {
      title: this.camelCaseFormatter(statsType),
      type: 'line',
      legend: [historicalData.name],
      series: [chartSeries],
      xAxis: historicalData.label,
    };
    this[chartGroup][statsType].showLoader = false;
    this[chartGroup][statsType].show = true;
  }



  getCarLiveData() {
    this.showCircularLoader = true;
    this.getActivePillValue(this.carLive['contentTypesToShow'][0]);
    this.showCarLiveDataGraphs = false;
    this.setCarGaugeValues();
    let startTime: any;
    let endTime: any;
    startTime = moment().subtract(0.33, 'hour').utc(); // last 20 mins data
    endTime = moment().utc();
    this.buildEchartCarStatusDataForSpecificPeriodAndSeries(startTime, endTime, this.assetData._id, 'minute', 'time');
    this.store.dispatch(SocketActions.updateSocketDataRequired({
      nameSpace: this.trackerNameSpace,
      topicName: CONSTANT.SOCKET.TRACKER.TOPICS.OBD_DEVICE_GPS_PUSH,
      key: this.sidebarId,
      callback: this.getCarLiveSocketData,
    }));

  }

  getCarLiveSocketData = (gpsData) => {
    if ((gpsData.assetDetails && gpsData.assetDetails.data && gpsData.assetDetails.data._id) ===
    (this.assetData && this.assetData._id)) {
    this.showCircularLoader = false;
    this.carStatsNotFound = false;
    this.rawGpsData = gpsData;
    if (gpsData.coolantTemperature != null) {
      this.coolantTemperature = Math.round(gpsData.coolantTemperature);
    }
    if (gpsData.generatedTimeStamp) {
      this.setLiveUpdatedTime(gpsData.generatedTimeStamp);
    }
    this.updateCarStatsECharts('speed',
      {value: Math.round(gpsData.speed), index: 0, label: moment().format('h:mm:ss a')});
    this.updateCarStatsECharts('rpm',
      {value: Math.round(gpsData.engineRpm), index: 0, label: moment().format('h:mm:ss a')});
    this.updateCarStatsECharts('aTemp',
      {value: Math.round(gpsData.ambientAirTemperature), index: 0, label: moment().format('h:mm:ss a')});
    this.updateCarStatsECharts('cTemp',
      {value: Math.round(gpsData.coolantTemperature), index: 0, label: moment().format('h:mm:ss a')});
    this.updateCarStatsECharts('fuelLevel',
      {value: Math.round(gpsData.fuelLevel), index: 0, label: moment().format('h:mm:ss a')});
    this.setCarSpeedGaugeValues(gpsData);
    this.setCarRpmGaugeValues(gpsData);
    this.setCarFuelGaugeValues(gpsData);
    this.setObdOdoMeterReading(gpsData);
    this.setColdChainBoxTempGaugeValues(gpsData);
    this.checkBoxStatusAndSet(gpsData);
  }
  }

  setObdOdoMeterReading(gpsData) {
    if (gpsData.assetDetails && gpsData.assetDetails.data && gpsData.assetDetails.data.obdOdoMeter && gpsData.assetDetails.data.obdOdoMeter != null) {
      this.obdOdoMeterReading = Math.round(parseInt(gpsData.assetDetails.data.obdOdoMeter));
    } else {
      this.obdOdoMeterReading = 0;
    }
  }

  public analyticsData: any = {
    // startTime : moment().subtract(24, 'hour').utc(),
    // endTime : moment().utc()
    startDate: moment().subtract(1, 'day'),
    endDate: moment()
  };

  getCarEventData() {
    this.isCarSelectedToShowEvents = true;
    this.showCarEventComponent = true;
    // this.showCircularLoader = true;
    // this.carEventDataNotAvailable = false;
    // this.carEventsArray = [];
    // let startTime: Date;
    // let endTime: Date;
    // startTime = moment().subtract(24, 'hour').utc();
    // endTime = moment().utc();
    // this.trackingService.getCarTrackerEventData(startTime, endTime, this.assetData.vinNumber, 'minute')
    //   .subscribe((data: Array<any>) => {
    //     this.showCircularLoader = false;
    //     if (data.length > 0) {
    //       this.carEventDataNotAvailable = false;
    //       this.carEventsArray = reverse(data);
    //     } else {
    //       this.carEventDataNotAvailable = true;
    //     }
    //   });
    this.getAllCarEventsAndStatus();

  }

  getObdOdoMeterReading() {
    if (this.assetData && this.assetData.obdOdoMeter && this.assetData.obdOdoMeter != null) {
      this.obdOdoMeterReading = Math.round(parseInt(this.assetData.obdOdoMeter));
    } else {
      this.obdOdoMeterReading = 0;
    }
    if (this.assetData && this.assetData.updated) {
      this.liveUpdatedTime = this.assetData.updated;
    }
    // if (this.assetData && this.assetData.obdOdoMeter) {
    //   let totalDistanceDriven = 0;
    //   if (this.assetData.referenceOdoMeter != null) {
    //     this.assetData.referenceOdoMeter = parseInt(this.assetData.referenceOdoMeter);
    //     totalDistanceDriven = this.assetData.obdOdoMeter + this.assetData.referenceOdoMeter;
    //     this.obdOdoMeterReading = Math.round(totalDistanceDriven) + '';
    //   } else  {
    //     this.obdOdoMeterReading = Math.round(totalDistanceDriven) + '';
    //   }
    // } else {
    //   this.obdOdoMeterReading = '-';
    // }
    // if (this.assetData && this.assetData.updated) {
    //   this.liveUpdatedTime = this.assetData.updated;
    // }
  }

  setCarGaugeValues() {
    this.carStatsNotFound = true;
    let startTime: any;
    let endTime: any;
    startTime = moment().subtract(1, 'minute').utc(); // last 1 min data
    endTime = moment().utc();
    this.trackingService.getCarAnalyticssHistoricDataById(startTime, endTime, this.assetData._id)
      .subscribe((data: any) => {
        if (data && data.length > 0) {
          if (data[data.length - 1].speed != null && data[data.length - 1].engineRpm != null && data[data.length - 1].coolantTemperature != null) {
            this.rawGpsData = data[data.length - 1];
            this.setCarSpeedGaugeValues(data[data.length - 1]);
            this.setCarRpmGaugeValues(data[data.length - 1]);
            this.setCarFuelGaugeValues(data[data.length - 1]);
            this.coolantTemperature = Math.round(data[data.length - 1].coolantTemperature);
            if (this.rawGpsData.updated) {
              this.setLiveUpdatedTime(this.rawGpsData.updated);
            }
            this.carStatsNotFound = false;
          } else {
            this.carStatsNotFound = true;
          }
        } else {
          this.carStatsNotFound = true;
        }
      });
  }

  setLiveUpdatedTime(timeStamp) {
    this.liveUpdatedTime = timeStamp;
  }

  getCarStatus() {
    this.carStatsNotFound = true;
    this.showCarStatusGraphs = false;
    this.showCircularLoader = true;
    let startTime: moment.Moment;
    let endTime: any;
    startTime = moment().subtract(2, 'hour').utc(); // last 24 hrs data
    endTime = moment().utc();
    this.buildEchartCarStatusDataForSpecificPeriodAndSeries(startTime, endTime, this.assetData._id, 'minute', 'date');
    this.trackingService.getCarDistanceMovedData(startTime, endTime, this.assetData?.['_id'], 'hour')
      .subscribe((data: Array<any>) => {
        if (data && data.length > 0) {
          let carDistanceMovedArray: Array<any> = [];
          carDistanceMovedArray = sortBy(data, [function (o) {
            return o.milliseconds;
          }]);
          this.carStatsNotFound = false;

          const carDistanceMovedData = {
            distanceMoved: {
              data: [],
              label: [],
              colour: 'red',
              name: this.lang.getTranslation('app.core.custom-modal.car-live-data.dist-travel-km')
            }
          };
          for (let i = 0; i <= carDistanceMovedArray.length - 1; i++) {
            const value = carDistanceMovedArray[i];
            if (value.updated) {
              carDistanceMovedData.distanceMoved.data.push(Math.round(value.distanceTravelled));
              carDistanceMovedData.distanceMoved.label.push(this.dateFormator(value.updated));
            }
            if (i === carDistanceMovedArray.length - 1) {
              const allStats = keys(this.carDistanceMovedStats);
              const localThis = this;
              forEach(allStats, function (statsType) {
                localThis.initCarMovedECharts(statsType, carDistanceMovedData[statsType]);
              });
            }
          }
        } else {
          this.carStatsNotFound = true;
        }
      });
  }

  initCarMovedECharts(statsType, historicalData) {
    const chartSeries: EChartSeries = {
      name: historicalData.name,
      type: 'line',
      data: historicalData.data,
      stack: true,
      smooth: false,
      label: {normal: {show: false, position: 'top'}},
      areaStyle: {opacity: 0.005},
      itemStyle: {
        colour: historicalData.colour
      }
    };
    this.carDistanceMovedStats[statsType].chartData = {
      title: this.camelCaseFormatter(statsType),
      type: 'line',
      legend: [historicalData.name],
      series: [chartSeries],
      xAxis: historicalData.label,
    };
    this.carDistanceMovedStats[statsType].showLoader = false;
    this.carDistanceMovedStats[statsType].show = true;
  }

  updateCarStatsECharts(statsType, value) {
    const updateValue: EChartSeriesUpdate = {
      index: 0,
      label: value.label,
      value: value.value
    };
    this.carStats[statsType].updateValue = updateValue;
  }

  checkBoxStatusAndSet(gpsData) {
    if (gpsData.sensorData && gpsData.sensorData.coldChainBox) {
      this._box.isboxStatusRequiredToShow = true;
      if (gpsData.sensorData.coldChainBox === 'close') {
        this._box.isBoxClosed = true;
        this._box.isBoxOpened = false;
        this._box.boxOpenStatusText = 'Closed';
      } else if (gpsData.sensorData.coldChainBox === 'open') {
        this._box.isBoxOpened = true;
        this._box.isBoxClosed = false;
        this._box.boxOpenStatusText = 'Opened';
      }
    }
  }

  setColdChainBoxTempGaugeValues(gpsData) {
    if (gpsData.sensorData) {
      this._box.isboxStatusRequiredToShow = true;
      if (gpsData.sensorData.temperature || gpsData.sensorData.temperature <= 0) {
        this.boxTemperature = parseFloat(gpsData.sensorData.temperature.toFixed(2));
      }
    }
    this.coldChainBoxTempGaugeOptions.name = 'Box Temperature';
    this.coldChainBoxTempGaugeOptions.min = 0;
    this.coldChainBoxTempGaugeOptions.max = 30;
    this.coldChainBoxTempGaugeOptions.radius = '70%';
    this.coldChainBoxTempGaugeOptions.data = [{
      value: ((gpsData.sensorData && (gpsData.sensorData.temperature || gpsData.sensorData.temperature <= 0)) ? ((gpsData.sensorData.temperature).toFixed(0)) : 'N/A'),
      name: '°C'
    }];
  }

  setCarSpeedGaugeValues(gpsData) {
    this.carSpeedGaugeOptions.name = 'Speed';
    this.carSpeedGaugeOptions.min = 0;
    this.carSpeedGaugeOptions.max = 200;
    this.carSpeedGaugeOptions.radius = '70%';
    this.carSpeedGaugeOptions.data = [{
      value: (gpsData.speed ? ((gpsData.speed).toFixed(0)) : '0'),
      name: 'km/h'
    }];
  }

  setCarRpmGaugeValues(gpsData) {
    this.carRpmGaugeOptions.name = 'Rpm';
    this.carRpmGaugeOptions.min = 0;
    this.carRpmGaugeOptions.max = 7;
    this.carRpmGaugeOptions.radius = '70%';
    this.carRpmGaugeOptions.data = [{
      value: (gpsData.engineRpm ? ((gpsData.engineRpm / 1000).toFixed(2)) : '0'),
      name: 'x1000 r/min'
    }];
  }

  setCarFuelGaugeValues(gpsData) {
    const fuelLevelObj = {
      value: '0',
      name: 'lts'
    };
    this.carFuelGaugeOptions.name = 'Fuel';
    this.carFuelGaugeOptions.radius = '70%';
    fuelLevelObj.value = (gpsData.fuelLevel ? ((gpsData.fuelLevel).toFixed(2)) : '0');
    if (gpsData.fuelUnit) {
      this.carFuelGaugeOptions.min = 0;
      this.carFuelGaugeOptions.max = 100;
      fuelLevelObj.name = 'in %';
    } else {
      this.carFuelGaugeOptions.min = 0;
      this.carFuelGaugeOptions.max = 60;
    }
    this.carFuelGaugeOptions.data = [fuelLevelObj];
  }

  resetSelectedEventAndStatus() {
    this.selectedEventList = [];
    this.selectedEventObjectArray = [];
    this.selectedStatusList = [];
  }

  openTabContent(evt, tabName) {
    const localThis = this;
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName('tabcontent');
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = 'none';
    }
    tablinks = document.getElementsByClassName('tablinks');
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(' active', '');
    }
    if (evt) {
      this._groupVariables.groupsIdsToRemove = [];
      this.resetSelectedEventAndStatus();
      this.destroyFeedsFromSocket();
      this.getAddedGroupsForAsset();
      // this.disconnectFromNameSpace(this.trackerNameSpace);
      this.store.dispatch(SocketActions.disconnect({
        nsp: this.trackerNameSpace,
        topic: CONSTANT.SOCKET.TRACKER.TOPICS.OBD_DEVICE_GPS_PUSH,
        key: this.sidebarId
      }));
      if (document.getElementById(tabName))
        document.getElementById(tabName).style.display = 'block';
      evt.currentTarget.className += ' active';
    }
    if (!evt && tabName === 'assetInfo') {
      if (document.getElementById(tabName))
        document.getElementById(tabName).style.display = 'block';
      $('.defaultTab').addClass('active');
    }
    if (!evt && tabName === 'link') {
      if (document.getElementById(tabName))
        document.getElementById(tabName).style.display = 'block';
      $('.linkTab').addClass('active');
    }
    if (!evt && tabName === 'groups') {
      if (document.getElementById(tabName))
        document.getElementById(tabName).style.display = 'block';
      $('.groupsTab').addClass('active');
    }
    if (!evt && tabName === 'service') {
      if (document.getElementById(tabName))
        document.getElementById(tabName).style.display = 'block';
      $('.serviceTab').addClass('active');
    }
    if (!evt && tabName === 'manageService') {
      if (document.getElementById(tabName))
        document.getElementById(tabName).style.display = 'block';
      $('.serviceManageTab').addClass('active');
    }
    if (!evt && tabName === 'carStatus') {
      if (localThis.showCarStatus) {
        if (document.getElementById(tabName))
          document.getElementById(tabName).style.display = 'block';
        $('.carStatusTab').addClass('active');
        localThis.getCarStatus();
      } else {
        localThis.openTabContent('', 'assetInfo');
      }
    }
    if (!evt && tabName === 'carLiveData') {
      $(document).ready(function () {
        if (localThis.carLive && localThis.carLive['showCarLive']) {
          document.getElementById(tabName).style.display = 'block';
          $('.carLiveDataTab').addClass('active');
          localThis.getCarLiveData();
        } else {
          localThis.openTabContent('', 'assetInfo');
        }
      });
    }
  }

  drawDiskStatsChart(xAxis, groupData) {
    const yAxis = {
      usedDisk: [],
      total: []
    };
    let totalDiskSize = 'NA';
    map(xAxis, function (xValue) {
      if (groupData && groupData[xValue] && groupData[xValue][0] && groupData[xValue][0]['fsSize'] && groupData[xValue][0]['fsSize'].length > 0) {
        const groupBySize = groupBy(groupData[xValue][0]['fsSize'], 'size');
        const fileToEvaluated = groupBySize[max(map(keys(groupBySize), function (value) {
          return parseFloat(value);
        })).toString()];
        if (fileToEvaluated && fileToEvaluated[0] && fileToEvaluated[0]['size']) {
          const totalSize = (fileToEvaluated[0]['size'] / (1024 * 1024 * 1024)).toFixed(2);
          totalDiskSize = 'Disk Size ' + totalSize + ' GB';
          if(totalDiskSize){

          }
          const used = fileToEvaluated[0]['used'];
          yAxis['usedDisk'].push((used / (1024 * 1024 * 1024)).toFixed(2));
          yAxis['total'].push(totalSize);
        } else {
          yAxis['usedDisk'].push(null);
          yAxis['total'].push(null);
        }
      } else {
        yAxis['usedDisk'].push(null);
        yAxis['total'].push(null);
      }
    });
    // this.setGraphData('Disk Stats', 'line', xAxis, yAxis['usedDisk'], 'usedDisk', totalDiskSize, 'disk', 'Disk used in GB', 'rgba(41,182,246,1.9)');
    // this.graphDiskRandomNumber = Math.random();
    this.drawEChars(xAxis, yAxis, 'disk');
  }

  drawCpuStatsChart(xAxis, groupData) {
    const yAxis = {
      currentLoad: [],
      fullLoad: []
    };
    forEach(xAxis, function (xValue) {
      if (groupData && groupData[xValue] && groupData[xValue][0] && groupData[xValue][0]['cpu']
        && groupData[xValue][0]['cpu']['currentload'] || groupData[xValue][0]['cpu']['currentload'] === 0) {
        const currentload = groupData[xValue][0]['cpu']['currentload'].toFixed(2);
        const fullLoad = groupData[xValue][0]['cpu']['fullLoad'].toFixed(2);
        yAxis['currentLoad'].push(currentload);
        yAxis['fullLoad'].push(fullLoad);
      } else if (groupData && groupData[xValue] && groupData[xValue][0] && groupData[xValue][0]['cpu'] &&
        groupData[xValue][0]['cpu']['currentload'] || groupData[xValue][0]['cpu']['currentload'] === 0) {
        const currentload = groupData[xValue][0]['cpu']['currentload'].toFixed(2);
        const fullLoad = groupData[xValue][0]['cpu']['fullLoad'].toFixed(2);
        yAxis['currentLoad'].push(currentload);
        yAxis['fullLoad'].push(fullLoad);
      } else {
        yAxis['currentLoad'].push(null);
        yAxis['fullLoad'].push(null);
      }
    });
    // this.setGraphData('CPU Stats', 'line', xAxis, yAxis['currentLoad'], 'currentLoad', '', 'cpu', 'CPU Currentload', 'rgba(56, 142, 60,1.9)');
    // this.graphCpuRandomNumber = Math.random();
    this.drawEChars(xAxis, yAxis, 'cpu');
  }

  drawMemoryStatsChart(xAxis, groupData) {
    const yAxis = {
      used: [], swapTotal: [], swapUsed: [], total: []
    };
    let totalMemory = 'NA';
    /*if (xAxis[0] && groupData[xAxis[0]] && groupData[xAxis[0]][0]['memory']['total']) {
     totalMemory ='Total memory ' +  (groupData[xAxis[0]][0]['memory']['total'] / (1024 * 1024 * 1024)).toFixed(2) + ' GB';
     }else{
     totalMemory="NA"
     }*/
    /*yAxis['usedMemory'] = */
    forEach(xAxis, function (xValue) {
      if (groupData && groupData[xValue] && groupData[xValue][0]
        && groupData[xValue][0]['memory'] &&
        groupData[xValue][0]['memory']['used']) {
        yAxis.total.push((groupData[xValue][0]['memory']['total'] / (1024 * 1024 * 1024)).toFixed(2));
        yAxis.used.push((groupData[xValue][0]['memory']['used'] / (1024 * 1024 * 1024)).toFixed(2));
        yAxis.swapTotal.push((groupData[xValue][0]['memory']['swaptotal'] / (1024 * 1024 * 1024)).toFixed(2));
        yAxis.swapUsed.push((groupData[xValue][0]['memory']['swapused'] / (1024 * 1024 * 1024)).toFixed(2));
        totalMemory = 'Total memory ' + (groupData[xAxis[0]][0]['memory']['total'] / (1024 * 1024 * 1024)).toFixed(2) + ' GB';
        //TODO:need to verify if it's getting used
        if(totalMemory){

        }
        /* yAxis.usedMemory=used;*/
        /*return (used / (1024 * 1024 * 1024)).toFixed(2)*/
      } else {
        yAxis.total.push(null);
        yAxis.used.push(null);
        yAxis.swapTotal.push(null);
        yAxis.swapUsed.push(null);
      }
    });

    // totalMemory = 'Total memory ' + totalMemory + ' GB';
    // this.setGraphData('Memory Stats', 'line', xAxis, yAxis.usedMemory, 'usedMemory', totalMemory, 'memory', 'Used Memory in GB', 'rgba(255, 160, 0,1.9)');
    // this.graphMemoryRandomNumber = Math.random();
    // this.memoryStatsGraphPlotting(xAxis, yAxis, totalMemory);
    this.drawEChars(xAxis, yAxis, 'memory');
  }

  drawEChars(label, series, value) {
    this.eChartGraph[value].showEchartLoader = false;
    const type = 'line';
    this.eChartGraph[value].graph.title = 'Inspection';
    this.eChartGraph[value].graph.type = type;
    this.eChartGraph[value].graph.legend = keys(series);
    this.eChartGraph[value].graph.series = map(this.eChartGraph[value].graph.legend, function (seriesName) {
      const eChartSeries: EChartSeries = {
        data: series[seriesName],
        type: type,
        name: seriesName,
        stack: false,
        smooth: true,
        label: {normal: {show: false, position: 'top'}},
        areaStyle: {normal: {}},
        itemStyle: {}
      };
      return eChartSeries;
    });
    this.eChartGraph[value].graph.xAxis = label;
    this.eChartGraph[value].show = true;
    this.eChartGraph[value].graph = clone(this.eChartGraph[value].graph);
    this.eChartGraph[value].show = clone(this.eChartGraph[value].show);
  }

  /*getActivePillValue(value: string) {
    let duration: string;
    duration = 'oneHour';
    switch (value) {
      case 'oneHour':
        duration = 'oneHour';
        break;
      case 'sixHours':
        duration = 'sixHours';
        break;
      case 'twelveHours':
        duration = 'twelveHours';
        break;
      case 'oneDay':
        duration = 'oneDay';
        break;
      default:
        break;
    }
    let obj: Object;
    obj = {};
    obj['field'] = this.graphData['hardwarePropertyType'];
    obj['duration'] = duration;
    //this.graphDataWithSeriesType.emit(obj);
  }*/

  getProfileImgForModal(id) {
    this.profileImageForModal = null;
    this.assetService.getProfilePhoto(id)
      .subscribe((resImg) => {
        const that = this;
        if (resImg) {
          if (resImg.type && resImg.type === 'application/json') {
            that.profileImageForModal = '../../assets/img-person-placeholder.jpg';
          } else {
            const reader = new FileReader();
            reader.readAsDataURL(resImg);
            reader.onload = function (e: any) {
              that.profileImageForModal = e.target.result;
            };
          }

        }
      });
  }

  getSelectedLinkedAssetCards(linkedAssetData) {
    linkedAssetData['isClicked'] = true;
    const remainingAssets = this.individualAssetLinkedDetailsArray.filter((asset) => {
      return asset['assetType'] !== linkedAssetData['assetType'];
    });
    for (const i in remainingAssets) {
      remainingAssets[i]['isClicked'] = false;
    }
    this.selectedLinkedAsset = linkedAssetData;
  }

  getCameraLiveStream() {
    setTimeout(() => {
      this.cameraLive.isActive = true;
    }, 50);
    // setTimeout(function() {
    //   let element = document.querySelector('#cameraLive video');
    //   element['muted'] = 'muted';
    //   element['loop'] = 'loop';
    // }, 100);
    /*this.assetService.getCameraLiveStream()
      .subscribe((live: any) => {
        this.videoSorce = live;
      });*/
  }

  resetCameraVariable() {
    this.cameraLive.showCameraLive = false;
    this.cameraLive.isActive = false;
  }

  destroyFeedsFromSocket() {
    this.cameraLive.isActive = false;
    this.serverLive.isActive = false;
  }

  getServerLiveStream() {
    setTimeout(() => {
      this.serverLive.isActive = true;
    }, 50);
  }

  getUpdatedRegisteredFaceThumbnail() {
    const _this = this;
    if (this.isThumbnailAccepted && this.assetData?._id) {
      const imgUrl = this.assetService.getRegisteredFaceThumbnail(this.assetData?._id);
      // imgUrl += '&date=' + new Date().getTime();
      _this.profileImageUpload.url = imgUrl;
    } else if (this.isprofilePhotoAccepted && this.assetData?._id) {
      const imgUrl = this.assetService.getRegisteredProfilePhoto(this.assetData?._id);
     // imgUrl += '&date=' + new Date().getTime();
      _this.profileImageUpload.url = imgUrl;
    }
    // this.oneTimeImageLoaderInit('thumbnailImage' + this.assetData._id, 'thumbnailImage');
  }

  getAddedGroupsForAsset() {
    // this.assetGroupService.getAddedGroupsForAsset(this.assetId)
    //   .subscribe((assetDetails: any) => {
    //     this._isLinkable.groups = [];
    //     if (assetDetails && assetDetails.length > 0 && assetDetails[0]['groupsLinked']) {
    //       this._isLinkable.groups = assetDetails[0]['groupsLinked'];
    //     }
    // });

    this.assetData?.['_id'] &&
    this.assetService?.getAssetDetailsByMongodbId(this.assetData?.['_id']).subscribe((res) => {
      if (res && res['groups']) {
        this._groupVariables.groups = res['groups'];
      } else {
        this._groupVariables.groups = [];
      }
    });
  }

  removeGroupDetails(groupDetailsToRemove) {
    this.emitSelectedGroupIdsTOSlider(groupDetailsToRemove);
  }

  emitSelectedGroupIdsTOSlider(groupDetailsToRemove) {
    if (groupDetailsToRemove.isGroupSelected === true) {
      let res;
      res = find(this._groupVariables.groupsIdsToRemove, function (o) {
        return o === groupDetailsToRemove._id;
      });
      if (!res) {
        this._groupVariables.groupsIdsToRemove.push(groupDetailsToRemove._id);
      }
    } else if (groupDetailsToRemove.isGroupSelected === false) {
      remove(this._groupVariables.groupsIdsToRemove, function (n) {
        return (n === groupDetailsToRemove._id);
      });
    }
    this.removeGroupDetailsToMainComponent.emit(this._groupVariables.groupsIdsToRemove);
  }

  getGroups() {
    this._groupVariables.isGetGroupsInitiated = Math.random();
  }

  getAllCarEventsAndStatus() {
    this.trackingService.getAllCarEventsAndStatus(this.moduleTypeToGetEventTypes).subscribe((data) => {
      this.carEvents = data;
    });
  }

  getSelectedEvent(selectedEventsArray) {
    if (selectedEventsArray?.value?.length > 0) {
      const eventStatusArray = [];
      const eventNamesArray = [];
      const statusNamesArray = [];
      selectedEventsArray?.value?.forEach((obj) => {
        const statusObject = {
          status: [],
          key: ''
        }
        statusObject.status = obj?.value?.status;
        statusObject.key = obj?.key;
        eventStatusArray.push(statusObject);
        eventNamesArray.push(obj.key);
        obj.value.status.forEach((status) => {
          statusNamesArray.push(status);
        });
      });
      this.selectedEventList = eventNamesArray;
      this.selectedStatusList = statusNamesArray;
      this.selectedEventStatusArray = eventStatusArray;

    } else {
      this.selectedEventObjectArray = [];
      this.selectedEventStatusArray = []
      this.selectedEventList = [];
      this.selectedStatusList = [];
    }


  }

  getSelectedStatus(selectedStatusArray) {
    if (selectedStatusArray.length <= 0) {
      this.selectedEventObjectArray = [];
      this.selectedEventList = [];
      this.selectedEventStatusArray = []
      this.selectedStatusList = [];
    }
  }

  showLoaderEvent(value) {
    this.showCircularLoader = value;
  }

  hideProfileImgModal(modalToClose) {
    $(modalToClose).modal("hide");
  }

  oneTimeImageLoaderInit(id, type) {
    const that = this;
    setTimeout(() => {
      const oneTimeImageContainer = $('#' + id);
      // if (oneTimeImageContainer.onload) {
      // .onload.prop('complete')
      if (oneTimeImageContainer.onload) {
        that.imagesLoader[type] = true;
        $(this).parent().find('.curtain').remove();
        // already loaded
      } else {
        $("<div class='curtain'> <div class='shine'></div> </div>").insertAfter(oneTimeImageContainer);
        (oneTimeImageContainer).one('lazyloaded load', function () {
          // now it has loaded
          that.imagesLoader[type] = true;
          $(this).parent().find('.curtain').remove();
        });
      }
    }, 10);
  }

  toggleLiveTab() {
    if (this.carLive) {
      this.carLive['showCarLive'] = ((this.assetData['assetType'] === CONSTANT.ASSET_TYPES.CAR) || (this.assetData['assetType'] === CONSTANT.ASSET_TYPES.DELIVERY_PERSON)) ? true : false;
      if (this.carLive['showCarLive']) {
        this.buildPillsArrayForLiveTab();
      }
    }
  }

  checkIfCarLinkedToObdDevice() {
    this.isCarNotLinkedToAnyObdDevice = this.assetData['locationLastUpdated'] ? false : true;
  }

  getLangLabel(labelCode: string) {
    if (labelCode) {
      return this.lang.getTranslation(labelCode);
    }
  }

  formSubmitHandler(event) {
    this.formSubmitTrigger.emit(event);
  }

  printQrCode(qrCode) {
    let title: string;
    let id: string;
    id = '';
    if (this.assetData && this.assetData.assetType && (this.assetData.assetType === CONSTANT.ASSET_TYPES.PERSON)) {
      title = 'Employee';
    } else {
      title = 'IoTZen';
    }
    if (this.selectedAssetConfigDetails && this.selectedAssetConfigDetails.assetType && this.selectedAssetConfigDetails.assetType.label) {
      id = this.getLangLabel(this.selectedAssetConfigDetails.assetType.label);
    }
    if (this.assetData && this.assetData._id) {
      id = id + ' : ' + this.assetData._id;
    }
    printJS({
      printable: qrCode,
      type: 'image',
      header: title, // Optional
      showModal: true, // Optional
      documentTitle: id,
      modalMessage: 'Printing Qr Code...', // Optional
      style: 'img { width: 800px;height: 800px;}' // Optional})
    });
  }

  getServicesLinkMethodsActivePillValue(value) {
    if (value === 'deploy') {
      this._serviceLinkMethods.showDeploy = true;
      this._serviceLinkMethods.showLink = false;
      this._serviceLinkMethods.activePillIndex = 1;
      this.getAllServicesToManage();
    } else {
      this._serviceLinkMethods.showDeploy = false;
      this._serviceLinkMethods.showLink = true;
    }
  }

  getSelectedServiceForm(selectedService) {
    this._serviceDeploy.uploadedFiles = [];
    this._serviceDeploy.showForm = true;
    $('#configFiles').val('');
    this._serviceDeploy.disableSubmit = true;
    let str: string;
    str = 'Please upload ' + (selectedService.requiredFiles).toString() + ' files';
    this._serviceDeploy.hintToUploadFiles = str;
  }

  getAllServicesToManage() {
    this.serviceLinkableService.getAllServicesToManage()
      .subscribe((allServices: any[]) => {
        this._serviceDeploy.allServicesToManage = allServices;
      });
  }

  onServiceUploadedFileChange(event) {
    this._serviceDeploy.uploadedFiles = [];
    this._serviceDeploy.disableSubmit = false;
    for (let i = 0; i < event.target.files.length; i++) {
      this._serviceDeploy.uploadedFiles.push(event.target.files[i]);
    }
  }

  submitServiceDeploymentData() {
    const formData = new FormData();
    formData.append('serviceId', (this._serviceDeploy.selectedService._id));
    formData.append('serverId', (this.assetId));
    formData.append('processManager', ('pm2'));

    for (let i = 0; i < this._serviceDeploy.uploadedFiles.length; i++) {
      formData.append('configFiles', this._serviceDeploy.uploadedFiles[i]);
    }
    this.serviceLinkableService.deployServiceOnServer(formData)
      .subscribe((res: any) => {
        if (res.message) {
          this.actionMessage = res.message;
          this.setCssProperties.showMessagePopup('actionMessagePopup');
          this._serviceDeploy.selectedService = null;
        }
      });
  }

  camelCaseFormatter(name) {
    const text = name;
    const result = text.replace(/([A-Z])/g, ' $1');
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult;
  }

  getProfileImageForModel() {
    const imgUrl = this.assetService.getRegisteredProfilePhoto(this.assetData._id);
    // imgUrl += '&date=' + new Date().getTime();
    this.profileImageUrlInModel = imgUrl;
  }


//----------------carousel method ----------------

  onThumbnailButtonClick() {
    this.showThumbnails = !this.showThumbnails;
  }

  toggleFullScreen() {
    if (this.fullscreen) {
      this.closePreviewFullScreen();
    } else {
      this.openPreviewFullScreen();
      setTimeout(() => {
        document.getElementById('custom-galleria-delete').click();
      }, 100);
    }
  }

  openPreviewFullScreen() {

    const elem = this.galleria.element.nativeElement.querySelector(".p-galleria");
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem['mozRequestFullScreen']) { /* Firefox */
      elem['mozRequestFullScreen']();
    } else if (elem['webkitRequestFullscreen']) { /* Chrome, Safari & Opera */
      elem['webkitRequestFullscreen']();
    } else if (elem['msRequestFullscreen']) { /* IE/Edge */
      elem['msRequestFullscreen']();
    }
  }

  onFullScreenChange() {
    this.fullscreen = !this.fullscreen;
  }

  closePreviewFullScreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document['mozCancelFullScreen']) {
      document['mozCancelFullScreen']();
    } else if (document['webkitExitFullscreen']) {
      document['webkitExitFullscreen']();
    } else if (document['msExitFullscreen']) {
      document['msExitFullscreen']();
    }
  }

  bindDocumentListeners() {
    this.onFullScreenListener = this.onFullScreenChange.bind(this);
    document.addEventListener("fullscreenchange", this.onFullScreenListener);
    document.addEventListener("mozfullscreenchange", this.onFullScreenListener);
    document.addEventListener("webkitfullscreenchange", this.onFullScreenListener);
    document.addEventListener("msfullscreenchange", this.onFullScreenListener);
  }

  unbindDocumentListeners() {
    document.removeEventListener("fullscreenchange", this.onFullScreenListener);
    document.removeEventListener("mozfullscreenchange", this.onFullScreenListener);
    document.removeEventListener("webkitfullscreenchange", this.onFullScreenListener);
    document.removeEventListener("msfullscreenchange", this.onFullScreenListener);
    this.onFullScreenListener = null;
  }

  galleriaClass() {
    return `custom-galleria ${this.fullscreen ? 'fullscreen' : ''}`;
  }

  fullScreenIcon() {
    return `pi ${this.fullscreen ? 'pi-window-minimize' : 'pi-window-maximize'}`;
  }


  getLighterColor(hex: string): string {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    return '#' + this.componentToHex(r+ 20) + this.componentToHex(g+ 20) + this.componentToHex(b+ 20);
  }

  componentToHex(c: number): string {
    const hex = c.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  }



  onDeleteCarousleItem(event: Event, assetId) {
    const that = this;
    this.confirmationService.confirm({
      target: event.target,
      message: "Are you sure that you want to delete asset",
      icon: "pi pi-exclamation-triangle",
      key: 'imageDeletePopup',
      accept: () => {
        this.assetService.deleteAsset(assetId).subscribe((data) => {
          if (data && data['msg']) {
            this.loadCarousel();
            that.loadCarousel();
            this.showSuccess(that.transformFileName(data['msg']))
            this.activeIndex = 0;

          }
        }, (error) => {
          this.onErrorMessage(that.transformFileName(error['msg']))
          that.loadCarousel();
        })
      },
      reject: () => {
        //reject action
      },
    });

  }

//----------------carousel method ----------------

}


export interface ServerStatsGraphDataType {
  memory: GraphDataType;
  cpu: GraphDataType;
  disk: GraphDataType;
}
