import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { assetDefaultImageConfigsSelector } from '../state/Assets/assets.selector';
import { AssetImageConfigHolder } from '../state/Assets/interfaces/Assets.modal';

@Injectable({
  providedIn: 'root'
})
export class LinkedAssetIconService {

  assetLinkedImageConfig: AssetImageConfigHolder;
  constructor(private store: Store) {
    this.store.select(assetDefaultImageConfigsSelector).subscribe((configs: AssetImageConfigHolder) => {
      this.assetLinkedImageConfig = configs;
    })

   }

   getIconAndColorsForLinkedAssets(assetData, config){
      for (const i of assetData) {
        const t = i['assetType'] ? i['assetType'] : i['type'];
        for (const j in config) {
          if (i['assetType'] ? i['assetType'] === config[j]['type'] : i['type'] === config[j]['type']) {
            i['color'] = config[j]['theme']['color']['primary'];
            if (this?.assetLinkedImageConfig?.[t]?.url) {
              i['icon'] = this.assetLinkedImageConfig[t]?.url;
            } else {
              i['icon'] = '../../assets/exclamation-red.png';
            }

          }
        }
      }
   }

   

}
