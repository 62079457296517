import { Component, OnInit, OnDestroy, ChangeDetectorRef, AfterViewInit, signal } from '@angular/core';
import {ServiceLinkableService} from '../../services/serviceLinkable.service';
import { AssetService } from '../../services/asset.service';
import { AssetGroupService } from '../../services/asset.group.service';
import {AssetCountType} from './asset-count-type';
import {HealthCheckService} from '../../services/healthCheck.service';
import {NavigationEnd, Router} from '@angular/router';
import {FormService} from "../../services/form.service";
import {AssetConfigService} from '../../services/asset-config.service';
import {SearchService} from '../../services/search.service';
import {SetCssPropertiesService} from '../../services/set-css-properties.service';
import { Subscription } from 'rxjs';
import {find, matchesProperty, groupBy, orderBy, slice, map, filter, forEach } from 'lodash';
import moment from 'moment-timezone';
import { Subject,Actions} from '../../auth/rules';
import {SplitScreenService} from '../../services/split-screen.service';
declare let $ :any;
import {Ability} from '@casl/ability';
import {CONSTANT} from '../../config/constant';
import {LangUtilService} from '../util/lang-util.service';
import {BreadcrumbService} from 'app/core/breadcrumb/app.breadcrumb.service';
import { Store } from '@ngrx/store';
import { selectSidebarState } from '../../state/Sidebar/sidebar.selector';
import { assetConfigSelector, assetsCardsImageDisplayStatusSelector, assetsCountSelector, geoFencesForAssetSelector, getCurrentPageData, selectMultipleAssetCreationStatus, selectedAssetLinkedGroupsSelector, selectedAssetsDataSelector } from '../../state/Assets/assets.selector';
import { AssetsCounts, Group } from '../../state/Assets/interfaces/Assets.modal';
import { createMultipleAssets, getGeoFenceByAssetId, loadAssetLinkedGroupsCount, loadAssetsFromState, removeCachedAssetLinkData, setCurrentPageSkipLimit, setSelectedAssetType } from '../../state/Assets/assets.action';
import { IRootAssetTypes } from '../../state/Assets/interfaces/IAsset.types.modal';
import {ConfirmationService, MessageService} from 'primeng/api';
import { getAccountFromLoginResponse } from '../../state/Login/login.selector';
import { Account } from '../../state/Login/login.reducer';

const CURRENT_LIMIT_PER_PAGE = CONSTANT.ASSET.CURRENT_LIMIT_PER_PAGE;

@Component({
  selector: 'app-asset-list',
  templateUrl: './asset-list.component.html',
  styleUrls: ['./asset-list.component.scss']
})
export class AssetListComponent implements OnInit, OnDestroy, AfterViewInit {
  currRoute: string;
  assetFromConfig:any;
  public assetCountByType: any = [];
  public showSlider = false;
  public assetDetailsAfterLinkingUpdated: any;
  public allAssets: any = [];
  public assetTypesWithLabels: any = [];
  public assetCount: any = [];
  public assetCountCardArray: any = [];
  public allServersStatsArray: any = [];
  public allServicesStatus: any = [];
  public selectedAssets: any = [];
  public assetData: any;
  public assetType: string;
  public selectedAssetConfigDetails: any;
  public assetId: any;
  public assetLinkedDetails: any = {};
  public delinkAssetDetails: any;
  public delinkServiceDetails: any;
  public linkAssetDetails: any;
  public deregisterSelectedAssetId: any;
  public resetFormData: number;
  public selectedPageCount: any;
  //public totalPageCount: any;
  //public currentPage: any;
  public upadtedCartSpecificDetails: any ;
  public output: string;
  public skip: number;
  public limit: number;
  public isShowAsset: boolean;
  public selectedAssetsNames: string;
  public selectedAssetsNamesArray: any = [];
  public searchForAsset = '';
  private searchPlaceholder = '';
  private assetCreationSettings
  public  linkDelinkAction = false;
  public  isServiceLinkDelinkAction = false;
  public assetConfigDetails: any;
  public serviceAssetConfigDetails: any;
  public embossedFlagStatus: boolean;
  public searchOperationDone: boolean;
  public isAssetDeregisteredSuccess: boolean;
  public selectedAssetToLinkCount: number;
  public showCircularLoaderValue: number;
  public resetAddAssetFormValue: number;
  public assetRegisterErrorMessages: any = [];
  public  serviceIdsToLinkAsset: any = [];
  private searchTextSub: Subscription;
  private routeEvents: Subscription;
  private assetMenuStatusEvent: Subscription;
  private selectedAssetsDataSub: Subscription;
  private assetsCountSelectorSub: Subscription;
  private assetConfigSelectorSub: Subscription;
  private assetLinkedGroupsSelectorSub: Subscription;
  private geoFencesForAssetSub: Subscription;
  private multipleAssetCreationSub: Subscription;
  private assetCreationSettingsSub: Subscription;


  SUBJECT=Subject;
  ACTIONS=Actions;
  isActiveCardStatus: boolean;
  menuOverFlowFlag: boolean;
  imageonlyUpdated: boolean;
  sliderVariables = {
    slideMargin : 0,
    count : 0
  };
  assetLabel: string;
  splitScreenActive$ = this.store.select(selectSidebarState);
  cardsImageDisplayStatus$ = this.store.select(assetsCardsImageDisplayStatusSelector);
  isprofilePhotoAccepted: boolean;
  isThumbnailAccepted: boolean;
  isQrCodeRequired: boolean;
  hideAssetsMenu: boolean;
  groupsList: any[] = [];

  filterFields: any = {
    selectedGroups : [],
    isFilterApplied: false,
    filterClickedIndex: 0
  };
  carLive = {
    contentTypesToShow: [],
    selectedContentType : ''
  };
  formData: any;
  eventToEdit: any;
  isEventSelectedToEdit: boolean;
  assetTypeToSend:string
  showDeRegisterBtn: boolean = false;
  showUpdateBtn: boolean = false;
  resetAddMultipleAssetFormValue = signal<number>(1);
  selectedAssetConfigDetailsForMultiplAssets = signal<Object>({});
  constructor(private store: Store, public assetService: AssetService, private router: Router, public formService: FormService,
              public assetConfigService: AssetConfigService, private searchService: SearchService,
              private  serviceLinkableService: ServiceLinkableService, public healthCheckService: HealthCheckService,
              public setCssProperties: SetCssPropertiesService,
              private confirmationService: ConfirmationService,
              private messageService: MessageService,
              private ability: Ability,
              private lang: LangUtilService, private cdr: ChangeDetectorRef, private breadCrumbService: BreadcrumbService,
              private splitScreenService: SplitScreenService, private assetGroupService: AssetGroupService) {
    // this.getAllServersStats();
    this.showDeRegisterBtn = this.isRemoveAssetBtnRequired();
    this.showUpdateBtn = this.isUpdateAssetBtnRequired();
    this.embossedFlagStatus = true;
    this.pageNumber = 1;
    this.currRoute = router.url;
    this.pagination = {
      'currentPage' : 1,
      'totalNoOfRecords': 0,
      'recordsPerPage' : 12,
      'pageCountValue' : 0,
    };
    this.selectedAssets = [];
    this.selectedAssetsNames = '';
    this.searchOperationDone = false;
    this.isAssetDeregisteredSuccess = false;
    this.imageonlyUpdated = false;
     // this.splitScreenActive = false;
    this.doSearchTextResetAndOtherOperations();
   /*  const localThis = this;
    $( window ).resize(function() {
      const currentWindowHeight = $(window).height();
      if (currentWindowHeight < localThis.defaultWindowHeight) {
        localThis.scrollAssetMenu();
      }
    }); */
  }

  public countObj: AssetCountType;
  public pagination: any;
  public pageNumber: any;
  p = 1;
  _addGroups = {
    ids: [],
    count: 0,
    msg: '',
    action: false
  };
  _removeGroups = {
    ids: [],
    count: 0,
    msg: '',
    action: false
  };
 // @Output() event:EventEmitter<string> = new EventEmitter<string>()

  assetCreationButtons = []

  geoFenceData

  ngAfterViewInit () {
    this.cdr.detectChanges();
  }


  assetsCounts: AssetsCounts;
  ngOnInit() {
    this.getSelectedAssetTypeFromRoute();
    this.assetsCountSelectorSub = this.store.select(assetsCountSelector).subscribe((counts) => {
      if(counts) {
        this.assetsCounts = counts;
        this.selectedAssets?.[0] ? this.setPaginationFromState(this.selectedAssets[0]) : '';
      }
    })

    this.geoFencesForAssetSub = this.store.select(geoFencesForAssetSelector).subscribe((geoFenceData) => {
      if ((geoFenceData?.geoLocation?.lat !== this.geoFenceData?.geoLocation?.lat ||
         geoFenceData?.geoLocation?.lng !== this.geoFenceData?.geoLocation?.lng ||
          geoFenceData?.geoFenceRadius !== this.geoFenceData?.geoFenceRadius)
         && (this.assetData?.hasOwnProperty('geoFenceRadius') || this.eventToEdit?.hasOwnProperty('geoFenceRadius')) ){

          const assetDetails= {
            ...this.eventToEdit,
            geoFenceRadius:geoFenceData?.geoFenceRadius || 0,
            geoLocation:{coordinates:[geoFenceData?.geoLocation?.lng,geoFenceData?.geoLocation?.lat]}
          };
          this.eventToEdit=assetDetails
      }

      this.geoFenceData=geoFenceData
    })

    this.selectedAssetsDataSub = this.store.select(selectedAssetsDataSelector).subscribe((data) => {
       this.setAssetData(data);
     })

     this.assetConfigSelectorSub = this.store.select(assetConfigSelector).subscribe((assetConfig: IRootAssetTypes[]) =>{
      if(assetConfig?.length > 0) {
        this.assetConfigDetails = assetConfig;
        this.assetCountByType = assetConfig;
        this.handleAssetCreationButtons()
        this.getAssetIndividualCounts();
      }
    })

    this.assetLinkedGroupsSelectorSub = this.store.select(selectedAssetLinkedGroupsSelector({assetType: this.selectedAssets[0]})).subscribe((groupsLinked: Group[]) => {
         this.groupsList = groupsLinked;
    })
    this.assetMenuStatusEvent = this.assetService.assetMenuStatus
      .subscribe((status) => {
        this.hideAssetsMenu = status;
      });
   // this.isShowAsset = true;
    /* this.assetConfigService.getAssetsConfig()
      .then(assetConfig => {
        this.assetConfigDetails = assetConfig;
      }); */
    this.getServiceAssetConfiguration();
    this.getServicesHealth();
    // this.getAssetTotalCountForPagination();
    // this.getFirstFewAssets();
    // this.getAssetForSelectedAssetTypes(0, this.pagination.recordsPerPage);
    // this.getAssetIndividualCounts();
    this.searchTextSub = this.searchService.searchText.subscribe(event => {
      this.searchForAsset = event;
      const eventLength = event.length;
      if (this.assetCountByType && this.assetCountByType.length > 0) {
        if (eventLength > 2) {
          this.searchOperationDone = true;
          this.pagination.currentPage = 1;
          if (this.searchForAsset) {
            this.getAssetForSelectedAssetTypesOnSearch(this.searchForAsset, 0 , this.pagination.recordsPerPage );
          } else {
            this.getAssetForSelectedAssetTypes();
          }
        } else {
          if (this.searchOperationDone) {
            this.searchOperationDone = false;
            this.getAssetForSelectedAssetTypes();
          }
        }
      }
    });

    this.multipleAssetCreationSub =
      this.store.select(selectMultipleAssetCreationStatus).subscribe(status => {
        if (status >= 200 && status <= 201) {
          this.showMultipleAssetCreationForm.set(false);
          this.assetService.sendNewThingsAddedStatus(true);
          this.getAssetCountsWithFilter()
        }
      });
    this.assetCreationSettingsSub =
      this.store.select(getAccountFromLoginResponse).subscribe((data: Account) => {
        this.assetCreationSettings = data?.assetCreation || {};
        data?.assetCreation && this.handleAssetCreationButtons()
      })
  }

  setBreadCreams(assetLabel){
    this.breadCrumbService.setItems([
      {label:this.getLangLabel('Things')},
      {label:assetLabel, routerLink:[]}
    ])
  }

  getSelectedAssetTypeFromRoute() {
    let url = this.router.url;    // '/asset/car'
    let selectedType;
    // getting and parsing url on init
    selectedType = this.parseAndGetSelectedTypeFromURL(url);
    this.assetTypeToSend = selectedType;
    this.store.dispatch(setCurrentPageSkipLimit({assetType:selectedType,skip:0,limit:null}))
    this.assetTypeCollectionToFilter(selectedType);

    this.store.dispatch(setSelectedAssetType({assetType: selectedType}))
    this.setPaginationFromState(selectedType);

    // Subscribing to the router events on change
    this.routeEvents = this.router.events.subscribe(routerEvent => {
      if (routerEvent instanceof NavigationEnd) {
        url = routerEvent.url;
        if (url.includes('/asset')) {
          selectedType = this.parseAndGetSelectedTypeFromURL(url);
          if ((selectedType === CONSTANT.ASSET_TYPES.CAR || selectedType === CONSTANT.ASSET_TYPES.DRIVER  || selectedType === CONSTANT.ASSET_TYPES.PERSON) && this.hideAssetsMenu === true) {
            this.hideAssetsMenu = true;
          } else {
            this.hideAssetsMenu = false;
          }
          this.assetTypeCollectionToFilter(selectedType);
          const selectedDashData = find(this.assetCountByType, matchesProperty('type', selectedType));
          this.addActiveClass(selectedDashData);
          const selectedAssetObj = find(this.assetCountCardArray, matchesProperty('type', selectedType));
          this.filterAssetOnType(selectedAssetObj);
        }
      }
    });
  }

  parseAndGetSelectedTypeFromURL(url) {
    let accountDetails;
    let slashIndexInUrl;
    if (url === '/asset' || url === '/asset/') {
      accountDetails = JSON.parse(sessionStorage.getItem('account'));
      if(accountDetails && accountDetails['assetTypes']!==null){
          return accountDetails['assetTypes'][0];
      }
    } else {
      slashIndexInUrl = url.lastIndexOf('/');
      return url.substr(slashIndexInUrl + 1);
    }
  }

  doSearchTextResetAndOtherOperations() {
    this.searchService.showSearchBar(true);
    this.searchService.resetSearchTextInSearchBox();
    this.searchService.sendSearchPlaceholderValue(this.searchPlaceholder);
    this.searchService.sendSearchedInfoValue(this.selectedAssetsNames);
  }

  ngOnDestroy() {
    this.routeEvents?.unsubscribe();
    this.searchTextSub?.unsubscribe();
    this.selectedAssetsDataSub?.unsubscribe();
    this.assetsCountSelectorSub?.unsubscribe();
    this.assetConfigSelectorSub?.unsubscribe();
    this.assetLinkedGroupsSelectorSub?.unsubscribe();
    this.assetMenuStatusEvent?.unsubscribe();
    this.geoFencesForAssetSub?.unsubscribe();
    this.multipleAssetCreationSub?.unsubscribe();
    this.assetCreationSettingsSub?.unsubscribe();
    // this.hideAssetsMenu = false;
     // this.splitScreenActive = false;
    this.splitScreenService.hideModalAndRemoveSplitScreen();
  }

  groupAllServiceLinkableAssets(assetConfigDetails) {
    let serviceLinkableAssets: any;
    serviceLinkableAssets = groupBy(assetConfigDetails, function (asset) {
      if (asset?.config?.isServiceLinkable === true) {
        return asset.type;
      }
    });
    return serviceLinkableAssets;
  }

  getServiceAssetConfiguration() {
    this.assetConfigService.getServiceAssetConfig()
      .then(serviceAssetConfig => {
        this.serviceAssetConfigDetails = serviceAssetConfig;
      });
  }

  getAssetIndividualCounts() {
    if(this.assetCountByType?.length > 0) {
      this.assetCountByType = orderBy(this.assetCountByType, ['order', 'type'], ['asc']);
      if (this.selectedAssets.length === 0) {
        this.assetTypeCollectionToFilter(this.assetCountByType[0]['type']);
        this.assetLabelCollectionToDispaly(this.assetCountByType[0]['label']);
        this.store.dispatch(loadAssetLinkedGroupsCount({assetType: this.assetCountByType[0]['type']}))
      } else {
        const selectedAssetObj = find(this.assetCountByType, matchesProperty('type', this.selectedAssets[0]));
        (selectedAssetObj && selectedAssetObj['label']) ? this.assetLabelCollectionToDispaly(selectedAssetObj['label']): null
        this.store.dispatch(loadAssetLinkedGroupsCount({assetType: this.selectedAssets[0]}))
      }
      this.assetCountCardArray = slice(this.assetCountByType, 0, this.assetCountByType.length);
      // this.getAssetForSelectedAssetTypes(0, this.pagination.recordsPerPage);
    } else {
      this.isShowAsset = false;
    }

    /* this.assetService.getAssetCount()
      .subscribe((assetIndividualCount: AssetCountType) => {
        if (assetIndividualCount['count'] && assetIndividualCount['count'].length > 0) {
          this.assetCountByType = assetIndividualCount['count'];
          this.assetCountByType = orderBy(this.assetCountByType, ['order'], ['asc']);
          if (this.selectedAssets.length === 0) {
            this.assetTypeCollectionToFilter(this.assetCountByType[0]['type']);
            this.assetLabelCollectionToDispaly(this.assetCountByType[0]['label']);
            this.getAllGroups(this.assetCountByType[0]['type']);
          } else {
            const selectedAssetObj = find(this.assetCountByType, matchesProperty('type', this.selectedAssets[0]));
            (selectedAssetObj && selectedAssetObj['label']) ? this.assetLabelCollectionToDispaly(selectedAssetObj['label']): null
            // this.assetLabelCollectionToDispaly(selectedAssetObj['label']);
            this.getAllGroups(this.selectedAssets[0]);
          }
          this.assetCountCardArray = slice(this.assetCountByType, 0, this.assetCountByType.length);
          setTimeout(() => {
            this.scrollAssetMenu();
          }, 500);
          for (const assetCount of this.assetCountByType) {
            for (const selectedAsset of this.selectedAssets) {
              if (assetCount.type === selectedAsset) {
                assetCount.selectedForFiler = true;
                break;
              } else {
                assetCount.selectedForFiler = false;
              }
            }
          }
          this.getAssetForSelectedAssetTypes(0, this.pagination.recordsPerPage);
          // setTimeout(function() { _this.glider(); }, 100);
        } else {
          this.isShowAsset = false;
        }
      }); */
  }

 /*  glider(direction) {
    const elementHeight = $('.assets-menu-item').height() + 2;
    const totalItems = this.assetCountCardArray.length;
    if (direction === 'down') {
      this.sliderVariables.count++;
      if (this.sliderVariables.count < totalItems) {
        if (this.sliderVariables.slideMargin > 0) {
          this.sliderVariables.slideMargin = -(this.sliderVariables.slideMargin + elementHeight);
        } else {
          this.sliderVariables.slideMargin = this.sliderVariables.slideMargin - elementHeight;
        }
        $('.slide-track').animate({'margin-top': this.sliderVariables.slideMargin + 'px'}, 500);
      } else {
        $('.slide-track').animate({'margin-top': '0px'}, 500);
        this.sliderVariables.slideMargin = 0;
        this.sliderVariables.count = 0;
      }
    } else {
      if (this.sliderVariables.slideMargin < 0) {
        this.sliderVariables.count--;
        this.sliderVariables.slideMargin = this.sliderVariables.slideMargin + elementHeight;
      } else {
        this.sliderVariables.slideMargin = -((elementHeight * totalItems) - elementHeight);
        this.sliderVariables.count = totalItems - 1;
      }
      $('.slide-track').animate({'margin-top': this.sliderVariables.slideMargin + 'px'}, 500);
    }
  } */

  checkIfProfileImageAccepted() {
    // let groupByLabel = groupBy(this.assetConfigDetails, 'type');
    // if (groupByLabel[selectedAsset['type']] && groupByLabel[selectedAsset['type']][0]['config'] && groupByLabel[selectedAsset['type']][0]['config']['fileUpload'] && groupByLabel[selectedAsset['type']][0]['config']['fileUpload']['profilePhoto'] && groupByLabel[selectedAsset['type']][0]['config']['fileUpload']['profilePhoto']['accept'] && (groupByLabel[selectedAsset['type']][0]['config']['fileUpload']['profilePhoto']['faceMatch'] || groupByLabel[selectedAsset['type']][0]['config']['fileUpload']['profilePhoto']['thumbnail'])) {
    //   this.isThumbnailAccepted = true;
    // } else {
    //   this.isThumbnailAccepted = false;
    // }

    // if (groupByLabel[selectedAsset['type']] && groupByLabel[selectedAsset['type']][0]['config'] && groupByLabel[selectedAsset['type']][0]['config']['fileUpload'] && groupByLabel[selectedAsset['type']][0]['config']['fileUpload']['profilePhoto'] && groupByLabel[selectedAsset['type']][0]['config']['fileUpload']['profilePhoto']['accept'] && (!groupByLabel[selectedAsset['type']][0]['config']['fileUpload']['profilePhoto']['faceMatch'] && !groupByLabel[selectedAsset['type']][0]['config']['fileUpload']['profilePhoto']['thumbnail'])) {
    //   this.isprofilePhotoAccepted = true;
    // } else {
    //   this.isprofilePhotoAccepted = false;
    // }

    // if (groupByLabel[selectedAsset['type']] && groupByLabel[selectedAsset['type']][0]['config'] && groupByLabel[selectedAsset['type']][0]['config']['qrCode']) {
    //   this.isQrCodeRequired = true;
    // } else {
    //   this.isQrCodeRequired = false;
    // }
    // console.log(selectedAsset, 'selected asset')
    // this.assetService.checkAssetShowTrue(selectedAsset['_id']).subscribe((res)=>{
    //     if(res && res['show']){
    //         this.isThumbnailAccepted = true
    //     }
    // })
    this.isThumbnailAccepted = true
    this.isprofilePhotoAccepted = false
  }
  filterAssetOnType(asset) {
    /*if (asset.config && asset.config.showStats && asset.config.showStats === true) {
      this.getAllServersStats();
    }*/
    // this.getAllServersStats();
    this.toggleActiveClassEvent();
    this.assetTypeCollectionToFilter(asset.type);
    this.assetLabelCollectionToDispaly(asset.label);
    this.resetFilterFields();
    this.store.dispatch(loadAssetLinkedGroupsCount({assetType: asset.type}))
    this.searchOperationDone = false;
    this.searchForAsset = '';
    if (this.searchForAsset) {
      this.getAssetForSelectedAssetTypesOnSearch(this.searchForAsset, 0 , this.pagination.recordsPerPage );
    } else {
      this.getAssetForSelectedAssetTypes();
    }
    this.searchService.resetSearchTextInSearchBox();
  }

  setEmbossedFlagStatus() {
    // this.embossedFlagStatus = embossedFlagStatus;
  }
  toggleActiveClassEvent() {
    this.assetId= '';
    this.splitScreenService.removeActiveClassForAllCards(this.allAssets);
     // this.splitScreenActive = false;
    this.assetData = null;
  }
  assetTypeCollectionToFilter(assetType) {
    this.selectedAssets = [];
    this.selectedAssets.push(assetType);
    /*let res;
    res = _.find(this.selectedAssets, function(o) { return o === assetType; });
    if (res) {
      let event = _.remove(this.selectedAssets, function(n) {
        return (n === res);
      });
    }  else {
      this.selectedAssets.push(assetType);
    }*/
  }
  createModalClosedEvent() {
    this.splitScreenService.closeCreateModal();
  }
  assetLabelCollectionToDispaly(assetLabel) {
    this.selectedAssetsNamesArray = [];
    this.selectedAssetsNamesArray.push(assetLabel);
    /*let label;
    label = _.find(this.selectedAssetsNamesArray, function(o) { return o === assetLabel; });
    if (label) {
      let event = _.remove(this.selectedAssetsNamesArray, function(n) {
        return (n === label);
      });
    }  else {
      this.selectedAssetsNamesArray.push(assetLabel);
    }*/
    this.selectedAssetsNames = this.selectedAssetsNamesArray.toString();
    this.handleAssetCreationButtons();
    this.setSearchingPlaceholder(this.selectedAssetsNamesArray);
    this.searchService.sendSearchedInfoValue(this.selectedAssetsNames);

    this.setBreadCreams(this.getLangLabel(this.selectedAssetsNames));
  }

  getAssetForSelectedAssetTypesOnSearch(searchForAsset: string, skip, limit) {
    this.pagination.currentPage = 1;
    let selectedAssetTypesAsString = '';
    selectedAssetTypesAsString = this.selectedAssets.toString();
    if (this.embossedFlagStatus === false) {
      selectedAssetTypesAsString = '';
      this.selectedAssets = [];
      this.selectedAssetsNamesArray = [];
      this.selectedAssetsNames = this.selectedAssetsNamesArray.toString();
      this.handleAssetCreationButtons();
      this.setSearchingPlaceholder(this.selectedAssetsNamesArray);
      this.searchService.sendSearchedInfoValue(this.selectedAssetsNames);
    }
    this.getSearchedAssetPaginationCount(searchForAsset, selectedAssetTypesAsString, skip, limit);
  }

  private setAssetListByTypesOnSearch(searchForAsset: string, selectedAssetTypesAsString: string, skip: number, limit: number) {
    this.isShowAsset = true;
    this.assetService.getAssetOnSearch(searchForAsset, selectedAssetTypesAsString, skip, limit)
      .subscribe(result => {
        this.isShowAsset = false;
        let allAssetArray: any = [];
        allAssetArray = this.checkAssetTypeToDisplayStatus(result);
        const isServiceLinkableAssetFoundInArray: boolean = this.checkForServiceLinkableAssetTypeInAssetList(result);
        if (isServiceLinkableAssetFoundInArray) {
          this.getAllServersStats(allAssetArray);
          this.toggleSplitScreenFunction();
        } else {
          this.allAssets = this.checkServerStatus(allAssetArray);
          this.toggleSplitScreenFunction();
        }
      });
  }

  getSearchedAssetPaginationCount(searchForAsset: string, selectedAssetsAsString: string, skip: number, limit: number) {
    this.isShowAsset = true;
     this.assetService.getSearchedAssetPaginationCount(this.searchForAsset, selectedAssetsAsString)
       .subscribe(searchedAssetCount => {
         this.isShowAsset = false;
         this.pagination.totalNoOfRecords = searchedAssetCount['count'];
         if (this.pagination.totalNoOfRecords > 0) {
           this.setAssetListByTypesOnSearch(searchForAsset, selectedAssetsAsString, skip, limit);
         } else if (this.pagination.totalNoOfRecords === 0) {
           this.allAssets = [];
         }
       });
  }

  getAssetForSelectedAssetTypes() {
    this.pagination.currentPage = 1;
    let selectedAssetsStr = '';
    selectedAssetsStr = this.selectedAssets.toString();
    if (this.embossedFlagStatus === false) {
      selectedAssetsStr = '';
      this.selectedAssets = [];
      this.selectedAssetsNamesArray = [];
      this.selectedAssetsNames = this.selectedAssetsNamesArray.toString();
      this.handleAssetCreationButtons();
      this.setSearchingPlaceholder(this.selectedAssetsNamesArray);
      this.searchService.sendSearchedInfoValue(this.selectedAssetsNames);
    }
   // this.getAssetCountByTypes(selectedAssetsStr, skip, limit);
    this.setPaginationFromState(selectedAssetsStr);
  }

  // private setAssetListByTypes(selectedAssetsStr: string, skip: number, limit: number) {
  //   /* this.isShowAsset = true;
  //   this.assetService.getAllByType(selectedAssetsStr, skip, limit)
  //     .subscribe(result => {
  //       this.isShowAsset = false;
  //       let allAssetArray: any = [];
  //       const selectedAssetObj = find(this.assetCountCardArray, matchesProperty('type', this.selectedAssets[0]));
  //       this.checkIfProfileImageAccepted(selectedAssetObj);
  //       let isServiceLinkableAssetFoundInArray: boolean;
  //       allAssetArray = this.checkAssetTypeToDisplayStatus(result);
  //       isServiceLinkableAssetFoundInArray = this.checkForServiceLinkableAssetTypeInAssetList(result);
  //       if (isServiceLinkableAssetFoundInArray) {
  //         this.getAllServersStats(allAssetArray);
  //         this.toggleSplitScreenFunction();
  //       } else {
  //         this.isShowAsset = true;
  //         if (allAssetArray[0] && allAssetArray[0].assetType === this.selectedAssets.toString()) {
  //           this.allAssets = this.checkServerStatus(allAssetArray);
  //         }
  //         this.isShowAsset = false;
  //         this.toggleSplitScreenFunction();
  //       }
  //   }); */
  // }

  private setPaginationFromState(selectedAssetsStr: string) {
    let currentSkip = 0;
    this.store.select(getCurrentPageData).subscribe((skip)=>{
      currentSkip = skip?.['skip']
    })
    this.isShowAsset = true;
    const assetCount = this.assetsCounts?.[selectedAssetsStr]?.['count'];
    if(assetCount || assetCount == 0) {
      this.isShowAsset = false;
      this.pagination.totalNoOfRecords = assetCount;
      if (this.pagination.totalNoOfRecords > 0) {
        this.store.dispatch(loadAssetsFromState({assetType: selectedAssetsStr, skip: currentSkip, limit: CURRENT_LIMIT_PER_PAGE}));
      } else if (this.pagination.totalNoOfRecords === 0) {
        this.allAssets = [];
      }
    }
  }

  private setAssetData(result){
    this.isShowAsset = true;
    if(result?.length > 0) {
      this.isShowAsset = false;
      let allAssetArray: any = [];
      this.checkIfProfileImageAccepted();
      allAssetArray = this.checkAssetTypeToDisplayStatus(result);
      const isServiceLinkableAssetFoundInArray: boolean = this.checkForServiceLinkableAssetTypeInAssetList(result);
      if (isServiceLinkableAssetFoundInArray) {
        this.getAllServersStats(allAssetArray);
        this.toggleSplitScreenFunction();
      } else {
        this.isShowAsset = true;
        if (allAssetArray[0] && allAssetArray[0].assetType === this.selectedAssets.toString()) {
          this.allAssets = this.checkServerStatus(allAssetArray);
        }
        this.isShowAsset = false;
        this.toggleSplitScreenFunction();
      }
    } else {
      this.isShowAsset = false;
    }
  }

  // private getAssetCountByTypes(selectedAssetsStr: string, skip: number, limit: number) {
  //   /* this.isShowAsset = true;
  //   this.assetService.getCountByTypes(selectedAssetsStr)
  //     .subscribe(result => {
  //       this.isShowAsset = false;
  //       this.pagination.totalNoOfRecords = result['count'];
  //       this.getPageCount();
  //       if (this.pagination.totalNoOfRecords > 0) {
  //         this.setAssetListByTypes(selectedAssetsStr, skip, limit);
  //       } else if (this.pagination.totalNoOfRecords === 0) {
  //         this.allAssets = [];
  //       }
  //     }, err => {
  //       this.isShowAsset = false;
  //     }); */
  // }

  setDataFromConfig(data) {
    this.output = data;
  }

  syncUpdateAssetCart(dataUpdated) {
   // this.upadtedCartSpecificDetails = dataUpdated;
    let isUpdatedAssetIsServiceLinkableAsset: boolean;
    isUpdatedAssetIsServiceLinkableAsset = false;
    const array: any = [];
    array.push(dataUpdated);
    isUpdatedAssetIsServiceLinkableAsset = this.checkForServiceLinkableAssetTypeInAssetList(array);
    if (isUpdatedAssetIsServiceLinkableAsset) {
      this.checkUpdatedServiceLinkableAssetStatus(dataUpdated);
    } else {
      this.updateAssetCard(dataUpdated);
    }
    if (!this.imageonlyUpdated) {
      const msg = this.getLangLabel('app.asset-list.asset-updated')
      this.messageService.add({ key: 'asset-list-toast', severity: 'success', summary: 'Success', detail: msg  });
    }
    setTimeout(() => {
      this.imageonlyUpdated = false;
    }, 4000 );
  }

  updateAssetCard(dataUpdated) {
    dataUpdated['cardClicked'] = true;
    for (const i in this.allAssets) {
      if (this.allAssets[i]['_id'] === dataUpdated['_id']) {
        this.allAssets[i] = dataUpdated;
        this.allAssets[i]['isUpdated'] = true;
        this.toggleSplitScreenFunction();
        break;
      }
    }
  }

  setProfilePhotoUpdatedFlag(value) {
    this.imageonlyUpdated = value;
  }

  assetRegistrationErrorMsgToAssetList(errMsg: string) {
    this.assetRegisterErrorMessages = [];
    this.assetRegisterErrorMessages.push(errMsg);
    this.messageService.add({ key: 'asset-list-toast', severity: 'error', summary: 'Error', detail: errMsg });
    $('#sidebar-right-to-add').modal('hide');
  }

  savedAssetCardToAssetList() {
    const msg = this.getLangLabel('app.asset-list.asset-saved')
    this.messageService.add({ key: 'asset-list-toast', severity: 'success', summary: 'Success', detail: msg  });
     this.splitScreenService.closeCreateModal();
    //this.selectedAssets = [];
    //this.getAssetTotalCountForPagination();
    //this.getFirstFewAssets();
    this.getAssetForSelectedAssetTypes();
   // this.getAssetIndividualCounts();
  }

  onNotificationEventSubmit(event) {
    const modPayload = this.modifyPayloadForLinkConfig(event.value)
    if (event.action === 'save') {
      this.saveAsset(modPayload);
    } else if (event.action === 'update') {
      this.updateAsset(modPayload);
    }
  }

  resetOnModelClosed() {
    //  this.splitScreenService.closeCreateModal('.create-modal', '#sidebar-right');
  }

  saveAsset(formData) {
    this.assetService.saveAssetDetails(formData)
      .subscribe(() => {
          this.savedAssetCardToAssetList();
          this.assetService.sendNewThingsAddedStatus(true);
        },
        err => {
          if (err.status === 405 ) {
            this.assetRegistrationErrorMsgToAssetList(err['error']['message']);
          }
        });
  }

  updateAsset(formData) {
    this.assetService.updateAssetsById(this.assetId, formData)
      .subscribe((assetvalues: any) => {
        this.syncUpdateAssetCart(assetvalues);
            (this.assetData?.hasOwnProperty('geoFenceRadius') || this.eventToEdit?.hasOwnProperty('geoFenceRadius')) &&
            this.assetId &&
            this.store.dispatch(getGeoFenceByAssetId({assetId:this.assetId}))
      });
  }

  editAsset(assetDetails) {
    this.assetTypeToSend = assetDetails?.assetType
    this.isEventSelectedToEdit = true;
    this.toggleCarLiveTab(assetDetails);
     // this.splitScreenActive = true;
    assetDetails = this.splitScreenService.addActiveClass(assetDetails, this.allAssets, '_id');
    this.splitScreenService.scrollToSelectedCard();
    this.splitScreenService.addOrRemoveSplitScreen('addSplitScreen', '#sidebar-right');
    this.linkDelinkAction = false;
    this.isServiceLinkDelinkAction = false;
     // this.splitScreenActive = true;
    this.showSlider = true;
    this.assetId = assetDetails._id;
    this.assetLabel = assetDetails['assetLabel'];
    this.getAssetLinked(this.assetId, true);
    this.showCircularLoaderValue = Math.random();
    this.splitScreenService.scrollToSelectedCard();
    assetDetails['cardClicked'] = false;

  }

  toggleCarLiveTab(assetDetails) {
    const tabContentsForCarType = ['gauge', 'graph', 'map'];
    const tabContentsForDeliveryAgentType = ['gauge', 'map'];
    if (assetDetails['assetType'] === CONSTANT.ASSET_TYPES.CAR) {
      this.carLive.contentTypesToShow = tabContentsForCarType;
    } else if (assetDetails['assetType'] === CONSTANT.ASSET_TYPES.DELIVERY_PERSON) {
      this.carLive.contentTypesToShow = tabContentsForDeliveryAgentType;
    } else {
      this.carLive.contentTypesToShow = [];
    }
    // this.carLive.contentTypesToShow = (assetDetails['assetType'] === CONSTANT.ASSET_TYPES.CAR || assetDetails['assetType'] === CONSTANT.ASSET_TYPES.DELIVERY_PERSON) ? tabContents : [];
    this.carLive.selectedContentType = this.carLive.contentTypesToShow[0];
  }

  // scrollToSelectedCard() {
  //   $('#main-container').animate({
  //     scrollTop: $('.card.active').offset().top - 100
  //   }, 'slow');
  // }

  getAssetLinked(assetId, isSetEditAssetReq?: boolean) {
    this.assetService.getAssetDetailsByMongodbId(assetId)
      .subscribe((assetDetails: any) => {
         if(isSetEditAssetReq){
          this.eventToEdit = assetDetails;
         }
        this.assetData = assetDetails;
        this.getAssetsLinkedDetails(assetDetails);
        this.isShowAsset=false
      });
  }

  getAssetsLinkedDetails(assetDetails) {
    if (assetDetails?.assetsLinked?.length > 0)  {
      const assetLinkedData = groupBy(assetDetails.assetsLinked, function (asset) {
        return asset.assetType;
      });
      this.assetLinkedDetails = assetLinkedData;
      this.assetDetailsAfterLinkingUpdated = assetDetails;
    } else if (assetDetails?.serviceAssets?.length > 0) {
      const serviceLinkedData = groupBy(assetDetails.serviceAssets, function (service) {
        return service.serviceType;
      });
      this.assetLinkedDetails = serviceLinkedData;
      this.assetDetailsAfterLinkingUpdated = assetDetails;
    } else {
      this.assetLinkedDetails = {};
      this.assetDetailsAfterLinkingUpdated = assetDetails;
    }
  }

  getAllServersStats(allAssetArray?: any) {
    this.isShowAsset = true;
    const durationInMinutes: any = 5;
    const start= moment().subtract(durationInMinutes, 'minutes').utc();
    const end = moment().utc();
    this.healthCheckService.getAssetStatus(start, end)
      .subscribe(data => {
        this.isShowAsset = false;
        this.allServersStatsArray = data;
        if (allAssetArray && allAssetArray.length > 0) {
          this.allAssets = this.checkServerStatus(allAssetArray);
        }
      });
  }

  getServicesHealth() {
    const durationInMinutes: any = 1;
    const start= moment().subtract(durationInMinutes, 'minutes').utc();
    const end = moment().utc();
    this.serviceLinkableService.getServicesHealth(start, end)
      .subscribe(allServicesStatus => {
        this.allServicesStatus = allServicesStatus;
      });
  }

  checkAssetTypeToDisplayStatus(assetsList) {
    const serviceLinkableAssets = this.groupAllServiceLinkableAssets(this.assetConfigDetails);
    assetsList = map(assetsList, function (asset) {
      if (serviceLinkableAssets[asset.assetType]) {
        asset = {...asset, showStatus: true}
      } else {
        asset = {...asset, showStatus: false}
      }
      return asset;
    });

    return assetsList;
  }

  checkServerStatus(assetsList) {
    const serverStatusGroupById = groupBy(this.allServersStatsArray, '_id');
    assetsList = map(assetsList, function(data) {
      if (serverStatusGroupById[data._id]) {
        if ((serverStatusGroupById[data._id][0]._id) === data._id) {
          data = {...data, running: true}
          data = {...data, stats: serverStatusGroupById[data._id][0]}
        }
      }
      return data;
    });
    return assetsList;
  }

  checkUpdatedServiceLinkableAssetStatus(asset) {
    const durationInMinutes: any = 5;
    const start= moment().subtract(durationInMinutes, 'minutes').utc();
    const end = moment().utc();
    this.healthCheckService.getAssetStatus(start, end)
      .subscribe(data => {
        this.allServersStatsArray = data;
        let serverStatusGroupById: any;
        serverStatusGroupById = groupBy(this.allServersStatsArray, '_id');
        if (serverStatusGroupById[asset._id]) {
          if ((serverStatusGroupById[asset._id][0]._id) === asset._id) {
            asset['running'] = true;
            asset['stats'] = serverStatusGroupById[asset._id][0];
          }
        }
        // this.upadtedCartSpecificDetails = asset;
        this.updateAssetCard(asset);
      });
  }

  checkForServiceLinkableAssetTypeInAssetList(assetsList) {
    let serviceLinkableAssets: any;
    serviceLinkableAssets = this.groupAllServiceLinkableAssets(this.assetConfigDetails);
    for (let i = 0; i < assetsList.length; i++) {
      if (serviceLinkableAssets[assetsList[i].assetType]) {
        return true;
      }
    }
    return false;
  }

  confirmedDelink(status) {
    if (status === 'OK') {
      let primaryAsset: any;
      const assetsToBeDeLinked : any = [];
      primaryAsset = this.assetId;
      assetsToBeDeLinked.push(this.delinkAssetDetails._id);
      let obj = {};
      obj = {
        'primaryAsset': primaryAsset,
        'assetsToBeDeLinked': assetsToBeDeLinked,
      };
      this.assetService.delinkAsset(obj)
        .subscribe((res: unknown[]) => {
          this.store.dispatch(removeCachedAssetLinkData({ids: res.map((el)=>el?.['_id'])}))
          this.linkDelinkAction = true;
          this.isServiceLinkDelinkAction = false;
          this.getAssetLinked(this.assetId);
        });
    }
  }

  confirmedServiceDelinkFromAsset(status) {
    if (status === 'OK') {
      const serviceAssetIds : any = [];
      let gatewayId = '';
      serviceAssetIds.push(this.delinkServiceDetails._id);
      gatewayId = this.assetId;
      let obj = {};
      obj = {
        'serviceAssetIds': serviceAssetIds,
        'gatewayId': gatewayId
      };
      this.serviceLinkableService.delinkAsset(obj)
        .subscribe((assetDetailsAfterServiceDelink: any) => {
          this.isServiceLinkDelinkAction = true;
          this.linkDelinkAction = false;
          this.assetData = assetDetailsAfterServiceDelink;
          this.getAssetsLinkedDetails(assetDetailsAfterServiceDelink);
        });
    }
  }

  confirmedlink(status) {
    if (status === 'OK') {
      let primaryAsset: any;
      let assetsToBeLinked: any = [];
      primaryAsset = this.assetId;
      assetsToBeLinked = this.linkAssetDetails;
      let obj = {};
      obj = {
        'primaryAsset': primaryAsset,
        'assetsToBeLinked': assetsToBeLinked,
      };
      this.assetService.linkAsset(obj)
        .subscribe((res: unknown[]) => {
          this.store.dispatch(removeCachedAssetLinkData({ ids: res.map((el) => el?.['_id']) }))
          this.linkDelinkAction = true;
          this.isServiceLinkDelinkAction = false;
          this.getAssetLinked(this.assetId);
          $('#assetListingPopup').hide(300);
        });
    }
  }

  confirmedDeregisteringAsset(status) {
    this.isAssetDeregisteredSuccess = false;
    if (status === 'OK') {
      this.assetService.deregisterAsset(this.deregisterSelectedAssetId)
        .subscribe(() => {
          this.isAssetDeregisteredSuccess = true;
          this.getAssetForSelectedAssetTypes();
          $('#layout-main-content').removeClass('split-view-active');
          $('html').css('overflow-y', 'auto');
          const msg = this.getLangLabel('app.asset-list.asset-deregister')
          this.messageService.add({ key: 'asset-list-toast', severity: 'success', summary: 'Success', detail: msg  });
          this.resetFormData = Math.random();
          this.assetService.sendNewThingsAddedStatus(true);
        }, () =>{});
    }
  }
  confirmedServicelinkToAsset(status) {
    if (status === 'OK') {
      let serviceAssetIds : any = [];
      let gatewayId = '';
      serviceAssetIds = this.serviceIdsToLinkAsset;
      gatewayId = this.assetId;
      let obj = {};
      obj = {
        'serviceAssetIds': serviceAssetIds,
        'gatewayId': gatewayId
      };
      this.serviceLinkableService.linkAsset(obj)
        .subscribe((assetDetailsAfterServiceLink: any) => {
          this.isServiceLinkDelinkAction = true;
          this.linkDelinkAction = false;
          this.assetData = assetDetailsAfterServiceLink;
          this.getAssetsLinkedDetails(assetDetailsAfterServiceLink);
          $('#serviceListingPopup').hide(300);
        });
    }
  }
  delinkAsset(delinkAssetDetails) {
    this.delinkAssetDetails = delinkAssetDetails;
    const msg = this.getLangLabel('app.core.alert-popup.delink-asset-confirmation')
    this.confirmationService.confirm({
      target: event.target,
      message: msg,
      key: 'asset-list-popup',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        // confirm action
        this.confirmedDelink('OK')
      },
      reject: () => {
        // reject action
      }
    });
  }

  delinkService(delinkServiceDetails) {
    this.delinkServiceDetails = delinkServiceDetails;
    const msg = this.getLangLabel('app.core.alert-popup.delink-service-confirmation')
    this.confirmationService.confirm({
      target: event.target,
      message: msg,
      key: 'asset-list-popup',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        // confirm action
        this.confirmedServiceDelinkFromAsset('OK')
      },
      reject: () => {
        // reject action
      }
    });
  }

  linkAsset(linkAssetsIdsArray) {
    this.linkAssetDetails = linkAssetsIdsArray;
    this.selectedAssetToLinkCount = this.linkAssetDetails.length;
    this.confirmedlink('OK');
  }

  linkServicesToAsset(servicesIdsArray) {
    this.serviceIdsToLinkAsset = servicesIdsArray;
    this.selectedAssetToLinkCount = this.serviceIdsToLinkAsset.length;
  }

  deregisterAsset(assetId) {
    this.deregisterSelectedAssetId = assetId;
    this.confirmedDeregisteringAsset('OK');
  }

  assetTypeToAdd(assetConfig) {
    this.selectedAssetConfigDetails = assetConfig;
    this.selectedAssetConfigDetailsForMultiplAssets.set(assetConfig);
    this.resetAddAssetFormValue = Math.random();
  }

  getDataByPageNo(page) {
    this.pageNumber = page.page + 1;

    if (page.page === 0) {
      this.skip = 0;
      this.limit = this.pagination.recordsPerPage;
    } else {
      this.skip = (page.page) * this.pagination.recordsPerPage;
      this.limit = this.pagination.recordsPerPage;
    }
    /*if(page==1){
     this.skip=page-1;
     this.limit=page*this.pagination.recordsPerPage;
     }else{
     this.skip=(page-1)*this.pagination.recordsPerPage;
     this.limit=this.pagination.recordsPerPage;
     }*/
     this.store.dispatch(setCurrentPageSkipLimit({assetType:this.selectedAssets.toString(),skip:this.skip,limit:this.limit}))
    if (this.searchForAsset && this.searchOperationDone) {
      this.setAssetListByTypesOnSearch(this.searchForAsset, this.selectedAssets.toString(), this.skip, this.limit);
    }
    if (this.selectedAssets.length>=0 && !this.searchOperationDone && !this.filterFields.isFilterApplied) {
     this.store.dispatch(loadAssetsFromState({assetType: this.selectedAssets.toString(), skip: this.skip, limit: this.limit}));
    }
    if (this.selectedAssets.length > 0 && this.filterFields.isFilterApplied) {
      this.getAssetDataWithFilter(this.selectedAssets.toString(), this.skip, this.limit);
    }

  }

  setSearchingPlaceholder(selectedAssets) {
      if ( selectedAssets.length === 0 ) {
        this.searchPlaceholder = this.lang.getTranslation('sys.search-all');//'Search all';
      } else {
        this.searchPlaceholder = this.lang.getTranslation('sys.search') + ' ' + selectedAssets.toString();
      }
     this.searchService.sendSearchPlaceholderValue(this.searchPlaceholder);
  }

  stopScroll() {
    $('.carousel').carousel({
      pause: true,
      interval: false
    });
  }

  addActiveClass(data) {
     // this.splitScreenActive = false;
    this.splitScreenService.hideModalAndRemoveSplitScreen();
    data['embossedFlagStatus'] = false;
    data['isActiveItem'] = true;
    for (const _ of this.assetCountCardArray) {
      const remainingData = filter(this.assetCountCardArray, function(item) {
        return item['type'] !== data['type'];
      });
      forEach(remainingData, function(item) {
        item['isActiveItem'] = false;
        item['embossedFlagStatus'] = false;
      });
    }
  }

  addNewAsset(assetConfig) {
     // this.splitScreenActive = true;
    this.isEventSelectedToEdit = false;
    this.splitScreenService.addOrRemoveSplitScreen('addSplitScreen', '#notification-event-to-add');
    const filteredArray = filter(this.assetCountByType, function(item) {
      return item['label'] === assetConfig;
    });
    this.selectedAssetConfigDetails = this.addLinkableAssetConfig(filteredArray[0]);
    this.formData =  (this.selectedAssetConfigDetails && this.selectedAssetConfigDetails['configuration']) ? this.selectedAssetConfigDetails['configuration'] : null;
    this.resetAddAssetFormValue = Math.random();
  }

  addLinkableAssetConfig(assetConfig) {

    const assetTypes = assetConfig?.config?.assetLinkConfig?.assetTypes || [];
    if (assetTypes.length) {
      const configuration = { ...(assetConfig.configuration || {}) }
      assetTypes.forEach(la => {
        configuration[la] = assetConfig.config.assetLinkConfig[la]
      })
      return { ...assetConfig, configuration }
    }
    return assetConfig
  }

  modifyPayloadForLinkConfig(payload) {
    const modPayload = { ...payload, assetsToLink: {} };
    const linkableAssets: string[] = this.selectedAssetConfigDetails?.config?.assetLinkConfig?.assetTypes || [];
    linkableAssets?.forEach((linkableAsset: string) => {
      const data = Array.isArray(modPayload[linkableAsset]) ? modPayload[linkableAsset] : modPayload[linkableAsset] ? [modPayload[linkableAsset]] : null;
      if (data) {
        modPayload.assetsToLink[linkableAsset] = data
      }
      delete modPayload[linkableAsset];
    })
    return modPayload
  }


  addMultipleAssets(assetConfig: string) {
    this.selectedAssetConfigDetailsForMultiplAssets.set(this.assetCountByType?.find(({ label }) => label === assetConfig));

    this.showMultipleAssetCreationForm.set(true);
    this.resetAddMultipleAssetFormValue.set(Math.random());
  }

  showMultipleAssetCreationForm = signal<boolean>(false);


  toggleSplitScreenFunction() {
     // this.splitScreenActive = this.splitScreenService.ToggleSplitScreen();
    if (this.assetData) {
      this.splitScreenService.addActiveClass(this.assetData, this.allAssets, '_id');
    }
  }

  addGroupsToAsset(addGroupsIdsArray) {
    this._addGroups.ids = addGroupsIdsArray;
    this._addGroups.count = this._addGroups.ids.length;
  }

  removeGroupsFromAssets(removeGroupsIdsArray) {
    this._removeGroups.ids = removeGroupsIdsArray;
    this._removeGroups.count = this._removeGroups.ids.length;
  }

  confirmedAddingGroup(status) {
    if (status === 'OK') {
      this._addGroups.action = false;
      const primaryAsset: any = [];
      let groupsToBeLinked: any = [];
      primaryAsset.push(this.assetId);
      groupsToBeLinked = this._addGroups.ids;
      let obj = {};
      obj = {
        'assets': primaryAsset,
        'groups': groupsToBeLinked,
      };
      this.assetGroupService.addAssetsToGroup(obj)
        .subscribe(res => {
          $('#groupListingPopup').hide(300);
          this.getAssetDetailsById(res[0], 'groupAdded');
          //   this.updateAssetCard(updatedAssetData);
          //   this._addGroups.action = true;
          //   this.setCssProperties.showMessagePopup('groupaddedSuccessfully');
          //   $('#groupListingPopup').hide(300);
          //   console.log('link group', res);
        });
    }
  }

  confirmedRemovingGroup(status) {
    if (status === 'OK') {
      this._removeGroups.action = false;
      const primaryAsset: any = [];
      let groupsToBeLinked: any = [];
      primaryAsset.push(this.assetId);
      groupsToBeLinked = this._removeGroups.ids;
      let obj = {};
      obj = {
        'assets': primaryAsset,
        'groups': groupsToBeLinked,
      };
      this.assetGroupService.removeAssetsFromGroup(obj)
        .subscribe(res => {
          // this._removeGroups.msg = res['msg'];
          this.getAssetDetailsById(res[0], 'groupRemoved');
          //   this.updateAssetCard(updatedAssetData);
          //   this._removeGroups.action = true;
          //   this.setCssProperties.showMessagePopup('groupRemovedSuccessfully');
          //   console.log('delink group', res);
        });
    }
  }

  getAssetDetailsById(assetObj, status) {
    const assetId = assetObj['_id'];
    this.assetService.getAssetDetailsByMongodbId(assetId).subscribe((res) => {
      if (res) {
        if (status === 'groupAdded') {
          this.updateAssetCard(res);
          this._addGroups.action = true;
          const msg = this.getLangLabel('app.asset-list.groups-added')
          this.messageService.add({ key: 'asset-list-toast', severity: 'success', summary: 'Success', detail: msg  });
        } else {
          this.updateAssetCard(res);
          this._removeGroups.action = true;
          const msg = this.getLangLabel('app.asset-list.groups-removed')
          this.messageService.add({ key: 'asset-list-toast', severity: 'success', summary: 'Success', detail: msg  });
        }
      }
    });
  }

  filterBtnClicked() {
    this.filterFields.filterClickedIndex = this.filterFields.filterClickedIndex + 1;
    if ((this.filterFields.filterClickedIndex % 2 === 0) && !this.filterFields.isFilterApplied) {
      this.searchService.showSearchBar(true);
    } else {
      this.searchService.showSearchBar(false);
      if (this.searchForAsset) {
        this.searchService.resetSearchTextInSearchBox();
      }
    }
  }

  getSelectedGroups(event) {
    if (event && event.length > 0) {
      this.filterFields.isFilterApplied = true;
      // this.filterFields.selectedGroups = event;
      this.getAssetCountsWithFilter();
    } else {
      this.filterFields.selectedGroups = [];
      this.filterFields.isFilterApplied = false;
      this.getAssetForSelectedAssetTypes();
    }
  }

  getAssetCountsWithFilter() {
    this.isShowAsset = true;
    let groups = '';
    const assetType = this.selectedAssets.toString();
    groups = this.filterFields.selectedGroups.toString();
    this.assetService.getCountByTypes(assetType, groups)
      .subscribe((result) => {
        this.isShowAsset = false;
        this.pagination.totalNoOfRecords = result['count'];
        if (this.pagination.totalNoOfRecords > 0) {
          this.getAssetDataWithFilter(this.selectedAssets, 0, this.pagination.recordsPerPage);

        } else if (this.pagination.totalNoOfRecords === 0) {
          this.allAssets = [];
          this.isShowAsset = false;
        }
      }, () => {
        this.isShowAsset = false;
      });
  }

  getAssetDataWithFilter(selectedAsset, skip, limit) {
    this.isShowAsset = true;
    const assetType = selectedAsset;
    const groups = this.filterFields.selectedGroups.toString();
    this.assetService.getAllByType(assetType, skip, limit, groups)
      .subscribe((result: any[]) => {
        if (result) {
          this.isShowAsset = false;
          let allAssetArray: any = [];
          allAssetArray = this.checkAssetTypeToDisplayStatus(result);
          const isServiceLinkableAssetFoundInArray: boolean = this.checkForServiceLinkableAssetTypeInAssetList(result);
          if (isServiceLinkableAssetFoundInArray) {
            this.getAllServersStats(allAssetArray);
            this.toggleSplitScreenFunction();
          } else {
            this.allAssets = this.checkServerStatus(allAssetArray);
            this.toggleSplitScreenFunction();
          }
        }
      });
  }

  resetFilterFields() {
    if (this.filterFields.isFilterApplied) {
      this.getAssetForSelectedAssetTypes();
    }
    this.filterFields.isFilterApplied = false;
    this.filterFields.selectedGroups = [];
  }

  getLangLabel(labelCode: string) {
    if(labelCode) {
      return this.lang.getTranslation(labelCode);
    }
  }

  isRemoveAssetBtnRequired() {
    return this.ability.can(Actions.DELETE, Subject.ASSET);
  }

  isUpdateAssetBtnRequired() {
    return this.ability.can(Actions.UPDATE, Subject.ASSET);
  }

  receiveMultipleAssetsData(assetData) {
    const assetType = this.assetCountByType?.find(({ label }) => this.selectedAssetsNames === label)?.type
    this.store.dispatch(createMultipleAssets({ assetType, assetData }))
  }


  handleAssetCreationButtons() {
    if (!this.selectedAssetsNames) return;
    this.assetCreationButtons = [];
    if (Object.keys(this.assetCreationSettings || {})?.length) {
      this.assetCreationSettings?.single?.enabled && this.assetCreationButtons.push({
        label: 'Single' + this.lang.getTranslation(this.selectedAssetsNames),
        icon: 'pi pi-plus',
        command: () => {
          this.addNewAsset(this.selectedAssetsNames);
        }
      });
      this.assetCreationSettings?.multiple?.enabled && this.assetCreationButtons.push({
        label: 'Multiple' + this.lang.getTranslation(this.selectedAssetsNames),
        icon: 'pi pi-plus',
        command: () => {
          this.addMultipleAssets(this.selectedAssetsNames);
        }
      })
    } else {
      this.assetCreationButtons.push({
        label: 'Single' + this.lang.getTranslation(this.selectedAssetsNames),
        icon: 'pi pi-plus',
        command: () => {
          this.addNewAsset(this.selectedAssetsNames);
        }
      });
    }
  }
}
