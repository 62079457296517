import {createSelector} from '@ngrx/store';
import {AppState} from '../app.state';
import {FleetState} from './fleet.reducer';


export const selectFleet = (state: AppState) => state?.fleet;

export const selectAllVehicles = (props: { module: string }) => createSelector(
  selectFleet,
  (state: FleetState) => state?.allVehicles?.[props.module]
);

export const selectedTrackableVehicles = (props: { module: string }) => createSelector(
  selectFleet,
  (state: FleetState) => state?.selectedTrackableVehicles?.[props.module]
);

export const selectMarkerIcons = (props: { module: string }) => createSelector(
  selectFleet,
  (state: FleetState) => state?.trackIcons?.[props.module]
);

export const selectSelectedVehicle = createSelector(
  selectFleet,
  (state: FleetState) => state?.selectedVehicle
);

export const selectLastUpdated = createSelector(
  selectFleet,
  (state: FleetState) => state?.lastUpdatedVehicle
);

export const selectVehiclesGroupName = createSelector(
  selectFleet,
  (state: FleetState) => state?.selectedGroupName
);

export const selectTrackStatus = createSelector(
  selectFleet,
  (state: FleetState) => state?.status
);

export const selectStatsData = createSelector(
  selectFleet,
  (state: FleetState) => {
    return {
      distanceMoved: state?.distanceMovedData,
      speedData: state?.speedData,
      fuelData: state?.fuelData,
      sensorData: state?.sensorData
    };
  }
);

export const selectEventsDataCount = createSelector(
  selectFleet,
  (state: FleetState) => state?.eventsCount
);

export const selectEventsData = createSelector(
  selectFleet,
  (state: FleetState) => state?.eventsData
);

export const selectEventsStatusData = createSelector(
  selectFleet,
  (state: FleetState) => state?.eventsStatusData
);

export const selectGeofences = createSelector(
  selectFleet,
  (state: FleetState) => state?.geofences
);

export const selectFilteredGeofences = createSelector(
  selectFleet,
  (state: FleetState) => state?.filteredGeofences
);

export const selectMapIconSetingsData = createSelector(
  selectFleet,
  (state: FleetState) => state?.mapIconData
);

export const selectSuccessMsg = createSelector(
  selectFleet,
  (state: FleetState) => state?.successMsg
);

export const selectGeoAssets = createSelector(
  selectFleet,
  (state: FleetState) => state?.geoAssets
);

export const selectExpectedPathLatLngs = createSelector(
  selectFleet,
  (state: FleetState) => state?.expectedPathLatLngs
);

export const selectActualPathLatLngs = createSelector(
  selectFleet,
  (state: FleetState) => state?.actualPathLatLngs
);

export const selectLinkedLocations = ({cityId}) => createSelector(
  selectFleet, (state: FleetState) => state?.linkedLocations[cityId]
);

export const selectPlotGeoFenceStatus = createSelector(
  selectFleet,
  (state: FleetState) => state?.plotGeoFencesStatus
);
export const selectCarGpsHistoricalData = createSelector(
  selectFleet,
  (state: FleetState) => state?.carGpsHistoricalData
);