import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {MenuItem} from 'primeng/api';
import {Store} from '@ngrx/store';
import { KioskActions } from "../../../state/Kiosk";
import { AppState } from "../../../state/app.state";


@Injectable()
export class BreadcrumbService {
  constructor(private store: Store<AppState>) {
  }

  private itemsSource = new Subject<MenuItem[]>();
  items = this.itemsSource.asObservable();

  setItems(items: MenuItem[]) {
    this.itemsSource.next(items);
    //reset the selected branch in state when bread crumb is updated
    this.store.dispatch(KioskActions.resetSelectedBranch());
  }
}
