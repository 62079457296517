import {Injectable} from '@angular/core';

const DELIVERY_COLOR = '#b7b21d'
const DELIVERY_BG_COLOR = '#e9e874'

@Injectable({
  providedIn: 'root'
})
export class OrderStatusColorValuesService {

  constructor() { }

  private orderStatusColorValues = {
    pending:{
      color: "#805b36", //dark brown ok
      backgroundColor: "#ffd8b2",

    },
    completed: {
      color: '#0f9818', //dark green
      backgroundColor: '#c8e6c9',

    },
    returning: {
      color: '#c63737', // default maroon red color ok
      backgroundColor: '#ffcdd2',
    },
    deliveryInProgress:{
      color: "#805b36", //dark brown ok
      backgroundColor: "#ffd8b2",

    },
    delivered: {
      color: '#0f9818', //dark green
      backgroundColor: '#c8e6c9',

    },
    deliveryOnHold: {
      color: '#c63737', // default maroon red color ok
      backgroundColor: '#ffcdd2',

    },
    pickedUp : {
      color: "#1d73b7", //dark navy color
      backgroundColor: "#b3e5fc",

    },
    assigned:{
      color: "#968f31", // lemon yello ok
      backgroundColor: "#feedaf",

    },

    deliveryPartnerAcceptedOrder: {
      color: "#7a2ead", //dark purple
      backgroundColor: "#eccfff",

    },

    deliveryPartnerDeclinedPickedUp: {
      color: '#c63737', // default maroon red color ok
      backgroundColor: '#ffcdd2',
    },

    deliveryPartnerRejectedOrder: {
      color: '#c63737', // default maroon red color ok
      backgroundColor: '#ffcdd2',
    },

    deliveryPartnerReturnedOrder: {
      color: '#c63737', // default maroon red color ok
      backgroundColor: '#ffcdd2',
    },
    cancelled: {
        color: '#c63737', // default maroon red color ok
        backgroundColor: '#ffcdd2',
      },
    forceDelivered: {
      color: '#1da885', //dark green
      backgroundColor: '#09edb4',
    },
    RoundTrip:{
      color: '#373fa3',
      backgroundColor: '#abafe0',
    },
    Returningto:{
      color: '#373fa3',
      backgroundColor: '#abafe0',
    },
    Returned:{
      color: '#373fa3',
      backgroundColor: '#abafe0',
    },
    ManuallyReturned: {
      color: "#b7b21d", // lemon yello ok
      backgroundColor: "#e9e874",
    },
    AutoReturned: {
      color: '#22C55E',
      backgroundColor: '#c8e6c9',
    },
    GeoFence: {
      color: '#22C55E',
      backgroundColor: '#c8e6c9',
    },
    manuallyCompleted:{
      color: '#317873', //Myrtle Green
      backgroundColor: '#09edb4',
    },
    timeBoundCompleted:{
      color: '#87A96B', //Asparagus green
      backgroundColor: '#09edb4',
    },
    inProgress:{
      color: "#805b36", //dark brown ok
      backgroundColor: "#ffd8b2",
    },
  }

  getColorValuesForAllOrderStatus(){
    return this.orderStatusColorValues;
  }
  getTextAndBorderColor(deliveryStatus, field?){
    if (field && field === 'orderStatus' ) {
      if(this.orderStatusColorValues[deliveryStatus]){
        const colorValue = this.orderStatusColorValues[deliveryStatus].color;
        const textAndBorderColorObj = {
          color: colorValue,
          border: `2px solid ${colorValue}`,
          textAlign: 'center',
        };

        return textAndBorderColorObj;
      }
      else{
        const textAndBorderColorObj = {
          color: DELIVERY_COLOR,
          border: `2px solid ${DELIVERY_COLOR}`,
          textAlign: 'center',
        };

        return textAndBorderColorObj;
      }
    } else {
      return {}
    }

  }
  getStatusColor(deliveryStatus){
    if(this.orderStatusColorValues[deliveryStatus]){
       return this.orderStatusColorValues[deliveryStatus].color
    }else{
      return DELIVERY_COLOR;
    }
  }

  getStatusBackgroundColor(deliveryStatus){
    if(this.orderStatusColorValues[deliveryStatus]){
      return this.orderStatusColorValues[deliveryStatus].backgroundColor
    }else{
      return DELIVERY_BG_COLOR;
    }
  }
}
